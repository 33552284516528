<template>
	<div class="vcheckboxedit">
		<div class="custom-control checkbox custom-checkbox b-custom-control-lg">
      <div class="" v-if="formattedOptions.length > 0" v-for="option in formattedOptions">
        <input class="form-check-input checkbox-input custom-control-input" v-if="option[0]" :name="id" type="radio" v-model="val"  :id="option[0]" :checked="value == option[0] ? 'checked' : 'false'" :value="option[0]" unchecked-value="0" @change="updateCheckbox"/>
        <label :for="option[0]" class="form-check-label custom-control-label" v-if="option[1]">{{option[1]}}</label>
      </div>
		</div>
	</div>
</template>
<script>
export default{
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			val: '', // v-bind data model for input text
			empty: 'Check', // empty place holder .. replace with your own localization for default
		}
	},
	props: ['id','value','placeholder', 'inputLabel', 'field', 'options'], // parent should provide :text or :placeholder
	methods: {
		initValue: function(){
			this.val = (this.value=='' || this.value==undefined || this.value==0) ? 0 : this.value;
		},
		updateCheckbox: function(){
			this.$emit('checkbox-clicked', this.val, this.field);
		}
	},
	computed: {
		vplaceholder: function(){
			return (this.val == 1) ? 'Yes' : 'No';
		},
    formattedOptions: function(){
      if(this.options){
        let tmpOptions = this.options.split("|");
        if(tmpOptions.length > 0){
          for(let i = 0; i < tmpOptions.length; i++){
            tmpOptions[i] = tmpOptions[i].split(":");
          }
          return tmpOptions;
        }
      } return [];
    }
	},
	mounted: function(){
		this.initValue();
	}
}
</script>