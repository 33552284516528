import Vue from "vue";
import moment from 'moment'

Vue.filter('todate', function(date){
    return moment(date).format("dddd, MMMM Do, YYYY");
});

Vue.filter('mmddyy', function(date){
    return moment(date).format("M/D/YY");
});

Vue.filter('mmddyyyy', function(date){
    return moment(date).format("M/D/YYYY");
});

Vue.filter('firstLetter', function(name){
    return name.charAt(0);
});

Vue.filter('userFriendly', function(property){
    property = property.replace(/_/g, ' ');
    property = property.trim();
    property = property.charAt(0).toUpperCase() + property.slice(1);
    return property;
});