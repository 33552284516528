<script>
import NewModal from './new-modal';
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    'newModal': NewModal
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  props: ['listsData'],
  data() {
    return {
      filter: '',
      timeout: null,
      deleteurl: '/data/superadmin/delete-user/'
    };
  },
  methods: {
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, 'ig');
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
    },
    sendAmessage(){

    },
    loadMore(){
      this.$emit('load-more');
    },
    viewUser(id, e){
      if(e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'A') {
        let userURL = '/#/dashboard/users/' + id;
        window.location.href = userURL;
      }
    },
    deleteList(list){
      // console.log(list);
    },
    editUser(id){
      let userURL = '/dashboard/users/' + id;
      window.location.href = userURL;
    },
    confirmDelete(user) {
      Swal.fire({
        title: "Are you sure you want to delete " + user.name + "?",
        buttonsStyling: false,
        confirmButtonText: "Delete User",
        customClass: {
          confirmButton: 'btn btn-danger',
          title: ''
        },
        focusConfirm: false,
        showCancelButton: false,
        showCloseButton: true,
        closeButtonHtml: '<svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"/></svg>'
      }).then(result => {
        if (result.value) {
          axios.delete(this.deleteurl + user.id).then(response => {
            if(response.data && response.data.status === 'success') {
              let index = this.listsData.data.indexOf(user);
              if (index !== -1) this.listsData.data.splice(index, 1);
              Swal.fire("User Deleted", user.name + " has been deleted.", "success");
            }
          }).catch(error => {
              /* handleErrors is a global function inside /js/mixins/global.mixins.js
              */
              this.handleErrors(error.response);
          });
        }
      });
    }
    /**
     * Search the table data with search input
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
     */
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('deleted')) {
      Swal.fire("User Deleted", decodeURI(urlParams.get('deleted')) + " has been deleted.", "success");
    }
  },
  computed: {
    filteredRows() {
      return this.listsData.data.filter(row => {
        const name = row.name.toString().toLowerCase();
        const email = row.email.toString().toLowerCase();
        const status = row.status.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return email.includes(searchTerm) || status.includes(searchTerm) || name.includes(searchTerm);
      });
    },
  }
};
</script>

<template>
  <div>
    <newModal/>

    <!-- Search -->
    <div class="row">
      <div class="col-md-8 col-lg-6">
        <div id="user-table_filter" class="dataTables_filter text-md-end mb-3">
          <label for="userSearchField" class="d-inline-flex align-items-center visually-hidden">Search:</label>
          <b-form-input
            id="userSearchField"
            type="search"
            v-model="filter"
            placeholder="Search..."
            class="form-control rounded-pill"
          ></b-form-input>
        </div>
      </div>
      <div class="col-md-4 col-lg-6 text-end">
        <button type="button" class="btn btn-label btn-link ms-auto"><span class="label-icon" aria-hidden="true"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_43_569)"><path d="m4.359 5.956-.718-.697L8.5.254l4.859 5.005-.718.696L9 2.205v10.767H8V2.205L4.359 5.956ZM16 9.03v6.47a.5.5 0 0 1-.5.5h-14a.5.5 0 0 1-.5-.5V9.025H0V15.5c0 .827.673 1.5 1.5 1.5h14c.827 0 1.5-.673 1.5-1.5V9.03h-1Z" fill="#3A4C55"/></g><defs><clipPath id="clip0_43_569"><path fill="#3A4C55" d="M0 0h17v17H0z"/></clipPath></defs></svg></span>Export</button>
      </div>
    </div>
    <!-- End search -->
    <div class="row" v-if="listsData && listsData.data">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table class="table table-hover project-list-table table-nowrap table-centered table-borderless align-middle">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Status</th>
                  <th scope="col">Role(s)</th>
                  <th scope="col" class="text-end">Manage</th>
                </tr>
              </thead>
              <tbody v-if="listsData.data && listsData.data.length > 0">
                <tr v-for="(list, index) in filteredRows" @click="viewUser(list.id, $event)" :key="`user-${index}`">
                  <td v-html="highlightMatches(list.name)">
                  </td>
                  <td v-html="highlightMatches(list.email)"></td>
                  <td>
                    <span class="visually-hidden" v-html="highlightMatches(list.status)"></span>
                    <div class="form-check form-switch form-switch-md" dir="ltr" v-if="list.status === 'Active'">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizelg"
                        checked
                      />
                    </div>

                    <div class="form-check form-switch form-switch-md" dir="ltr" v-if="list.status === 'Pending' || list.status === 'Inactive'">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="SwitchCheckSizelg"
                      />
                    </div>
                  </td>
                  <td>
                    <div v-if="list.roles && list.roles.length > 0">
                      <span v-for="(role, index) in list.roles">
                      {{role.name | capitalize}}<span v-if="index + 1 < list.roles.length">, </span>
                      </span>
                    </div>
                  </td>
                  <td class="text-end">
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <span class="visually-hidden">Manage user {{list.name}}</span>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                        </template>
                        <li><button type="button" class="btn btn-label btn-link" @click="editUser(list.id)"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</button></li>
                        <li><button type="button" class="btn btn-label btn-link" @click="confirmDelete(list)"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                      </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="listsData.next_page_url">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success" @click.prevent="loadMore">
            <i class="bx bx-loader bx-spin font-size-18 align-middle mr-2" v-if="listsData.loading"></i>Load more
          </a>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>