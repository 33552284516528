<script>
import axios from 'axios';
import CheckboxEdit from '../../../plugins/CheckboxEdit';
import {_} from 'vue-underscore';

/**
 * New Location Modal component
 */
export default {
  components: {CheckboxEdit},
  props: ['modalId', 'tenantid', 'localDataFieldsToUpdate', 'customFieldsToUpdate', 'fileUploadFieldsToUpdate', 'hoursToUpdate', 'client', 'locationsUrl'],
  data() {
    return {
      bulkUpdateUrl: "/data/superadmin/tenants/",
      locations: [],
      checkedlocations: [],
      initialCheckedLocations: [],
      getting: false,
      showModal: false,
      checkAll: 0,
      showConfirm: false,
    };
  },
  methods: {
    getLocations(){
      if(!this.locations.length) {
        axios.get(this.bulkUpdateUrl).then(response => {
          if(response.data && response.data.data && response.data.data.locations) {
              this.locations = response.data.data.locations;
          }
          if(response.data && response.data.data && response.data.data.checkedLocations) {
              this.initialCheckedLocations = JSON.parse(JSON.stringify(response.data.data.checkedLocations));
              this.checkedlocations = response.data.data.checkedLocations;
          }

        }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        });
      }
    },
    sendLocations(){
      this.$emit('send-locations', this.checkedlocations);
      let locationsToAdd = [];
      let locationsToRemove = [];
      this.initialCheckedLocations.forEach((value, index) => {
        if (!this.checkedlocations.includes(this.initialCheckedLocations[index])) {
          locationsToRemove.push(this.initialCheckedLocations[index]);
        }
      });
      this.checkedlocations.forEach((value, index) => {
        if (!this.initialCheckedLocations.includes(this.checkedlocations[index])) {
          locationsToAdd.push(this.checkedlocations[index]);
        }
      });
      this.$emit('update-locations', {add: locationsToAdd, remove: locationsToRemove});
      this.showModal = false;
    },
    checkedLocation(val, id){
      if(val) {
        this.checkedlocations.push(id);
      } else {
        this.checkedlocations = _.without(this.checkedlocations, id);
      }
    },
    isChecked(id) {
      return this.checkedlocations.includes(id) ? 1 : 0;
    },
    toggleCheckAll() {
      if (!this.checkAll) {
        this.checkedlocations = [];
        for (let loc in this.locations) {
          this.checkedLocation(1, this.locations[loc].id);
        }
      } else {
        this.checkedlocations = [];
      }
      this.checkAll = !this.checkAll;
      let checkboxes = this.$refs.shForm.getElementsByClassName('form-check-input');
      for (let i = 1; i < checkboxes.length; i++) {   // checkboxes[0] is the "all" checkbox so skip it
        checkboxes[i].checked = this.checkAll;
      }
    },
    checkedLocationsChanged() {
      const checkedLocations = this.checkedlocations.slice().sort();
      const initialCheckedLocations = this.initialCheckedLocations.slice().sort();
      const changed = !((checkedLocations.length == initialCheckedLocations.length) && checkedLocations.every(function(element, index) {
        return element === initialCheckedLocations[index]; 
      }));
      return changed;
    },
    toggleConfirmDialogue() {
      this.showConfirm = !this.showConfirm;
    },
    changeIsPlural() {
      console.log(this.fileUploadFieldsToUpdate);
      return Object.keys(this.localDataFieldsToUpdate).length + Object.keys(this.customFieldsToUpdate).length + this.fileUploadFieldsToUpdate.length + Object.keys(this.hoursToUpdate).length > 1 ? 's' : '';
    },
    locationIsPlural() {
      return this.checkedlocations.length > 1 ? 's' : '';
    },
  },
  mounted() {
    if(this.locationsUrl)
      this.bulkUpdateUrl = this.locationsUrl;
    if(this.client)
      this.bulkUpdateUrl = this.bulkUpdateUrl.replace('superadmin', 'tenants');
    if(this.tenantid)
      this.bulkUpdateUrl += this.tenantid;
    this.initialCheckedLocations = [];
  }
};
</script>

<template>
	<div>
        <b-button v-b-modal="typeof modalId !== 'undefined' ? 'bulk-update-modal-' + modalId : 'bulk-update-modal'" @click.prevent="getLocations()" class="btn bg-blue-800">Bulk Update</b-button>
        <b-modal size="lg" :id="typeof modalId !== 'undefined' ? 'bulk-update-modal-' + modalId : 'bulk-update-modal'" hide-footer v-model="showModal">
          <template #modal-header="{ close }">
            <p><strong>Bulk update</strong></p>
            <button type="button" class="close" @click="close()">
              <span class="visually-hidden">Close Dialog</span>
              <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
            </button>
          </template>
          <p>The following changes will be applied:</p>
          <div v-if="typeof localDataFieldsToUpdate !== 'undefined' && Object.keys(localDataFieldsToUpdate).length">
            <ul v-for="(val, key) in localDataFieldsToUpdate" :key="key">
              <li>{{ key | userFriendly }} to {{ val }}</li>
            </ul>
          </div>
          <div v-if="typeof customFieldsToUpdate !== 'undefined' && Object.keys(customFieldsToUpdate).length">
            <ul v-for="(val, key) in customFieldsToUpdate" :key="key">
              <li>{{ key | userFriendly }} to {{ val }}</li>
            </ul>
          </div>
          <div v-if="typeof fileUploadFieldsToUpdate !== 'undefined' && Object.keys(fileUploadFieldsToUpdate).length">
            <ul v-for="(val, key) in fileUploadFieldsToUpdate" :key="key">
              <li>{{ key | userFriendly }} to {{ val }}</li>
            </ul>
          </div>
          <div v-if="typeof hoursToUpdate !== 'undefined' && Object.keys(hoursToUpdate).length">
            <ul v-for="(val, key) in hoursToUpdate" :key="key">
              <li>{{ key | userFriendly }} to {{ val }}</li>
            </ul>
          </div>
          <br>
          <h2 class="text-uppercase card-title">Select locations to update</h2>
          <b-form @submit.prevent="sendLocations" ref="shForm">
            <div class="d-flex align-items-center">
              <CheckboxEdit id="toggleAllLocations" :field="'all'" :value="checkAll" v-on:checkbox-clicked="toggleCheckAll" inputLabel="Select All"></CheckboxEdit></th>

              <div v-if="checkedLocationsChanged()" class="ms-auto">
                <a href="#" @click.prevent="sendLocations()" class="btn btn-success btn-xl">Save Changes</a>
              </div>
            </div>
            <hr class="mt-2">
            <ul v-if="locations" class="list-unstyled list-update-locations mt-2">
              <li v-for="location in locations">
                <CheckboxEdit :field="location.id" :id="location.id" :inputLabel="location.name" :value="isChecked(location.id)" v-on:checkbox-clicked="checkedLocation" ></CheckboxEdit>
              </li>
            </ul>
            <div v-else>
              <a href="#" @click.prevent="getLocations()">Fetch Locations</a>
            </div>
          </b-form>
        </b-modal>
	</div>
</template>