<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import newModal from './new-modal';
import updateModal from './update-modal';
import axios from "axios";

/**
 * Settings-list component
 */
export default {
  components: {
    Layout,
    PageHeader,
    newModal, 
    updateModal,
  },
  data() {
    return {
      dataurl: '/data/superadmin/settings',
      setting: [],
      title: "Settings",
      items: [
        {
          text: "Settings",
          href: "/",
        }
      ],
      listsData: [],
      fields: [
        { key: "label", sortable: true },
        { key: "description", sortable: true },
        { key: "tooltip", sortable: true },
        { key: "slug", sortable: true },
        { key: "datatype", sortable: true },
        { key: "default", sortable: true },
      ],
      currentPage: 1,
      perPage: 100,
      sortBy: "age",
      sortDesc: false,
      filter: null,
      filterOn: [],
    };
  },
  mounted() {
    // Set the initial number of items
    this.getLists();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
		settingClicked(records, index){
			this.setting = records;
			this.$refs.updateModalRef.$refs.modalEl.show();
		},
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          if(append) {
            this.listsData.locations.data.push.apply(this.listsData.locations.data, response.data.locations.data);

            this.listsData.locations.next_page_url = response.data.next_page_url;

            this.listsData.loading = false;
          } else {
            this.listsData = response.data;
          }
        	this.totalRows = this.listsData.length;
        }
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
		formSuccess(newSetting){
			this.$refs.modal.$refs.modalEl.hide();
      this.listsData.push(newSetting);
    },
		updateSuccess(newSetting){
			this.$refs.updateModalRef.$refs.modalEl.hide();
      
			this.listsData.find(this.updateById(newSetting)).label = newSetting.label;
			this.listsData.find(this.updateById(newSetting)).description = newSetting.description;
			this.listsData.find(this.updateById(newSetting)).tooltip = newSetting.tooltip;
			this.listsData.find(this.updateById(newSetting)).slug = newSetting.slug;
			this.listsData.find(this.updateById(newSetting)).datatype = newSetting.datatype;
			this.listsData.find(this.updateById(newSetting)).default = newSetting.default;
		},
    deleteSuccess(id){
      this.$refs.updateModalRef.$refs.modalEl.hide();
      let indexToDelete = null;
      this.listsData.forEach(function(item, index) {
        if (item.id === id) {
          indexToDelete = index;
        }
      });
      if (indexToDelete !== null) {
        this.listsData.splice(indexToDelete, 1);
      }
    },
		updateById(newSetting){
			return function(setting){
				return setting.id == newSetting.id;
			}
		},
    newSetting() {
      this.$refs.modal.$refs.modalEl.show();
    },
  },
};
</script>

<template>
  <Layout>
    <newModal ref="modal" @form-success="formSuccess"/>
		<updateModal :setting="setting" ref="updateModalRef" @update-success="updateSuccess" @delete-success="deleteSuccess"/>
    <PageHeader :title="title" :items="items" />
                <b-button type="button" variant="outline-success" class="d-flex align-items-end" @click="newSetting"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add New Setting</b-button>

            <!-- Table -->
            <div class="table-responsive mb-0  mt-3">
              <b-table
                class="datatables project-list-table settings-table"
                :items="listsData"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
								@row-clicked="settingClicked"
                borderless
              ></b-table>
            </div>
    </div>
  </Layout>
</template>