<script>
/**
 * logged out header component
 */
export default {
}
</script>
<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-dark">
                        <span class="logo-sm">
                        <img src="/images/logo.svg" alt height="22" />
                        </span>
                        <span class="logo-lg">
                        <img src="/images/logo-dark.png" alt height="17" />
                        </span>
                    </a>

                    <a href="/" class="logo logo-light">
                        <span class="logo-sm">
                        <img src="/images/logo-icon-light.png" alt height="22" />
                        </span>
                        <span class="logo-lg">
                        <img src="/images/logo-light.png" alt height="19" />
                        </span>
                    </a>
                </div>

            </div>
        </div>
    </header>
</template>