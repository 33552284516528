<script>
import { revenueChart } from "../../original/contacts/data-profile";
import LabelEdit from '../../../plugins/LabelEdit';
import axios from "axios";
import Swal from "sweetalert2";


/**
 * Contacts-Profile component
 */
export default {
  components: { 'label-edit': LabelEdit },
  props: ['listData'],
  data() {
    return {
      updateurl: '/data/superadmin/users/update',
      deleteurl: '/data/superadmin/delete-user/',
      localData: [],
      revenueChart: revenueChart,
      name: '',
      roles: [],
      activity: [],
      activityPagination: [],
      localDataFieldsToUpdate: {},
      roleDataFieldsToUpdate: [],
      refresher: true
    };
  },
  methods: {
    userUpdate(){
      let data = {id: this.localData.id};

      for(const field in this.localDataFieldsToUpdate) {
        if(this.localData[field] != this.localDataFieldsToUpdate[field]) {
          data[field] = this.localDataFieldsToUpdate[field];
        }
      }

      axios.post(this.updateurl, data).then(response => {
        if(response.data.data) {
            let responseData = response.data.data;
            responseData.roles = this.localData.roles;
            this.localData = responseData;
        }

        this.localDataFieldsToUpdate = {};

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    updateRole(){
      let formData = new FormData();
      formData.append('id', this.localData.id);
      formData.append('relation', 'Role');
      this.roleDataFieldsToUpdate.forEach((value, index) => {
        formData.append('roles[]', value);
      })

      axios.post(this.updateurl, formData).then(response => {
        if(response.data.data)
          this.localData.roles = response.data.data.roles;

        this.roleDataFieldsToUpdate = [];

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    checkIfRoleExists(roleId){
      if(this.localData.roles) {
        return this.roleFilter(this.localData.roles, roleId).length;
      } return false;
    },
    roleFilter(roles, rId){
      return roles.filter(function(obj){
        return obj.id == rId;
      });
    },
    paginateActivity(event){
      let element = event.target;
      if (element.tagName == 'SPAN') {
        element = element.parentNode;
      }
      if (!element.getAttribute('data-url') || element.classList.contains('active')) return;
      axios.get(element.getAttribute('data-url')).then(response => { 
        if(response.data) {
          this.activity = response.data.data.activity.data;
          this.activityPagination = response.data.data.activity.links;
        }
      }).catch(error => { 
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    viewCustomer(id, e){
      if(e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'A') {
        let userURL = '/#/dashboard/customers/' + id;
        window.location = userURL;
      }
    },
    confirmDelete(user) {
      Swal.fire({
        title: "Are you sure you want to delete " + user.name + "?",
        buttonsStyling: false,
        confirmButtonText: "Delete User",
        customClass: {
          confirmButton: 'btn btn-danger',
          title: ''
        },
        focusConfirm: false,
        showCancelButton: false,
        showCloseButton: true,
        closeButtonHtml: '<svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"/></svg>'
      }).then(result => {
        if (result.value) {
          axios.delete(this.deleteurl + user.id).then(response => {
            if(response.data && response.data.status === 'success') {
              window.location = '/#/dashboard/users?deleted=' + encodeURI(user.name)
            }
          }).catch(error => {
              /* handleErrors is a global function inside /js/mixins/global.mixins.js
              */
              this.handleErrors(error.response);
          });
        }
      });
    },
    watchforchanges(){
      return ( Object.keys(this.localDataFieldsToUpdate).length || this.roleDataFieldsToUpdate.length);
    },
    saveChanges(){
      if(Object.keys(this.localDataFieldsToUpdate).length) 
        this.userUpdate();

      if(this.roleDataFieldsToUpdate.length) 
        this.updateRole();      
    },
    prepareLocaldataUpdate(newdata, field){
      if(this.localData[field] == newdata) return;
      this.localDataFieldsToUpdate[field] = newdata === '' ? null : newdata;
      this.refresh();
    },
    prepareRoledataUpdate(field){
      if(this.roleDataFieldsToUpdate.includes(field)) {
        const index = this.roleDataFieldsToUpdate.indexOf(field);
        if(index !== -1) {
          this.roleDataFieldsToUpdate.splice(index, 1);
        }
      } else {
        this.roleDataFieldsToUpdate.push(field);
      }
      this.refresh();
    },
    refresh() {
      this.refresher = false;
      this.refresher = true;
    }
  },
  mounted(){
      this.localData = this.listData.data.user;
      this.roles = this.listData.data.roles;
      this.activity = this.listData.data.activity.data;
      this.activityPagination = this.listData.data.activity.next_page_url !== null ? this.listData.data.activity.links : [];
      this.name = this.localData.name;
  }
};
</script>

<template>
  <div v-if="listData && listData.data">
    <div class="row">
      <div class="col text-end pe-lg-5">
        <button type="button" class="btn ms-auto mb-3 btn-outline-danger d-flex align-items-end" @click="confirmDelete(localData)"><svg class="me-1" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg>Delete User</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card bg-none" v-if="localData.name">
          <div class="card-body">
            <h2 class="card-title text-uppercase">User Information</h2>
            <p class="form-group">
              <label-edit
                field="name"
                id="info_name"
                inputLabel="Full Name"
                v-bind:text="localData.name" 
                v-on:text-updated-blur="prepareLocaldataUpdate"
              ></label-edit>
            </p>
            <p class="form-group">
              <label-edit
                field="email"
                id="info_email"
                inputLabel="E-mail"
                v-bind:text="localData.email" 
                v-on:text-updated-blur="prepareLocaldataUpdate"
              ></label-edit>
            </p>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-md-6">
        <div class="card bg-none">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Status</h2>
            <div class="table-responsive mb-0">
              <div class="custom-control checkbox custom-radio b-custom-control-lg">
                <input class="custom-control-input" id="statusActive" type="radio" name="user-status" :checked="localData.status === 'Active'" @change="prepareLocaldataUpdate('Active', 'status')" />
                <label class="form-check-label custom-control-label" for="statusActive">
                  Active
                </label>
              </div>

              <div class="custom-control checkbox custom-radio b-custom-control-lg">
                <input class="custom-control-input" id="statusPending" type="radio" name="user-status" :checked="localData.status === 'Pending'" @change="prepareLocaldataUpdate('Pending', 'status')" />
                <label class="form-check-label custom-control-label" for="statusPending">
                  Pending
                </label>
              </div>

              <div class="custom-control checkbox custom-radio b-custom-control-lg">
                <input class="custom-control-input" id="statusInactive" type="radio" name="user-status" :checked="localData.status === 'Inactive'" @change="prepareLocaldataUpdate('Inactive', 'status')" />
                <label class="form-check-label custom-control-label" for="statusInactive">
                  Inactive
                </label>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card bg-none">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Roles & Permissions</h2>
            <div class="table-responsive mb-0">
                <div v-for="role in roles" class="custom-control checkbox custom-checkbox b-custom-control-lg">
                  <input class="form-check-input checkbox-input custom-control-input" type="checkbox" :id="role.id + role.name" :checked="checkIfRoleExists(role.id)" @change="prepareRoledataUpdate(role.name)"/>
                  <label class="form-check-label custom-control-label" :for="role.id + role.name">
                    {{role.name}} ({{role.guard_name}})
                  </label>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card bg-none">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Associated Brands</h2>
            <div class="table-responsive mb-0" v-if="localData.tenants">
              <table class="table table-nowrap table-hover mb-0 align-middle">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Database</th>
                    <th scope="col">Domain</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tenant in localData.tenants"  @click="viewCustomer(tenant.id, $event)">
                    <td>{{tenant.name}}</td>
                    <td>{{tenant.tenancy_db_name}}</td>
                    <td>
                    <a :href="'//' + tenant.domain_full" class="btn btn-primary btn-sm" target="_BLANK">
                      {{tenant.domain_full}}
                    </a>
                    <a :href="'/dashboard/customers/' + tenant.id" class="btn btn-primary btn-sm visually-hidden">
                      View Customer
                    </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card bg-none">
          <div class="card-body">
            <h2 class="card-title text-uppercase">History</h2>
            <div class>
              <ul class="list-unstyled">
                <li class="row">
                    <span class="col-md-4 font-weight-semibold">01/12/21</span>
                    Logged In
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-5">Activity</h4>
            <ul>
              <li v-for="act in activity" :key="act.id">
                <div>
                  <h5 class="font-size-15">
                    {{act.properties.message}}
                  </h5>
                  <span class="text-primary">{{act.updated_at | todate}}</span>
                </div>
                <br>
              </li>
            </ul>
            <div v-if="activityPagination" class="text-center">
              <ul class="pagination justify-content-center pagination-rounded">
                <li v-for="pagination in activityPagination" :key="pagination.label" :class="{'active': pagination.active, 'page-link': true}" :data-url="pagination.url" role="button" @click="paginateActivity">
                  <span v-html="pagination.label.replace('Previous', '').replace('Next', '')"></span>
                </li>
              </ul>
            </div>
            <div v-if="!activity.length">
              <h5 class="font-size-15">
                No activity recorded
              </h5>
            </div>
          </div>
        </div>
        <!-- end card -->
        
      </div>
      <div class="location-saver alert bg-white" v-show="watchforchanges()" v-if="refresher">
      	<button type="button" class="close">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>

        <button type="button" @click.prevent="saveChanges" class="btn btn-success btn-xl mx-auto">Save Changes</button>
      </div>
    </div>
  </div>
</template>