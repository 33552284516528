<script>
import TextareaEdit from './TextareaEdit';
import LabelEdit from './LabelEdit';
import CheckboxEdit from './CheckboxEdit';
import WysiwygEdit from './WysiwygEdit';
import RadioEdit from './RadioEdit';

export default{
    components: { LabelEdit, TextareaEdit, CheckboxEdit, WysiwygEdit, RadioEdit },
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			label: '', // v-bind data model for input text
			empty: 'Enter details', // empty place holder .. replace with your own localization for default
		}
	},
	props: ['text','placeholder', 'field'], // parent should provide :text or :placeholder
	methods: {
		initText(){
			if(this.text==''||this.text==undefined){
				this.label = this.vlabel
			}else{
				this.label = this.text
			}
		},
		// when the div label got clicked and trigger the text box
		onLabelClick(){
			this.edit = true;
			this.label = this.text;
		},
		// trigger when textbox got lost focus
		updateTextBlur(){
			// update the edit mode to false .. display div label text
			this.edit = false;
			// emit text updated callback
			this.$emit('text-updated-blur',this.label, this.field.slug)
		},
		updateTextEnter(){
			this.edit = false;
			this.$emit('text-updated-enter',this.label, this.field.slug)
		},
        updateCustomFieldFromCF(value, field){
            this.$emit('text-updated-blur', value, field);
        }
	},
	computed: {
		vplaceholder: function(){
			// check if the placeholder is undefined or empty
			if(this.placeholder==undefined || this.placeholder==''){
				// if it is empty or undefined, pre-populate with built-in place holder text
			 	return this.empty
			}else{
				return this.placeholder
			}
		},
		vlabel: function(){
			// after text has been updated
			// return text value or place holder value depends on value of the text
			if(this.text==undefined||this.text==''){
				return this.vplaceholder
			}else{
				return this.label
			}
		}
	},
	mounted: function(){
		// initiate the label view
		this.initText();
	},
	updated: function(){
		var ed = this.$refs.labeledit;
		if(ed!=null){
			ed.focus();
		}
	},
	watch: {
		text: function(value){
			if(value==''||value==undefined){
				this.label = this.vplaceholder
			}
		}
	}
}
</script>
<template>
    <div>
        <LabelEdit v-if="field && field.type == 'text'" :field="field.slug" v-bind:text="text" v-on:text-updated-blur="updateCustomFieldFromCF" ></LabelEdit>
        <LabelEdit v-else-if="field && (field.type == 'url' || field.type == 'email')" :field="field.slug" v-bind:text="text" v-on:text-updated-blur="updateCustomFieldFromCF" ></LabelEdit>
        <TextareaEdit v-else-if="field && field.type == 'textarea'" :field="field.slug" v-bind:text="text" v-on:text-updated-blur="updateCustomFieldFromCF" ></TextareaEdit>
        <CheckboxEdit v-else-if="field && field.type == 'checkbox'" :field="field.slug" :id="field.slug" v-bind:value="text" v-on:checkbox-clicked="updateCustomFieldFromCF" ></CheckboxEdit>
        <RadioEdit v-else-if="field && field.type == 'radio'" :field="field.slug" :options="field.options" :id="field.slug" v-bind:value="text" v-on:checkbox-clicked="updateCustomFieldFromCF" ></RadioEdit>
        <WysiwygEdit v-else-if="field && field.type == 'wysiwyg'" v-bind:text="text" :field="field.slug" :id="field.slug" v-bind:value="text" v-on:text-updated-blur="updateCustomFieldFromCF" ></WysiwygEdit>
        <div v-else>{{field}}</div>
	</div>
</template>