<template>
	<div class="vwysiwygedit d-flex">
    <span v-if="!edit" class="label">{{ inputLabel }}</span>
		<label v-if="edit" :for="id">{{ inputLabel }}</label>
		<div @click="onLabelClick" v-if="!edit" class="wysiwys-edit-wrapper"><div v-html="vlabel"></div></div>
    <wysiwyg v-if="edit" v-model="label" ref="wysiwygedit" :id="id"/>
	</div>
</template>
<script>


export default{
	name: 'LabelEdit',
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			label: '', // v-bind data model for input text
			empty: 'Enter text...', // empty place holder .. replace with your own localization for default
		}
	},
	props: ['text','placeholder', 'inputLabel', 'id', 'field', 'type'], // parent should provide :text or :placeholder
	methods: {
		// when the div label got clicked and trigger the text box
		onLabelClick: function(){
			this.edit = true;
			this.label = (this.text==undefined || this.text=='') ? "" : this.text;
		},
		// trigger when textbox got lost focus
		updateTextBlur: function(){
			// this.edit = false;
			this.$emit('text-updated-blur',this.label, this.field)
		},
		updateTextEnter: function(){
			this.edit = false;
			this.$emit('text-updated-enter',this.label, this.field)
		}
	},
	computed: {
		inputtype: function(){
			return this.type ? this.type : 'text';
		},
		vplaceholder: function(){
			 	return (this.placeholder==undefined || this.placeholder=='') ? this.empty : this.placeholder
		},
		vlabel: function(){
				return (this.text==undefined || this.text=='') ? this.vplaceholder : this.text
		}
	},
	mounted: function(){
		this.label = this.text;
	},
	watch: {
		label: function(value){
      this.updateTextBlur()
		}
	}
}
</script>