import Vue from "vue";
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("home", require("./home").default);

Vue.component("main-wrapper", require("./main-wrapper").default);
Vue.component("dashboard-users-new-modal", require("./dashboard/users/new-modal").default);

Vue.component("client", require("./client/index").default);

Vue.component("cf-text", require("./dashboard/customfields/fields/text").default);
Vue.component("cf-textarea", require("./dashboard/customfields/fields/textarea").default);
Vue.component("cf-file", require("./dashboard/customfields/fields/file").default);
Vue.component("cf-linked_image", require("./dashboard/customfields/fields/linked_image").default);
Vue.component("cf-tel", require("./dashboard/customfields/fields/tel").default);
Vue.component("cf-email", require("./dashboard/customfields/fields/email").default);
Vue.component("cf-url", require("./dashboard/customfields/fields/url").default);
Vue.component("cf-checkbox", require("./dashboard/customfields/fields/checkbox").default);
Vue.component("cf-radio", require("./dashboard/customfields/fields/radio").default);
Vue.component("cf-date_time", require("./dashboard/customfields/fields/date_time").default);
Vue.component("cf-wysiwyg", require("./dashboard/customfields/fields/wysiwyg").default);

Vue.component("login", require("./original/account/login").default);
Vue.component("register", require("./original/account/register").default);
Vue.component("forgot-password", require("./original/account/forgot-password").default);
Vue.component("reset-password", require("./original/account/reset-password").default);

Vue.component("pages-404", require("./original/utility/404").default);
Vue.component("pages-500", require("./original/utility/500").default);