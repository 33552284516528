<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import newModal from './new-modal';
import updateModal from './update-modal';
import axios from "axios";

/**
 * Custom Fields component
 */
export default {
  components: { Layout, PageHeader, newModal, updateModal },
  data() {
    return {
      dataurl: '/data/superadmin/customfields',
      field: [],
      listData: [],
      listsData: [],
      cardsData: [],
      tenantsData: [],

			fieldName: "",
			fieldType: "",
			fieldSlug: "",
			fieldVisibility: "",

      customFieldTypes: [
				{value: '', text: 'Select New Type'},
				{value: 'text', text: 'Text box'},
				{value: 'textarea', text: 'Textarea'},
				{value: 'file', text: 'File / Image'},
				{value: 'linked_image', text: 'Linked Image'},
				{value: 'tel', text: 'Tel'},
				{value: 'email', text: 'Email'},
				{value: 'url', text: 'URL'},
				{value: 'checkbox', text: 'Checkbox'},
				{value: 'radio', text: 'Radio'},
				{value: 'date_time', text: 'DateTime'},
				{value: 'wysiwyg', text: 'WYSIWYG'}
			],

      title: "Custom Fields",

      items: [
        {
          text: "Custom Fields",
          href: "/",
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 200, 300],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "label", sortable: true },
        { key: "tooltip", sortable: true },
        { key: "slug", sortable: true },
        { key: "type", sortable: true },
        { key: "visibility", sortable: true }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listsData.length;
    },
  },
	watch: {
		fieldSlug: function(newSlug, oldSlug){
			this.fieldSlug = newSlug.replace(/\s+/g, '-').toLowerCase();
		},
		fieldType: function(newType, oldType){
			if(newType != '') {
				if(Vue.options.components['cf-' + newType])
					this.$refs.modal.$refs.modalEl.show();
				else
					this.errorAlert("Component doesn't exist");
			}
		}
	},
  mounted() {
    // Set the initial number of items
    this.getLists();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
		fieldClicked(records, index){
			this.field = records;
			this.$refs.updateModalRef.$refs.modalEl.show();
		},
    getLists() {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          if(response.data.fields) {
            this.listsData = response.data.fields;
            this.cardsData = response.data.cards;
            this.tenantsData = response.data.tenants;
          }
        	this.totalRows = this.listsData.length;
        }
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
		formSuccess(){
			this.$refs.modal.$refs.modalEl.hide();
			this.fieldType = '';
      this.getLists();
    },
		updateSuccess(newField){
			this.$refs.updateModalRef.$refs.modalEl.hide();
			this.field = '';

			this.listsData.find(this.updateById(newField)).label = newField.label;
			this.listsData.find(this.updateById(newField)).tooltip = newField.tooltip;
			this.listsData.find(this.updateById(newField)).visibility = newField.visibility;
			this.listsData.find(this.updateById(newField)).slug = newField.slug;
			this.listsData.find(this.updateById(newField)).card = newField.card;
			this.listsData.find(this.updateById(newField)).tenants = newField.tenants;
		},
		updateById(newField){
			return function(field){
				return field.id == newField.id;
			}
		}
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
		<newModal :type="fieldType" ref="modal" @form-success="formSuccess"/>
		<updateModal :field="field" :cards="cardsData" :tenants="tenantsData" ref="updateModalRef" @update-success="updateSuccess"/>
    <div class="row">
      <div class="col-md-6 col-lg-4">
            <b-form-group label="Add New Field" label-for="customfield-type" >
							<b-form-select id="customfield-type" class="form-select bg-none" v-model="fieldType" :options="customFieldTypes" required></b-form-select>
						</b-form-group>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      class="form-select form-select-sm bg-none"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    <span class="visually-hidden">Search:</span>
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control rounded-pill"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                class="project-list-table datatables custom-fields-table"
                :items="listsData"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
								@row-clicked="fieldClicked"
                borderless
              ></b-table>
            </div>
      </div>
    </div>
  </Layout>
</template>