<script>
import axios from 'axios';

/**
 * New Location Modal component
 */
export default {
  props: ['parentdata'],
  data() {
    return {
      createLocationUrl: "/data/tenants/create-tenant-location",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      region: "",
      name: "",
      name_slug: "",
      is_active: true,
      latitude: "",
      longitude: "",
	  adding: false,
	  showModal: false
    };
  },
  methods: {
	createNewLocation(){
		if(!this.parentdata)
			return;

		let formData = new FormData(document.getElementById("create-new-location"));
		formData.append('tenant', this.parentdata);

		this.adding = true;

		axios.post(this.createLocationUrl, formData).then(response => {
			if(response.data && response.data.success) {
				this.clearForm();
				this.showModal = false;
				if(response.data.locations) {
					this.$emit('reload-tenant-locations', response.data.locations);
				}
			}

			this.adding = false;

			/* handleResponse is a global function inside /js/mixins/global.mixins.js
			*/
			this.handleResponse(response);
		}).catch(error => {

			this.adding = false;

			if(error.response){
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	},
	clearForm() {
      this.address = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.country = "";
      this.region = "";
      this.name = "";
      this.name_slug = "";
      this.latitude = "";
      this.longitude = "";
	},
	getTenantsData(){
		axios.get("/data/superadmin/tenants?all").then(response=> {
			if(response.data)
				this.tenants = response.data;
		}).catch(error => {
			if(error.response) {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	},
	onFocused(e) {
		e.target.parentElement.classList.add('is-focused');
    },
	onBlurred(e) {
		if(e.target.value === "") {
			e.target.parentElement.classList.remove('is-focused');
		}
    }
  },
  mounted() {
	  // console.log(this.parentdata);
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.modal-new-user variant="outline-success" class="d-flex align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add Location</b-button>
		<b-modal id="modal-new-user" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
          	<h2 class="text-uppercase card-title">Add location</h2>
			<form action="/data/superadmin/create-tenant-location" id="create-new-location" method="POST" @submit.prevent="createNewLocation">
					<span class="invalid-feedback" role="alert">
					<strong>message</strong>
					</span>
					<div class="form-group d-flex align-items-center">
						<div class="form-switch form-switch-md" dir="ltr">
							<input
								class="form-check-input"
								type="checkbox"
								name="is_active"
								id="is_active_link"
								v-model="is_active"
								value="1"
								checked
							/>
						</div>
						<label class="ms-1 mb-0" for="is_active_link">Active?</label>
					</div>

					<div class="form-group label-placeholder">
						<label for="name">Location Name</label>
						<input type="text" id="name" name="name" class="form-control" v-model="name" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" required>
					</div>

					<div class="form-group label-placeholder">
						<label for="address">Address</label>
						<input type="text" id="address" name="address" class="form-control" v-model="address" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" required>
					</div>

					<div class="form-group label-placeholder">
						<label for="city">City</label>
						<input type="text" id="city" name="city" class="form-control" v-model="city" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" required>
					</div>

					<div class="form-group label-placeholder">
						<label for="state">State</label>
						<input type="text" id="state" name="state" class="form-control" v-model="state" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group label-placeholder">
						<label for="zipcode">Zip Code</label>
						<input type="text" id="zipcode" name="zipcode" class="form-control" v-model="zipcode" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group label-placeholder">
						<label for="country">Country</label>
						<input type="text" id="country" name="country" class="form-control" v-model="country" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group label-placeholder">
						<label for="region">Region</label>
						<input type="text" id="region" name="region" class="form-control" v-model="region" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group label-placeholder">
						<label for="latitude">Latitude</label>
						<input type="text" id="latitude" name="latitude" class="form-control" v-model="latitude" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group label-placeholder">
						<label for="longitude">Longitude</label>
						<input type="text" id="longitude" name="longitude" class="form-control" v-model="longitude" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)">
					</div>

					<div class="form-group new-tenant-name text-center mt-3">
						<b-button variant="success" type="submit" :disabled="adding">
							<span v-html="adding ? 'Adding' : 'Add Location'">Add Location</span>
						</b-button>
					</div>
			</form>
		</b-modal>
	</div>
</template>