<script>
import Vertical from "./vertical";

export default {
  props: ['user'],
  components: { Vertical },
  data() {
    return {};
  },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {},
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl');
  }
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'" :user="user">
      <slot />
    </Vertical>
  </div>
</template>
