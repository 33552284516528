import homeComponent from "./../views/home"

import dashboardCustomersComponent from "./../views/dashboard/tenants/index" //dashboard-customers
import dashboardLocationsComponent from "./../views/dashboard/locations/index" //dashboard-locations
import dashboardUsersComponent from "./../views/dashboard/users/index" //dashboard-users
import dashboardUsersComponentNewmodal from "./../views/dashboard/users/new-modal" //dashboard-users-new-modal
import dashboardCustomfieldsComponent from "./../views/dashboard/customfields/index" //dashboard-customfields
import dashboardCustomfieldsComponentPresets from "./../views/dashboard/customfields/presets" //dashboard-customfields-presets
import dashboardCustomfieldsComponentCards from "./../views/dashboard/customfields/cards" //dashboard-customfields-cards
import dashboardSettingsComponent from "./../views/dashboard/settings/index" //dashboard-settings

import clientLocationsComponent from "./../views/client/locations/index" //client-locations
import clientCardsComponent from "./../views/client/cards/index" //client-cards
import clientUsersComponent from "./../views/client/users/index" //client-users
import clientSettingsComponent from "./../views/client/settings/index" //client-settings
import clientComponent from "./../views/client/index" //client

import cfTextComponent from "./../views/dashboard/customfields/fields/text" //cf-text
import cfTextareaComponent from "./../views/dashboard/customfields/fields/textarea" //cf-textarea
import cfFileComponent from "./../views/dashboard/customfields/fields/file" //cf-file
import cfLinkedImageComponent from "./../views/dashboard/customfields/fields/linked_image" //cf-linked_image
import cfTelComponent from "./../views/dashboard/customfields/fields/tel" //cf-tel
import cfEmailComponent from "./../views/dashboard/customfields/fields/email" //cf-email
import cfUrlComponent from "./../views/dashboard/customfields/fields/url" //cf-url
import cfCheckboxComponent from "./../views/dashboard/customfields/fields/checkbox" //cf-checkbox
import cfRadioComponent from "./../views/dashboard/customfields/fields/radio" //cf-radio
import cfDateTimeComponent from "./../views/dashboard/customfields/fields/date_time" //cf-date_time
import cfWysiwygComponent from "./../views/dashboard/customfields/fields/wysiwyg" //cf-wysiwyg

import loginComponent from "./../views/original/account/login" //login
import registerComponent from "./../views/original/account/register" //register
import forgotPasswordComponent from "./../views/original/account/forgot-password" //forgot-password
import resetPasswordComponent from "./../views/original/account/reset-password" //reset-password

import pages404Component from "./../views/original/utility/404" //pages-404
import pages500Component from "./../views/original/utility/500" //pages-500

import { authCheckGuard, guestCheckGuard, adminOrClientGuard } from "./guards"

export default [
    { path: '/', component: homeComponent, beforeEnter: adminOrClientGuard },
    { path: '/dashboard/customers/:customer_id?', component: dashboardCustomersComponent, beforeEnter: authCheckGuard },
    { path: '/dashboard/locations/:customer_id?/:secondaryid?', component: dashboardLocationsComponent, beforeEnter: authCheckGuard },
    { path: '/dashboard/users/:user_id?', component: dashboardUsersComponent, beforeEnter: authCheckGuard },
    { path: '/dashboard/users/new/modal', component: dashboardUsersComponentNewmodal, beforeEnter: authCheckGuard },
    { path: '/dashboard/customfields', component: dashboardCustomfieldsComponent, beforeEnter: authCheckGuard },
    { path: '/dashboard/customfields-presets', component: dashboardCustomfieldsComponentPresets, beforeEnter: authCheckGuard },
    { path: '/dashboard/customfields-cards', component: dashboardCustomfieldsComponentCards, beforeEnter: authCheckGuard },
    { path: '/dashboard/settings', component: dashboardSettingsComponent, beforeEnter: authCheckGuard },
    { path: '/client/locations/:customer_id?/:secondaryid?', component: clientLocationsComponent, beforeEnter: authCheckGuard },
    { path: '/client/cards/:customer_id?/:secondaryid?', component: clientCardsComponent, beforeEnter: authCheckGuard },
    { path: '/client/users', component: clientUsersComponent, beforeEnter: authCheckGuard },
    { path: '/client/settings/:customer_id?/:secondaryid?', component: clientSettingsComponent, beforeEnter: authCheckGuard },
    { path: '/client', component: clientComponent, beforeEnter: authCheckGuard },
    { path: '/cf-text', component: cfTextComponent, beforeEnter: authCheckGuard },
    { path: '/cf-textarea', component: cfTextareaComponent, beforeEnter: authCheckGuard },
    { path: '/cf-file', component: cfFileComponent, beforeEnter: authCheckGuard },
    { path: '/cf-linked_image', component: cfLinkedImageComponent, beforeEnter: authCheckGuard },
    { path: '/cf-tel', component: cfTelComponent, beforeEnter: authCheckGuard },
    { path: '/cf-email', component: cfEmailComponent, beforeEnter: authCheckGuard },
    { path: '/cf-url', component: cfUrlComponent, beforeEnter: authCheckGuard },
    { path: '/cf-checkbox', component: cfCheckboxComponent, beforeEnter: authCheckGuard },
    { path: '/cf-radio', component: cfRadioComponent, beforeEnter: authCheckGuard },
    { path: '/cf-date_time', component: cfDateTimeComponent, beforeEnter: authCheckGuard },
    { path: '/cf-wysiwyg', component: cfWysiwygComponent, beforeEnter: authCheckGuard },
    { path: '/login', component: loginComponent, beforeEnter: guestCheckGuard },
    { path: '/register', component: registerComponent, beforeEnter: authCheckGuard },
    { path: '/forgot-password', component: forgotPasswordComponent, beforeEnter: authCheckGuard },
    { path: '/reset-password', component: resetPasswordComponent, beforeEnter: authCheckGuard },
    { path: '/pages-404', component: pages404Component, beforeEnter: authCheckGuard },
    { path: '/pages-500', component: pages500Component, beforeEnter: authCheckGuard },
    { path: '*', component: pages404Component, beforeEnter: authCheckGuard },
  ];