<script>
import axios from 'axios';
import CheckboxEdit from '../../../plugins/CheckboxEdit';
import DatepickerEdit from '../../../plugins/DatepickerEdit';
import {_} from 'vue-underscore';

/**
 * New Location Modal component
 */
export default {
  components: {DatepickerEdit, CheckboxEdit},
  props: ['holiday', 'tenant'],
  data() {
    return {
      applyHolidayUrl: "/data/superadmin/update-tenant-holiday",
      is_annual: 0,
      holidayname: "",
      holidaydate: "",
      open_time: "",
      close_time: "",
      is_closed: '1',
      is_closed_options: [
        { value: '1', text: 'Closed' },
        { value: '0', text: 'Open' },
      ],
      showModal: false
    };
  },
  methods: {
    applyHoliday(){
      if(!this.holidayname || !this.holidaydate || (this.is_closed == '0' && (!this.open_time || !this.close_time))) { this.warningAlert('Missing required fields'); return; }

      let formData = new FormData();
      formData.append('tenant', this.tenant);
      formData.append('id', this.holiday.id);
      formData.append('holiday_name', this.holidayname);
      formData.append('start_date', this.holidaydate);
      formData.append('is_annual', this.is_annual);
      formData.append('is_closed', this.is_closed);
      formData.append('open_time', this.open_time);
      formData.append('close_time', this.close_time);

      axios.post(this.applyHolidayUrl, formData).then(response => {
        if(response.data && response.data.success && response.data.holiday) {
          this.showModal = false;
          this.$emit('replace-tenant-holiday', response.data.holiday);
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    updateIsAnnual(val){
      this.is_annual = val;
    },
    updateDate(val){
      this.holidaydate = val;
    },
    updateTime(val, field){
      if(field == 'open_time') this.open_time = val;
      else if(field == 'close_time') this.close_time = val;
    },
  },
  mounted() {
	  if(this.holiday) {
      this.holidayname = this.holiday.holiday_name;
      this.holidaydate = this.holiday.start_date;
      this.is_annual = this.holiday.is_annual;
      this.is_closed = this.holiday.is_closed;
      this.open_time = this.holiday.open_time;
      this.close_time = this.holiday.close_time;
    }
  },
};
</script>

<template>
	<div class="d-inline">
    <b-button v-b-modal="'apply-holiday-modal' + holiday.id" variant="link" class="text-primary px-0">Edit</b-button>
    <b-modal size="lg" :id="'apply-holiday-modal' + holiday.id" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
      <h2 class="text-uppercase card-title">Edit Holiday</h2>
      <b-form @submit.prevent="applyHoliday" ref="shForm">
        <b-form-group label="Date:" label-for="monday_end_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit :defaultvalue="holiday.start_date" id="monday_end" field="monday_end" type="date" dateformat="YYYY-MM-DD" v-bind:text="'YYYY-MM-DD'" v-on:text-updated-blur="updateDate"></DatepickerEdit>
        </b-form-group>
        <b-form-group label="Holiday Name:" label-for="cf-visibility-input" label-cols-sm="5" content-cols-sm="7">
          <input type="text" name="holidayname" placeholder="Enter name of holiday" class="form-control" v-model="holidayname" required>
        </b-form-group>
        <div class="fieldset form-group form-row">
          <legend class="col-sm-5">Annual</legend>
          <div class="d-flex col-sm-7">
            <div class="custom-control checkbox custom-radio b-custom-control-lg">
              <input class="custom-control-input" id="is_annual" name="special-hours-annual" :value="is_annual" type="radio" :checked="is_annual" @click="updateIsAnnual(1)" />
              <label class="form-check-label custom-control-label" for="is_annual">
                Yes
              </label>
            </div>

            <div class="custom-control checkbox custom-radio b-custom-control-lg ms-3 mt-0">
              <input class="custom-control-input" id="is_not_annual" :value="is_annual" type="radio" :checked="!is_annual" name="special-hours-annual" @click="updateIsAnnual(0)" />
              <label class="form-check-label custom-control-label" for="is_not_annual">
                No
              </label>
            </div>
          </div>
        </div>
        <b-form-group label="Open status:" label-for="cf-visibility-input" label-cols-sm="5" content-cols-sm="7">
          <b-form-select name="visibility" v-model="is_closed" :options="is_closed_options" :plain="true"></b-form-select>
        </b-form-group>
        <b-form-group label="Open time:" label-for="open_time_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit id="open_time" :defaultvalue="holiday.open_time ? holiday.open_time : 'Enter open time'"  field="open_time" type="time" v-on:text-updated-blur="updateTime"></DatepickerEdit>
        </b-form-group>
        <b-form-group label="Close time:" label-for="close_time_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit id="close_time" :defaultvalue="holiday.close_time ? holiday.close_time : 'Enter close time'" field="close_time" type="time" v-on:text-updated-blur="updateTime"></DatepickerEdit>
        </b-form-group>
        <div class="mt-5"><b-button type="submit" variant="success">Edit</b-button></div>
      </b-form>
		</b-modal>
	</div>
</template>