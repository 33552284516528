<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :viewBox="viewBox"
    :aria-labelledby="ariaLabelledBy"
    :style="cssProps"
    role="img"
  >
    <title :id="iconName" lang="en" v-if="iconTitle">{{iconTitle}}</title>
    <defs>
      <linearGradient :id="'activeGradient' + iconName" x1="0" x2="0" y1="0" y2="1">
        <stop class="stop1" offset="0%" style="stop-color:#01A8FF;" />
        <stop class="stop2" offset="100%" style="stop-color:#00BB6B;" />
      </linearGradient>
    </defs>
    <g>
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  computed: {
    ariaLabelledBy(){
      return (this.iconTitle) ? 'this.iconName' : 'null'
    },
    cssProps() {
      return {
        '--gradient-url': 'url(#activeGradient' + this.iconName + ')'
      }
    }
  },
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    iconTitle: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 20
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
        type: String,
        default: '0 0 20 20'
    }
  }
}
</script>

<style scoped>
  .active path{
      fill: var(--gradient-url);
  }
</style>