<script>
import { menuItems, clientMenuItems } from "./../components/menu";
import axios from 'axios';

export default {
  props: ['user'],
  mounted() {
    if(this.user){
      this.$store.commit('setUser', this.user)

      this.checkStorageForCustomers(this.user)
    }
  },
  data() {
    return {
      menuItems: menuItems
    };
  },
  computed: {
    currentpath() {
      return this.$route.path;
    },
    storecustomers() {
      return this.$store.state.customers;
    },
    storecustomer() {
      return this.$store.state.selectedCustomer;
    }
  },
  watch: {
    currentpath: function(newVal, oldVal) {
      if(this.user.is_admin) {
        this.menuItems = menuItems;
      } else {
        this.menuItems = clientMenuItems;
      }
      this.setupPageTitle()
    },
    storecustomer: function(newVal, oldVal) {
      this.updateLocationLink();
    }
  },
  methods: {
    setupPageTitle(){
      for (var i = 0; i < this.menuItems.length; i++) {
        if(this.$route.path.indexOf(this.menuItems[i].link) !== -1){
          document.title = this.$t(this.menuItems[i].label) + " | Reachist"
        }
      }
    },
    checkStorageForCustomers(user) {
      if (user.id === undefined) return;

      let dataurl = '/data/tenants/tenants';
      if (user.is_admin) {
        dataurl = '/data/superadmin/tenants?all';
      }
      if (!localStorage.getItem('customers')) {
        this.getCustomers(dataurl);
      } else {
        this.updateLocationLink();
      }
    },
    getCustomers(dataurl) {
      axios.get(dataurl).then(response => { 
        let customers = [];
        response.data.forEach((obj) => {
          let customer = {
            id: obj.id,
            name: obj.name
          }
          customers.push(customer);
        });
        this.$store.commit('setCustomers', customers);
        this.$store.commit('setSelectedCustomer', 0);
        if (!this.user.is_admin) {
          location = '/#/client/locations/' + this.storecustomer;
        } else {
          this.updateLocationLink();
        }
        
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    updateLocationLink() {
      this.menuItems.find((i) => {
        if (i.label === 'menuitems.dashboard.list.locations') {
          if (i.link.includes('/locations')) {
            i.link = i.link.split('/locations')[0] + '/locations';
          }
          i.link += '/' + this.storecustomer;
        }
      });
    }
  },
};
</script>

<template>

</template>