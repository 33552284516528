<script>
import axios from "axios";
import CheckboxEdit from '../../../plugins/CheckboxEdit';

/**
 * New CustomField Modal component
 */
export default {
  components: { CheckboxEdit },
  props: ['type'],
  data() {
    return {
      options: "",
      showModal: false,
      fieldTooltip: '',
      fieldSlug: '',
      fieldType: '',
      is_private: false,
      modalTitle: 'Add New Field',
      createCFUrl: '/data/superadmin/create-customfield',
      fieldComponent: false
    };
  },
	watch: {
		type: function(newType, oldType){
      this.fieldType = newType;

      if (newType)
        this.modalTitle = 'Add New ' + this.namify(newType) + ' Field';

      this.fieldComponent = newType != '' ? 'cf-' + newType : false;
		},
		fieldSlug: function(newSlug, oldSlug){
      this.fieldSlug = this.slugify(newSlug);
		}
	},
  computed: {
    isOptions(){
      return this.fieldType == 'radio';
    }
	},
  methods: {
    clearForm() {
      this.options = '';
      this.fieldTooltip = '';
      this.fieldSlug = '';
      this.modalTitle = 'Add New Field';
      this.showModal = false;
      this.fieldComponent = false;
      this.$refs.componentContainer.clearForm();

      this.$emit('form-success');
    },
    onSubmit(){
      let formData = new FormData(this.$refs.cfForm);

      let visibility = this.is_private ? 'private' : 'public';
      formData.set('visibility', visibility);
      if(this.options)
        formData.set('options', this.options);

      this.adding = true;

      axios.post(this.createCFUrl, formData).then(response => {

        if(response.data && response.data.status && response.data.status == 'success') {
          this.clearForm();
        }

        this.adding = false;

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {

        this.adding = false;

        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    onReset() {
      this.label = "";
      this.tooltip = "";
    },
    visibilityChanged(val) {
      this.is_private = val;
    },
    valueUpdated(value) {
      this.fieldSlug = value.replace(/\s+/g, '_').toLowerCase();
    }
  },
};
</script>

<template>
	<div class="">
		<b-modal id="modal-new-cf" hide-footer v-model="showModal" ref="modalEl">
      <template #modal-header="{ close }">
        <button type="button" class="close" @click="close()">
          <span class="visually-hidden">Close Dialog</span>
          <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
        </button>
      </template>
      <h2 class="text-uppercase card-title">Add new custom field</h2>
      <b-form @submit.prevent="onSubmit" @reset="onReset" ref="cfForm">
        <component :is="fieldComponent" v-if="fieldComponent" @value-updated="valueUpdated" ref="componentContainer"/>

        <b-form-group label="Tooltip:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
          <b-form-input v-model="fieldTooltip" name="tooltip"></b-form-input>
        </b-form-group>
        <b-form-group label="Slug:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
          <b-form-input v-model="fieldSlug" name="slug"></b-form-input>
          <input type="hidden" name="type" v-model="fieldType"/>
        </b-form-group>
        <b-form-group label-cols-sm="5" content-cols-sm="7">
         <CheckboxEdit :value="is_private" id="cf-visibility-input" inputLabel="Private" v-on:checkbox-clicked="visibilityChanged"></CheckboxEdit>
        </b-form-group>

        <b-form-group label="Options:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7" v-if="isOptions">
          <textarea placeholder="value:Label separated by |" class="" v-model="options"></textarea>
        </b-form-group>

        <div class="mt-5"><b-button type="submit" variant="success">Submit</b-button></div>
      </b-form>
		</b-modal>
	</div>
</template>