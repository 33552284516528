<script>
import axios from 'axios';

/**
 * New Location Modal component
 */
export default {
  props: ['parentdata'],
  data() {
    return {
      createLocationUrl: "/data/superadmin/create-tenant-location",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      region: "",
      name: "",
      name_slug: "",
      is_active: true,
      latitude: "",
      longitude: "",
	  adding: false,
	  showModal: false
    };
  },
  methods: {
	createNewLocation(){
		if(!this.parentdata)
			return;

		let formData = new FormData(document.getElementById("create-new-location"));
		formData.append('tenant', this.parentdata.id);

		this.adding = true;

		axios.post(this.createLocationUrl, formData).then(response => {
			if(response.data && response.data.success) {
				this.clearForm();
				this.showModal = false;
				if(response.data.locations) {
					this.$emit('reload-tenant-locations', response.data.locations);
				}
			}

			this.adding = false;

			/* handleResponse is a global function inside /js/mixins/global.mixins.js
			*/
			this.handleResponse(response);
		}).catch(error => {

			this.adding = false;

			if(error.response){
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	},
	clearForm() {
      this.address = "";
      this.city = "";
      this.state = "";
      this.zipcode = "";
      this.country = "";
      this.region = "";
      this.name = "";
      this.name_slug = "";
      this.latitude = "";
      this.longitude = "";
	},
	getTenantsData(){
		axios.get("/data/superadmin/tenants?all").then(response=> {
			if(response.data)
				this.tenants = response.data;
		}).catch(error => {
			if(error.response) {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	}
  },
  mounted() {
	// console.log(this.parentdata);
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.modal-new-user variant="primary" class="btn-sm"><i class="bx bx-plus font-size-16 align-middle"></i></b-button>
		<b-modal id="modal-new-user" title="Add New Location" title-class="font-18" hide-footer v-model="showModal">
			<form action="/data/superadmin/create-tenant-location" id="create-new-location" method="POST" @submit.prevent="createNewLocation">
				<div class="modal-body">
					<span class="invalid-feedback" role="alert">
					<strong>message</strong>
					</span>
					<div class="form-group">
						<div class="checkbox"><br>
							<input type="checkbox" name="is_active" class="checkbox-input" id="is_active_link" v-model="is_active" value="1" checked>
							<label for="is_active_link">Active?</label>
						</div>
					</div>

					<label>Name: </label>
					<div class="form-group">
					<input type="text" name="name" placeholder="Location Name" class="form-control" v-model="name" required>
					</div>

					<label>Address: </label>
					<div class="form-group">
					<input type="text" name="address" placeholder="Address" class="form-control" v-model="address" required>
					</div>

					<label>City: </label>
					<div class="form-group">
					<input type="text" name="city" placeholder="City" class="form-control" v-model="city" required>
					</div>

					<label>State: </label>
					<div class="form-group">
					<input type="text" name="state" placeholder="State" class="form-control" v-model="state">
					</div>

					<label>Zip Code: </label>
					<div class="form-group">
					<input type="text" name="zipcode" placeholder="Zip Code" class="form-control" v-model="zipcode">
					</div>

					<label>Country: </label>
					<div class="form-group">
					<input type="text" name="country" placeholder="Country" class="form-control" v-model="country">
					</div>

					<label>Region: </label>
					<div class="form-group">
					<input type="text" name="region" placeholder="Region" class="form-control" v-model="region">
					</div>

					<hr/>

					<label>Latitude: </label>
					<div class="form-group">
					<input type="text" name="latitude" placeholder="Latitude" class="form-control" v-model="latitude">
					</div>

					<label>Longitude: </label>
					<div class="form-group">
					<input type="text" name="longitude" placeholder="Longitude" class="form-control" v-model="longitude">
					</div>

					<label></label>
					<div class="form-group new-tenant-name text-end">
						<b-button block variant="primary" type="submit" class="ml-1" :disabled="adding">
						<i class="bx bx-check d-block d-sm-none"></i>
						<span class="d-none d-sm-block">
							<span v-html="adding ? 'Adding' : 'Add'">Add</span>
							<i v-if="adding" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
						</span>
						</b-button>
					</div>
				</div>
			</form>
		</b-modal>
	</div>
</template>