<script>
import axios from 'axios';
import CheckboxEdit from '../../../plugins/CheckboxEdit';
import DatepickerEdit from '../../../plugins/DatepickerEdit';
import {_} from 'vue-underscore';

/**
 * New Location Modal component
 */
export default {
  components: {DatepickerEdit, CheckboxEdit},
  props: ['parentdata', 'islocation', 'locationdata'],
  data() {
    return {
      // addHolidayUrl: "/data/superadmin/add-tenant-holiday",
      addHolidayUrl: "/data/superadmin/add-tenant-location-holiday",
      is_annual: 0,
      holidayname: "",
      holidaydate: "",
      open_time: "",
      close_time: "",
      users: [],
      is_closed: '1',
      adding: false,
      showModal: false
    };
  },
  methods: {
    addHoliday(){
      // console.log('this.holidayname', this.holidayname, 'this.holidaydate', this.holidaydate, 'this.is_closed', this.is_closed, 'this.open_time', this.open_time, 'this.close_time', this.close_time);
      // return;
      if(!this.holidayname || !this.holidaydate || (this.is_closed == '0' && (!this.open_time || !this.close_time))) { this.warningAlert('Missing required fields'); return; }

      let formData = new FormData();
      formData.append('tenant', this.parentdata.id);
      formData.append('holiday_name', this.holidayname);
      formData.append('start_date', this.holidaydate.replace(/(..).(..).(....)/, "$3-$1-$2"));
      formData.append('is_annual', this.is_annual);
      formData.append('is_closed', this.is_closed);
      formData.append('open_time', this.open_time);
      formData.append('close_time', this.close_time);

      // if(this.islocation && this.locationdata && this.locationdata.id) {
      //   formData.append('location', this.locationdata.id);
      //   this.addHolidayUrl = "/data/superadmin/add-tenant-location-holiday";
      // }
      formData.append('locations[]', this.locationdata.id);

      axios.post(this.addHolidayUrl, formData).then(response => {
        if(response.data && response.data.success && response.data.holiday) {
          this.showModal = false;
          this.clearForm();
          this.$emit('reload-tenant-holiday', response.data.holiday);
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {

        this.adding = false;

        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    clearForm() {
      this.holidayname = "";
      this.holidaydate = "";
      this.is_annual = 0;
    },
    updateIsAnnual(val){
      this.is_annual = val;
    },
    updateIsClosed(val){
      this.is_closed = val;
    },
    updateTime(val, field){
      if(field == 'open_time') this.open_time = val;
      else if(field == 'close_time') this.close_time = val;
    },
    updateDate(val){
      this.holidaydate = val;
    }
  },
  computed: {
    userids() {
      return (this.parentdata && this.parentdata.users) ? _.pluck(this.parentdata.users, 'id') : [];
    }
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.add-holiday-modal variant="outline-success" class="d-flex align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Holiday</b-button>
		<b-modal id="add-holiday-modal" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
      <h2 class="text-uppercase card-title">Add Holiday</h2>
      <b-form @submit.prevent="addHoliday" ref="shForm">
        <b-form-group label="Holiday Name" label-for="cf-visibility-input" label-cols-sm="5" content-cols-sm="7">
          <input type="text" name="holidayname" placeholder="Enter name of holiday" class="form-control no-border" v-model="holidayname" required>
        </b-form-group>
        <b-form-group label="Date" label-for="monday_end_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit id="monday_end" field="monday_end" type="date" dateformat="MM/DD/YYYY" v-bind:text="'MM/DD/YYYY'" v-on:text-updated-blur="updateDate" defaultvalue="Enter Date"></DatepickerEdit>
        </b-form-group>
        <div class="fieldset form-group form-row">
          <legend class="col-sm-5">Annual:</legend>
          <div class="d-flex col-sm-7">
            <div class="custom-control checkbox custom-radio b-custom-control-lg">
            <input class="custom-control-input" id="is_annual" name="special-hours-annual" :value="is_annual" type="radio" @click="updateIsAnnual(1)" />
              <label class="form-check-label custom-control-label" for="is_annual">
                Yes
              </label>
            </div>

            <div class="custom-control checkbox custom-radio b-custom-control-lg ms-3 mt-0">
              <input class="custom-control-input" id="is_not_annual" :value="is_annual" type="radio" name="special-hours-annual" @click="updateIsAnnual(0)" />
              <label class="form-check-label custom-control-label" for="is_not_annual">
                No
              </label>
            </div>
          </div>
        </div>
        <div class="fieldset form-group form-row">
          <legend class="col-sm-5">Open status:</legend>
          <div class="d-flex col-sm-7">
            <div class="custom-control checkbox custom-radio b-custom-control-lg">
            <input class="custom-control-input" id="is_open" name="special-hours-open-status" :value="is_closed" type="radio" @click="updateIsClosed(0)" />
              <label class="form-check-label custom-control-label" for="is_open">
                Open
              </label>
            </div>

            <div class="custom-control checkbox custom-radio b-custom-control-lg ms-3 mt-0">
              <input class="custom-control-input" id="is_closed" name="special-hours-open-status" :value="is_closed" type="radio" @click="updateIsClosed(1)" />
              <label class="form-check-label custom-control-label" for="is_closed">
                Closed
              </label>
            </div>
          </div>
        </div>
        <b-form-group label="Open time" label-for="open_time_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit id="open_time" field="open_time" type="time" v-on:text-updated-blur="updateTime" defaultvalue="Enter open time"></DatepickerEdit>
        </b-form-group>
        <b-form-group label="Close time" label-for="close_time_datepicker" label-cols-sm="5" content-cols-sm="7">
          <DatepickerEdit id="close_time" field="close_time" type="time" v-on:text-updated-blur="updateTime" defaultvalue="Enter close time"></DatepickerEdit>
        </b-form-group>
        <div class="mt-5 mb-2"><b-button type="submit" variant="success">Save Changes</b-button></div>
        <p class="small">*To assign these hours to additional locations, click Bulk Update next to entry</p>
      </b-form>
		</b-modal>
	</div>
</template>