<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import axios from "axios";

/**
 * Custom Fields component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      dataurl: '/data/superadmin/customfields-presets',
      createurl: '/data/superadmin/create-customfields-presets',
      updateurl: '/data/superadmin/update-customfields-presets',
      presets: [],
      checkedPresets: [],
      listData: [],
      listsData: [],

      selectedPreset: "",
      newPresetName: "",

      title: "Custom Fields Presets",

      items: [
        {
          text: "Presets",
          href: "/",
        }
      ]
    };
  },
  computed: {
    presetSelection() {
      if(this.presets) {
        return this.presets.map(item => {
            const container = {};

            container['value'] = item.id;
            container['text'] = item.name;
            container.data = item.presets;

            return container;
        });
      } return [];
    },
    presetData() {
      if(this.selectedPreset) {
      } return [];
    },
  },
	watch: {
		selectedPreset: function(val){
      if(val) {
        let data = this.customFieldFilter(this.presets, val);

        if(data[0] && data[0].presets) {
          this.checkedPresets = data[0].presets;
          this.newPresetName = data[0].name;
        }
      }
		}
	},
  mounted() {
    // Set the initial number of items
    this.getLists();
  },
  methods: {
    clear() {
      this.newPresetName = '';
      this.selectedPreset = '';
      this.checkedPresets = [];
    },
    update() {
      if(!this.selectedPreset) return;

      let formData = new FormData();
      formData.append('preset_id', this.selectedPreset);
      formData.append('presets', this.checkedPresets);

      if(this.newPresetName)
        formData.append('name', this.newPresetName);

      axios.post(this.updateurl, formData).then(response => {
        if(response.data.status && response.data.status == 'success'){
          this.presets = response.data.data;
          this.newPresetName = '';
          this.selectedPreset = '';
          this.checkedPresets = [];
        }
 
        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
      });
    },
    save() {
      if(!this.newPresetName) return;

      let formData = new FormData();
      formData.append('name', this.newPresetName);
      formData.append('presets', this.checkedPresets);

      axios.post(this.createurl, formData).then(response => {
        if(response.data.status && response.data.status == 'success'){
          this.newPresetName = '';
          this.checkedPresets = [];
          this.presets = response.data.data;
        }
          
        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
      });
    },
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data.fields) {
            this.listsData = response.data.fields;
            this.presets = response.data.presets;
        }
      }).catch(error => {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
      });
    },
    checkIfCFieldChecked(customFieldId){
      if(this.presetData) {
        return this.customFieldFilter(this.presetData, customFieldId).length;
      } return false;
    },
    customFieldFilter(customfields, rId){
      return customfields.filter(function(obj){
        return obj.id == rId;
      });
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title mb-4">{{title}}</h1>
						<div class="row">
							<div class="col-md-3">
								<label for="customfield-type">Select Preset</label>
								<b-form-select class="form-select" id="customfield-type" v-model="selectedPreset" :options="presetSelection" required></b-form-select>
							</div>
							<div class="col-md-3">
								<label for="customfield-type">Preset Name</label>
                    <b-form-input
                      v-model="newPresetName"
                      type="text"
                      placeholder="Preset Name"
                      ></b-form-input>
							</div>
							<div class="col-md-3" v-show="newPresetName && !selectedPreset">
								<label for="customfield-type">&nbsp;</label>
                  <b-button block variant="primary" role="button" enabled="enabled" @click.prevent="save">
                    Save
                  </b-button>
							</div>
							<div class="col-md-3" v-show="selectedPreset">
								<label for="customfield-type">&nbsp;</label>
                  <b-button block variant="primary" role="button" enabled="enabled" @click.prevent="update">
                    Update
                  </b-button>
							</div>
							<div class="col-md-3">
								<label for="customfield-type">&nbsp;</label>
                  <b-button block variant="danger" role="button" @click.prevent="clear">
                    Clear
                  </b-button>
							</div>
						</div>
            <div class="table-responsive mb-0 mt-4 row ms-0">
              <div v-for="customField in listsData" class="custom-control checkbox custom-checkbox b-custom-control-lg mb-3 mt-0 col-sm-12 col-md-6 col-lg-4">
                <input class="form-check-input checkbox-input custom-control-input" name="preset" type="checkbox" v-model="checkedPresets" :id="customField.slug" :value="customField.slug" :checked="checkIfCFieldChecked(customField.id)" />
                <label :for="customField.slug" class="form-check-label custom-control-label">{{customField.label}} ({{customField.visibility}})</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>