<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import TenantsListTemplate from './tenantsListTemplate';
import TenantTemplate from './tenantDetailsTemplate';

import axios from "axios";

/**
 * Projects-list component
 */
export default {
  components: {
    Layout,
    PageHeader,
    TenantsListTemplate,
    TenantTemplate
  },
  computed: {
    customer_id() {
      return this.$route.params.customer_id == undefined ? false : this.$route.params.customer_id;
    },
  },
  watch: { 
    customer_id: function(newVal, oldVal) {
      this.initiateComponents()
    }
  },
  data() {
    return {
      getdataurl: '/data/superadmin/tenants',
      dataurl: '/data/superadmin/tenants',
      listData: [],
      listsData: [],
      title: "Customers List",
      items: [
        {
          text: "Customers",
          href: "/dashboard/customers",
        },
        {
          text: "Customers List",
          active: true,
        },
      ],
    };
  },
  methods: {
    loadMore() {
      this.dataurl = this.listsData.next_page_url;
      this.listsData.loading = true;
      this.getLists(true);
    },
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          if(append) {
            this.listsData.data.push.apply(this.listsData.data, response.data.data);
            this.listsData.next_page_url = response.data.next_page_url;
            this.listsData.loading = false;
          } else {
            this.listsData = response.data;
          }
        }
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    initiateComponents() {
      if(this.customer_id) { // single tenant details
        this.$store.commit('setSearchSettings', {'tenant': this.customer_id});

        this.title = this.customer_id + ' Details';
        this.items[1].text = 'Customer Details';

        axios.get(this.getdataurl + '/' + this.customer_id).then(response => { 
          if(response.data) {
            this.listData = response.data;
          }
        }).catch(error => { 
          if(error.response) {
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else {
        this.getLists();
      }
    }
  },
  mounted() {
    this.initiateComponents()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <TenantsListTemplate v-if="listsData && listsData.data && !customer_id" :listsData="listsData"></TenantsListTemplate>
    <TenantTemplate v-if="listData && listData.data && customer_id" :listData="listData"></TenantTemplate>
  </Layout>
</template>