<script>
import axios from 'axios';
import { XlsCsvParser } from 'vue-xls-csv-parser';

/**
 * New Location Modal component
 */
export default {
  components: { XlsCsvParser },
  props: ['tenantid'],
  data() {
    return {
      showModal: false,
      uploading: false,
      uploadData: [],
      createLocationUrl: "/data/superadmin/create-tenant-location",
      currentRow: 0,
      rows: 0,
      cols: 0,
      columns: [
        { name: 'Location name', value: 'name' },
        { name: 'Address', value: 'address' },
        { name: 'City', value: 'city' },
        { name: 'State', value: 'state' },
        { name: 'Zip', value: 'zip'},
        { name: 'Country', value: 'country', isOptional: true},
        { name: 'Region', value: 'region', isOptional: true},
        { name: 'Latitude', value: 'latitude', isOptional: true},
        { name: 'Longitude', value: 'longitude', isOptional: true},
        { name: 'Custom Fields', value: 'fields', isOptional: true},
        { name: 'Phone', value: 'phone', isOptional: true},
        { name: 'Facebook URL', value: 'facebook_url', isOptional: true},
        { name: 'Twitter Handle', value: 'twitter_handle', isOptional: true},
        { name: 'Yelp URL', value: 'yelp_url', isOptional: true},
        { name: 'Instagram URL', value: 'instagram_url', isOptional: true},
        { name: 'Is Active', value: 'is_active', isOptional: true},
        { name: 'Is Online Ordering', value: 'is_online_ordering', isOptional: true},
        { name: 'Is fundraising', value: 'is_fundraising', isOptional: true},
        { name: 'Skip This Column', value: 'skip', isOptional: true},
      ],
      results: null,
      help: 'Necessary columns are: name, address, city, state and zip',
    };
  },
  methods: {
    onValidate(results) {
      if(!this.tenantid) return;

      this.results = results;
      this.rows = results[0].data.length;
      this.cols = results.length;

      for (let i = 0; i < this.rows; i++) {
        let rowData = [];
        for (let t = 0; t < this.cols; t++) {
          let colName = results[t].column;
          let dataVal = results[t].data[i];
          if(colName != 'skip')
            rowData[colName] = dataVal;
        }
        this.uploadData.push(rowData);
      }

      if(this.rows == this.uploadData.length) {
        this.beginUpload();
      }
    },
    beginUpload(){
      this.uploading = true;

      if(this.uploadData.length > 0) {
        this.currentRow++;

        let row = this.uploadData.pop();

        let formData = new FormData();

        formData.append('tenant', this.tenantid);
        if(this.uploadData.length > 0)
          formData.append('bulk', 1);

        Object.keys(row).forEach(key => {
          formData.append(key, row[key]);
        });

        axios.post(this.createLocationUrl, formData).then(response => {
          if(response.data && response.data.success) {
            if(response.data.locations !== true && response.data.locations.length > 0) {
              this.$emit('reload-tenant-locations', response.data.locations);
            }
            this.beginUpload();
          }

          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          if(this.uploadData.length == 0 && !this.uploading) this.handleResponse(response);
        }).catch(error => {

          this.adding = false;

          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else {
        this.uploading = false;
      }
    },
    getFields(){
      return;
    }
  },
  mounted() {
  }
};
</script>

<template>
	<div>
        <a href="#" v-b-modal.mass-upload-modal @click.prevent="getFields" class="btn btn-primary btn-xl btn-block">Mass Import</a>
        <b-modal size="lg" id="mass-upload-modal" title="Mass Import" title-class="font-18 text-center" hide-footer v-model="showModal">
          <template #modal-header="{ close }">
            <button type="button" class="close" @click="close()">
              <span class="visually-hidden">Close Dialog</span>
              <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
            </button>
          </template>
          <div class="modal-body">
            <xls-csv-parser :columns="columns" @on-validate="onValidate" :help="help" lang="en" v-if="!uploading"></xls-csv-parser>
            <div class="status text-center" v-else>
              Importing <span v-html="currentRow"></span> out of <span v-html="rows"></span>
            </div>
          </div>
        </b-modal>
	</div>
</template>