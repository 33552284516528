<template>
	<span>
		<b-button :id="tooltipID" v-if="tooltip" variant="tooltip">
			<svg aria-hidden="true" focusable="false" role="presentation" width="5" height="5" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.459 3a.232.232 0 0 1-.132-.04.28.28 0 0 1-.093-.104.309.309 0 0 1-.035-.145.302.302 0 0 1 .128-.25.24.24 0 0 1 .132-.04c.048 0 .092.014.131.04.04.025.072.06.095.104a.302.302 0 0 1 .035.146.309.309 0 0 1-.035.145.275.275 0 0 1-.095.104.227.227 0 0 1-.131.04Zm-.18-.895v-.766c.021.004.047.01.075.014a.74.74 0 0 0 .09.005.618.618 0 0 0 .273-.056.416.416 0 0 0 .18-.168.516.516 0 0 0 .064-.267c0-.099-.02-.184-.059-.255a.403.403 0 0 0-.166-.165.528.528 0 0 0-.253-.058.47.47 0 0 0-.336.123.508.508 0 0 0-.15.338L.666.778A.937.937 0 0 1 .807.36.772.772 0 0 1 1.1.093.824.824 0 0 1 1.487 0a.815.815 0 0 1 .737.433c.073.13.11.276.11.436 0 .146-.03.281-.09.405a.838.838 0 0 1-.248.303.726.726 0 0 1-.352.146v.382h-.366Z" fill="currentColor"/></svg>
			<span class="sr-only">More Info</span>
		</b-button>
		<b-tooltip v-if="tooltip" :target="tooltipID" placement="righttop" :title="tooltip" triggers="hover focus">
			{{ tooltip }}
		</b-tooltip>
	</span>
</template>
<script>
export default{
	name: 'TooltipSpan',
	props: {
		id: {
			type: String,
			required: true,
		},
		tooltip: String,
	},
	computed: {
		tooltipID: function() {
			return "tooltip_span_" + this.id;
		}
	}
}
</script>