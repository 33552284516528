<script>
import Header from "./header";

/**
 * Forgot Password component
 */
export default {
    components: { Header },
    data() {
        return {
            password: "",
            password_confirmation: "",
            isResetError: false,
            activeInputClass: 'form-group label-placeholder is-focused',
            defaultInputClass: 'form-group label-placeholder'
        };
    },
    methods: {
        checkAnimation(e) {
            //hack to detect autofill and adjust field state
            //https://rojas.io/how-to-detect-chrome-autofill-with-vue-js/
            if(e.animationName == "onAutoFillStart") {
                e.target.parentElement.classList.add('is-focused');
            }
            else if(e.animationName == "onAutoFillCancel") {
                e.target.parentElement.classList.remove('is-focused');
            }
        },
        onFocused(e) {
            e.target.parentElement.classList.add('is-focused');
        },
        onBlurred(e) {
            if(e.target.value === "") {
                e.target.parentElement.classList.remove('is-focused');
            }
        }
    },
    mounted() {
        this.isResetError = !!this.error;
        document.body.classList.add('bg-dark');
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: false,
            default: () => null
        },
        error: {
            type: String,
            required: false,
            default: () => null
        }
    }
};
</script>

<template>
<div class="container-fluid">
  <Header />
  <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card bg-none">
                <h1 class="text-white text-center h2 mb-3">Reset password</h1>
                <div class="card-body bg-none pt-0">
                    <b-alert v-model="isResetError" class="mb-4" variant="danger" dismissible>{{error}}</b-alert>
                    <b-form class="p-2" :action="submitUrl" method="POST">
                        <slot />
                        <div id="input-group-1" :class="[email ? activeInputClass : defaultInputClass]">
                            <label for="useremail">Email</label>
                            <b-form-input
                                type="email" name="email" 
                                :value="email" 
                                class="form-control bg-none" 
                                id="useremail" 
                                v-on:focus="onFocused($event)"
                                v-on:blur="onBlurred($event)"
                                v-on:animationstart="checkAnimation"
                            ></b-form-input>
                        </div>
                        <div id="password-group" :class="[password ? activeInputClass : defaultInputClass]">
                            <label for="password">Password</label>
                            <b-form-input
                                class="bg-none" 
                                id="password"
                                name="password"
                                type="password"
                                v-on:focus="onFocused($event)"
                                v-on:blur="onBlurred($event)"
                                v-on:animationstart="checkAnimation"
                            ></b-form-input>
                        </div>
                        <div :class="[password_confirmation ? activeInputClass : defaultInputClass]">
                            <label for="password-confirm">Confirm Password</label>
                            <b-form-input
                                class="bg-none" 
                                id="password-confirm"
                                name="password_confirmation"
                                type="password"
                                v-on:focus="onFocused($event)"
                                v-on:blur="onBlurred($event)"
                                v-on:animationstart="checkAnimation"
                            ></b-form-input>
                        </div>
                        <div class="mt-3">
                            <b-button type="submit" variant="success" class="btn-block">Reset</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
            <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center text-white">
            <p>
                Remember It ?
                <a href="/login" class="fw-medium text-primary">Sign In here</a>
            </p>
            <p class="small">
                © {{ new Date().getFullYear() }} Reachist. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Blueshoon
            </p>
        </div>
    </div>
        <!-- end col -->
  </div>
</div>
</template>