import Vue from "vue";
import axios from "axios";

Vue.mixin({
    methods: {
        errorAlert: function (message) {
            Vue.$toast.error(message, {
               position: 'top',
               duration: 3000
            });
        },
        warningAlert: function (message) {
            Vue.$toast.warning(message, {
                position: 'top',
                duration: 3000
            });
        },
        successAlert: function (message) {
            Vue.$toast.success(message, {
                position: 'bottom'
            });
        },
        handleError: function (error) {
            if(error.message)
                this.errorAlert(error.message);
        },
        handleErrors: function (response) {
            if(response.status && (response.status == 403 || response.status == 401 || response.status == 419)) {
                window.location.href = '/';
            }

            if(response.data.errors) {
                for (const [key, value] of Object.entries(response.data.errors)) {
                    this.errorAlert(`${value}`);
                }
            } else if(response.data.message) {
                this.errorAlert(response.data.message);
            } else if(response.status = 419) { // reloads page on token expiration.
                location.reload();
            } else if(response.status >= 400 && response.status < 500) { // or unknown error when on production
                this.errorAlert(response.statusText);
            } else {
                console.log(response);
            }

            if(response.hardredirect) {
                window.location.href = response.hardredirect;
            }

            if(response.data.hardredirect) {
                window.location.href = response.data.hardredirect;
            }
        },
        handleResponse: function (response) {
            if(response.status && response.status == 'success' && response.message) {
                this.successAlert(response.message);
            }

            if(response.redirect) {
                window.location.href = response.redirect;
            }

            if(response.data.status && response.data.status == 'success' && response.data.message) {
                this.successAlert(response.data.message);
            }

            if(response.data.redirect) {
                window.location.href = response.data.redirect;
            }

            if(response.hardredirect) {
                window.location.href = response.hardredirect;
            }

            if(response.data.hardredirect) {
                window.location.href = response.data.hardredirect;
            }

            if(response.data.success) {
              this.successAlert(response.data.message);
            }

            if(response.data.error) {
              this.errorAlert(response.data.message);
            }
        },
        namify: function(s){
            if(typeof s !== 'string') return '';

            const words = s.replace(/_/g, ' ').replace(/-/g, ' ').split(" ");

            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }

            return words.join(" ");
        },
        capitalize: function(s){
            if(typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        slugify: function(s){
            return s.trim().replace(/\s+/g, '_').toLowerCase();
        },
        updateLocationsCustomField: function(newdata, customFieldId, location_id, tenant_id, updateurl = '/data/superadmin/locations/update'){
            if(newdata == '') return false;
            let returnData;
      
            let formData = new FormData();
            formData.append('relation', 'CustomFields');
            formData.append('customfield', customFieldId);
            formData.append('value', newdata);
            formData.append('location_id', location_id);
            formData.append('tenant_id', tenant_id);
      
            axios.post(updateurl, formData).then(response => {
              if(response.data.data)
                returnData = response.data.data;

              this.handleResponse(response);
            }).catch(error => { 
              returnData = false;
              this.handleError(error);
            });
            
            return returnData;
          },
    },
  })