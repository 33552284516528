<script>
/**
 * New User Modal component
 */
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    clearForm(){
      this.value = '';
    }
  },
	watch: {
		value: function(newValue, oldValue){
			if(newValue != '') {
				this.$emit('value-updated', newValue);
			}
		}
	},
};
</script>

<template>
  <b-form-group label="Label:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
    <b-form-input v-model="value" name="label"></b-form-input>
  </b-form-group>
</template>