<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import SettingsListTemplate from './settingsListTemplate';
import SettingTemplate from './settingDetailsTemplate';

import axios from "axios";

export default {
  components: { 
    Layout, 
    PageHeader,
    SettingsListTemplate,
    SettingTemplate
  },
  computed: {
    customer_id() {
      return this.$route.params.customer_id == undefined ? false : this.$route.params.customer_id;
    },
    secondaryid() {
      return this.$route.params.secondaryid == undefined ? false : this.$route.params.secondaryid;
    },
  },
  watch: { 
    customer_id: function() {
      this.initiateComponents()
    },
    secondaryid: function(newVal) {
      this.initiateComponents()
    }
  },
  data() {
    return {
      getdataurl: '/data/tenants/tenants',
      dataurl: '/data/tenants/tenants',
      tenant: null,
      listsData: [],
      title: "Settings",
      items: [
        {
          text: "Settings",
          href: "/dashboard/settings",
        },
        {
          text: "Settings List",
          active: true,
        },
      ],
    };
  },
  methods: {
    getLists() {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          this.listsData = response.data;
        }
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    initiateComponents() {
      if(this.customer_id) {
        this.dataurl = this.dataurl + '/' + this.customer_id;

        axios.get(this.dataurl).then(response => { 
          if(response.data) {
            this.tenant = response.data.data.tenant;
            this.title = this.tenant.id.charAt(0).toUpperCase() + this.tenant.id.slice(1) + ' Settings';
            this.items[1].text = this.tenant.id.charAt(0).toUpperCase() + this.tenant.id.slice(1) + ' Settings';
          }
        }).catch(error => { 
          if(error.response) {
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else {
        this.tenant = null
        this.title = "Settings"
        this.items = [
          {
            text: "Settings",
            href: "/dashboard/settings",
          },
          {
            text: "Settings List",
            active: true,
          },
        ];
        this.dataurl = this.getdataurl
        this.getLists();
      }
    }
  },
  mounted() {
    this.initiateComponents()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <SettingsListTemplate v-if="listsData && !customer_id" :listsData="listsData" :id="customer_id"></SettingsListTemplate>
    <SettingTemplate v-if="tenant" :tenant="tenant"></SettingTemplate>
  </Layout>
</template>