<script>
import LabelEdit from '../../../plugins/LabelEdit';
import SwitchEdit from '../../../plugins/SwitchEdit';
import axios from "axios";

export default {
  components: { 'label-edit': LabelEdit, SwitchEdit },
  props: ['tenant'],
  data() {
    return {
      dataurl: '/data/tenants/settings',
      updateurl: '/data/tenants/update-setting',
      settingsData: {},
      settingsToUpdate: {},
      refresher: true,
    };
  },
  mounted() {
    this.buildSettings(this.tenant.settings);
  },
  methods: {
    buildSettings(tenantSettings) {
      var slugs = [];
      for (var key in tenantSettings) {
        if (tenantSettings.hasOwnProperty(key)) {
          slugs.push(key);
        }
      }
      // get data from settings table to combine with tenantSettings
      axios.get(this.dataurl, {params: {slugs: slugs}}).then(response => { 
        if(response.data) {
          let data = response.data;
          let settingsData = {};
          data.forEach((element, index) => {
            data[index].value = tenantSettings[data[index].slug];
            settingsData[data[index].slug] = data[index];
          });
          this.settingsData = settingsData;
        }
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    prepareSettingsUpdate(newdata, field) {
      if(this.settingsData[field].value == newdata) return;
      if(this.settingsData[field].datatype === 'boolean') {
        this.settingsToUpdate[field] = this.settingsData[field].value = newdata === 1 ? true : false;
      } else {
        this.settingsToUpdate[field] = this.settingsData[field].value = newdata === '' ? null : newdata;
      }
      this.refresh();
    },
    refresh() {
      this.refresher = false;
      this.refresher = true;
    },
    watchforchanges(){
      return ( Object.keys(this.settingsToUpdate).length);
    },
    saveChanges(){
      let formData = new FormData();

      if(Object.keys(this.settingsToUpdate).length)
        formData.append('settings', JSON.stringify(this.settingsToUpdate));

      formData.append('tenant', this.tenant.id);

      axios.post(this.updateurl, formData).then(response => {
        if(response.data.success && response.data.data){
          this.buildSettings(response.data.data);
          this.settingsToUpdate = {};
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
  },
  
};
</script>

<template>
  <div>
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="form-group" v-for="(setting, key) in settingsData" :key="key">
            <div v-if="setting.datatype === 'string'">
              <label-edit :field="setting.slug" :inputLabel="setting.label" :id="setting.slug" v-bind:text="setting.value" @text-updated-blur="prepareSettingsUpdate" ></label-edit>
            </div>
            <div v-else-if="setting.datatype === 'number'">
              <label-edit :field="setting.slug" :inputLabel="setting.label" :id="setting.slug" type="number" v-bind:text="setting.value" @text-updated-blur="prepareSettingsUpdate" ></label-edit>
            </div>
            <div v-else-if="setting.datatype === 'boolean'" class="d-flex justify-content-between settings-switch">
                <div class="pt-1">{{ setting.label }}</div>
                <div><SwitchEdit :field="setting.slug" :id="setting.slug" :value="(setting.value === true ? 1 : 0)" @checkbox-clicked="prepareSettingsUpdate"></SwitchEdit></div>
            </div>
            <p class="text-muted px-2">{{ setting.description }}</p>
          </div>
        </div>
      </div>
      <!-- end card -->

    </div>
      
    <div class="container setting-saver" v-show="watchforchanges()" v-if="refresher">
      <a href="#" @click.prevent="saveChanges" class="btn btn-primary btn-xl">Save Changes</a>&nbsp;&nbsp;
    </div>
  </div>
</template>