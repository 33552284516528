<script>
import axios from 'axios';
import {_} from 'vue-underscore';

/**
 * New Location Modal component
 */
export default {
  props: ['parentdata'],
  data() {
    return {
      assignTenantUserUrl: "/data/superadmin/assign-tenant-user",
      search: "",
      users: [],
	  adding: false,
	  showModal: false
    };
  },
  methods: {
	assignUserToTenant(user = false){
		if(!this.parentdata || !user)
			return;

		let formData = new FormData();
		formData.append('tenant', this.parentdata.id);
		formData.append('user', user.id);
		formData.append('type', 'add');

		axios.post(this.assignTenantUserUrl, formData).then(response => {
			if(response.data && response.data.success) {
				if(response.data.user) {
					this.$emit('reload-tenant-users', response.data.user);
				}
			}

			/* handleResponse is a global function inside /js/mixins/global.mixins.js
			*/
			this.handleResponse(response);
		}).catch(error => {

			this.adding = false;

			if(error.response){
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	},
	clearForm() {
      this.search = "";
      this.users = "";
	},
	searchUser(){
		if(!this.parentdata)
			return;

		let formData = new FormData(document.getElementById("add-user-to-tenant"));
        formData.append('omit', this.userids);

		this.adding = true;
		axios.post("/data/superadmin/find-user", formData).then(response=> {
			if(response.data && response.data.users)
				this.users = response.data.users;
            this.adding = false;
		}).catch(error => {
			if(error.response) {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
            this.adding = false;
		});
	}
  },
  mounted() {
	//   console.log(this.parentdata);
  },
  computed: {
    userids() {
      return (this.parentdata && this.parentdata.users) ? _.pluck(this.parentdata.users, 'id') : [];
    }
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.add-user-modal variant="outline-success" class="mb-3 d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add User</b-button>

		<b-modal id="add-user-modal" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
			<form action="/data/superadmin/assign-tenant-user" id="add-user-to-tenant" method="POST" @submit.prevent="searchUser">
					<span class="invalid-feedback" role="alert">
					<strong>message</strong>
					</span>

					<label for="searchName">Search users by name or email </label>
					<div class="form-group">
					<input id="searchName" type="text" name="search" class="form-control" v-model="search" required>
					</div>

					<div class="form-group new-tenant-name text-end">
						<b-button variant="success" type="submit">
							<span class="d-none d-sm-block">
								<span v-html="adding ? 'Searching' : 'Search'">Search</span>
								<i v-if="adding" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
							</span>
						</b-button>
					</div>
			</form>
            <form v-if="users && users.length > 0">
                <br/>
                <table class="table table-nowrap table-hover mb-0 align-middle">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users" v-if="!userids.includes(user.id)">
                        <td>
                            {{user.name}}
                        </td>
                        <td>
                            {{user.email}}
                        </td>
                        <td>
							<b-button variant="outline-success" class="btn-sm d-flex align-items-baseline" @click.prevent="assignUserToTenant(user)">
								<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add <span class="visually-hidden"> user {{user.name}}</span>
							</b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
		</b-modal>
	</div>
</template>