<script>
import i18n from "../i18n";
import simplebar from "simplebar-vue";
import axios from 'axios';

/**
 * Nav-bar Component
 */
export default {
  props: ['user'],
  data() {
    return {
      languages: [
        {
          flag: "/images/flags/us.jpg",
          language: "en",
          title: "English",
        }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null
    };
  },
  components: { simplebar },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  computed: {
    storeuser() {
      return this.$store.state.user;
    },
    storecustomers() {
      return this.$store.state.customers;
    },
    adminSelectedCustomer() {
      return this.$store.state.adminSelectedCustomer;
    },
    storecustomer() {
      return this.$store.state.selectedCustomer;
    }
  },
  methods: {
    backToAdmin(){
      this.$store.commit('resetAdminSelectedCustomer');
      // location.reload()
    },
    logout() {
      axios.post("/logout").then(response => {
        if(response.status == 200)
          location.reload();

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response.data);
      }).catch(error => {
        console.log('logged out error', error);
      });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    selectCustomer(index) {
      this.$store.commit('setSelectedCustomer', index);
      if (this.storeuser.is_admin) {
        this.$store.commit('setAdminSelectedCustomer', index);
        location.reload()
      } else {
        location = '/#/client/locations/' + this.storecustomer;
      }      
    },
    getSelectedCustomerName() {
      if (!this.storecustomers.length) {
        return null;
      }
      if (!this.storecustomer.length) {
        this.$store.commit('setSelectedCustomer', 0);
      }
      
      return this.storecustomers.find(e => e.id === this.storecustomer).name;
    }
  },
};
</script>

<template>
  <header id="page-topbar" class="bg-white">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logo-dark.png" alt height="17" />
            </span>
          </a>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/images/logo-icon-light.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logo-light.png" alt height="19" />
            </span>
          </a>
        </div>

      </div>

      <div class="d-flex flex-grow-1">
        <div v-if="storecustomers.length > 1">
          <div class="dropdown-menu-customers">
            <b-dropdown variant="white" toggle-class="header-item">
              <template v-slot:button-content>
                {{ getSelectedCustomerName() }}
                <svg class="caret" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m0 0 4 4 4-4H0Z" fill="#000"/></svg>
              </template>

              <div v-for="customer, index in storecustomers" :key="customer.$index">
                <b-dropdown-item class="d-block" href="javascript: void(0);" @click="selectCustomer(index)">
                  {{ customer.name }}
                </b-dropdown-item>
              </div>

            </b-dropdown>
          </div>
        </div>
        <div class="d-flex align-items-center" v-else>
          <div class="single-customer-display">
            {{ getSelectedCustomerName() }}
          </div>
        </div>
        <div class="d-flex align-items-center">
          <!-- customer logo image can be displayed here once we have it -->
          <!-- <img src="/images/clients/1.png" alt="" class="img-fluid img-logo"> -->
          <a href="#" class="back-to-admin" @click.prevent="backToAdmin()" v-if="adminSelectedCustomer !== '0' && adminSelectedCustomer && storeuser && storeuser.is_admin">Back to Admin</a>
        </div>

        <b-dropdown right variant="white" toggle-class="header-item" class="ms-auto" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="/images/logo-icon-light.png?1.jpg"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1" v-if="storeuser && storeuser.name" v-html="storeuser.name"></span>
            <svg class="caret" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m0 0 4 4 4-4H0Z" fill="#000"/></svg>
          </template>
        
          <b-dropdown-item href="javascript: void(0);">
            API Keys
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            Settings
          </b-dropdown-item>
          <a href="/logout" class="dropdown-item bg-success text-white text-center text-md" @click.prevent="logout">
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>

        <!--div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div-->
      </div>
    </div>
  </header>
</template>
