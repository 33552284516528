<script>
import NewModal from './new-modal';
import Swal from "sweetalert2";
import axios from 'axios';

export default {
  components: { NewModal },
  props: ['listsData'],
  data(){
    return {
      filter: '',
      removeLocationUrl: "/data/superadmin/delete-tenant-location"
    };
  },
  methods: {
    loadMore(){
      this.$emit('load-more');
    },
    loadTenantLocations(locationsEmitted){
      this.listsData.locations.data = locationsEmitted;
    },
    deleteList(list){
      console.log(list);
    },
    viewLoc(id1, id2, event){
      if(event.target.nodeName !== 'BUTTON' && event.target.nodeName !== 'A') {
        let locURL = '/#/dashboard/locations/' + id1 + '/' + id2;
        window.location = locURL;
      }
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, 'ig');
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
    },
    filterLocations(){
      clearTimeout(this.timeout);
      
      var self = this;
      this.timeout = setTimeout(function () {
          // enter this block of code after 1 second
          // handle stuff, call search API etc.
          console.log('searching:', self.filter);
          self.filter = self.filter;
      }, 500);
    },
    deleteLocation(id1, id2, name, index){
      Swal.fire({
        title: "Are you sure you want to delete " + name + "?",
        buttonsStyling: false,
        confirmButtonText: "Delete Location",
        customClass: {
          confirmButton: 'btn btn-danger',
          title: ''
        },
        focusConfirm: false,
        showCancelButton: false,
        showCloseButton: true,
        closeButtonHtml: '<svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"/></svg>'
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(this.removeLocationUrl + '/' + id1 + '/' + id2).then(response => {
            if(response.data && response.data.success && response.data.location) {
              this.listsData.locations.data.splice(index, 1);
              Swal.fire("Location Deleted", name + " has been deleted.", "success");
            }
          }).catch(error => {
            if(error.response){
              /* handleErrors is a global function inside /js/mixins/global.mixins.js
              */
              this.handleErrors(error.response);
            }
          });
        }
      });
    },
    viewTenantLocs(id, e){
      if(e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'A') {
        let tenantURL = '/#/dashboard/locations/' + id;
        window.location = tenantURL;
      }
    }
  },
  computed: {
    locations() {
      if(this.listsData && this.listsData.locations && this.listsData.locations.data){
        return this.listsData.locations.data;
      } else {
        return [];
      }
    },
    filteredRows() {
      return this.locations.filter(row => {
        const name = row.name.toString().toLowerCase();
        const address = row.address.toLowerCase();
        const city = row.city.toLowerCase();
        const state = row.state.toLowerCase();
        const zipcode = row.zipcode.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return address.includes(searchTerm) || city.includes(searchTerm) || name.includes(searchTerm) || state.includes(searchTerm) || zipcode.includes(searchTerm);
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="row" v-if="listsData.tenants && listsData.tenants.length > 1">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 100px">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Manage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="listData in listsData.tenants" :key="listData.id" @click="viewTenantLocs(listData.id, $event)">
                  <td>
                    {{listData.id}}
                  </td>
                  <td>
                    {{listData.name}}
                  </td>
                  <td>
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <span class="visually-hidden">Manage {{listsData.name}}</span>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                        </template>
                        <li><a :href="'/dashboard/customers/' + listData.id" class="btn btn-label btn-link"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</a></li>
                        <li><button type="button" class="btn btn-label btn-link"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                        <li><a :href="'//' + listData.domain_full" target="_blank" class="btn btn-label btn-link"><span class="label-icon label-primary" aria-hidden="true"><svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C6.35 2 5 3.35 5 5s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3Zm0 1c1.11 0 2 .89 2 2 0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2ZM.062 4.732c-.09.166-.048.385.032.53C1.632 8.06 4.584 10 8 10c3.43 0 6.404-1.952 7.938-4.77a.601.601 0 0 0 0-.5C14.403 1.913 11.428 0 8 0A8.99 8.99 0 0 0 .062 4.732ZM8 1c2.96 0 5.51 1.6 6.906 3.982C13.51 7.364 10.956 9 8 9 5.043 9 2.49 7.364 1.094 4.982 2.49 2.602 5.04 1 8 1Z" fill="currentColor"/></svg></span>View Site</a></li>
                      </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="listsData.locations && listsData.locations.data">
      <newModal :parentdata="listsData.tenants[0].id" v-on:reload-tenant-locations="loadTenantLocations"/>

      <div class="row mt-3">
        <div class="col-md-8 col-lg-6">
          <div id="location-table_filter" class="dataTables_filter text-md-end mb-3">
            <label for="locationSearchField" class="d-inline-flex align-items-center visually-hidden">Search:</label>
            <b-form-input
                id="locationSearchField"
                type="search"
                v-model="filter"
                placeholder="Search..."
                class="form-control rounded-pill"
            ></b-form-input>
          </div>
        </div>
        <div class="col-md-4 col-lg-6 text-end">
          <button type="button" class="btn btn-label btn-link ms-auto"><span class="label-icon" aria-hidden="true"><svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_43_569)"><path d="m4.359 5.956-.718-.697L8.5.254l4.859 5.005-.718.696L9 2.205v10.767H8V2.205L4.359 5.956ZM16 9.03v6.47a.5.5 0 0 1-.5.5h-14a.5.5 0 0 1-.5-.5V9.025H0V15.5c0 .827.673 1.5 1.5 1.5h14c.827 0 1.5-.673 1.5-1.5V9.03h-1Z" fill="#3A4C55"/></g><defs><clipPath id="clip0_43_569"><path fill="#3A4C55" d="M0 0h17v17H0z"/></clipPath></defs></svg></span>Export</button>
        </div>
      </div>

      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table
              class="table table-hover project-list-table table-nowrap table-centered table-borderless"
            >
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Active</th>
                  <th scope="col">Last Edited</th>
                  <th scope="col">Manage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="listsData.locations && listsData.locations.data" v-for="listData, index in filteredRows" :key="listData.id" @click="viewLoc(listsData.tenants[0].id, listData.id, $event)">
                  <td v-html="highlightMatches(listData.name)">
                  </td>
                  <td>
                    <span v-html="highlightMatches(listData.address)"></span><br>
                    <span v-html="highlightMatches(listData.city)"></span>, <span v-html="highlightMatches(listData.state)"></span> <span v-html="highlightMatches(listData.zipcode)"></span>                  </td>
                  <td>
                    <span class="visually-hidden">{{ listData.is_active ? 'Active' : 'Inactive' }}</span>
                    <div class="form-check form-switch form-switch-md" dir="ltr" v-if="listData.is_active === 1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'SwitchCheckSizelg' + listData.id"
                        checked
                      />
                    </div>

                    <div class="form-check form-switch form-switch-md" dir="ltr" v-if="listData.is_active === 0">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'SwitchCheckSizelg' + listData.id"
                      />
                    </div>
                  </td>
                  <td>{{ listData.updated_at | mmddyy }}</td>
                  <td>
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <span class="visually-hidden">Manage {{listData.name}}</span>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                        </template>
                        <li><a :href="'/dashboard/locations/' + listsData.tenants[0].id + '/' + listData.id" class="btn btn-label btn-link"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</a></li>
                        <li><a href="#" target="_blank" class="btn btn-label btn-link"><span class="label-icon label-primary" aria-hidden="true"><svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C6.35 2 5 3.35 5 5s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3Zm0 1c1.11 0 2 .89 2 2 0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2ZM.062 4.732c-.09.166-.048.385.032.53C1.632 8.06 4.584 10 8 10c3.43 0 6.404-1.952 7.938-4.77a.601.601 0 0 0 0-.5C14.403 1.913 11.428 0 8 0A8.99 8.99 0 0 0 .062 4.732ZM8 1c2.96 0 5.51 1.6 6.906 3.982C13.51 7.364 10.956 9 8 9 5.043 9 2.49 7.364 1.094 4.982 2.49 2.602 5.04 1 8 1Z" fill="currentColor"/></svg></span>Preview</a></li>
                        <li><button type="button" class="btn btn-label btn-link" @click="deleteLocation(listsData.tenants[0].id, listData.id, listData.name, index)"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                      </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="listsData.locations && listsData.locations.next_page_url && false">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success" @click.prevent="loadMore">
            <i class="bx bx-loader bx-spin font-size-18 align-middle mr-2" v-if="listsData.loading"></i>Load more
          </a>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>