<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import UsersListTemplate from './usersListTemplate';
import UserTemplate from './userDetailsTemplate';

import axios from "axios";

/**
 * Projects-list component
 */
export default {
  components: {
    Layout,
    PageHeader,
    'usersListTemplate': UsersListTemplate,
    'userTemplate': UserTemplate
  },
  computed: {
    user_id() {
      return this.$route.params.user_id == undefined ? false : this.$route.params.user_id;
    },
  },
  watch: { 
    user_id: function(newVal, oldVal) {
      this.initiateComponents()
    }
  },
  data() {
    return {
      getdataurl: '/data/superadmin/users',
      dataurl: '/data/superadmin/users',
      listData: [],
      listsData: [],
      title: "Users List",
      cssclass: "users-list",
      items: [
        {
          text: "Users",
          href: "/dashboard/users",
        },
        {
          text: "Users List",
          active: true,
        },
      ],
    };
  },
  methods: {
    loadMore() {
      this.dataurl = this.listsData.next_page_url;
      this.listsData.loading = true;
      this.getLists(true);
    },
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          if(append) {
            this.listsData.data.push.apply(this.listsData.data, response.data.data);
            this.listsData.next_page_url = response.data.next_page_url;
            this.listsData.loading = false;
          } else {
            this.listsData = response.data;
          }
        }
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    initiateComponents(){
      if(this.user_id) { // single user details
        this.title = 'User Details';
        this.cssclass = 'user-details ps-lg-5';
        this.items[1].text = 'User Details';

        axios.get(this.getdataurl + '/' + this.user_id).then(response => { 
          if(response.data) {
            this.listData = response.data;
          }
        }).catch(error => { 
          if(error.response) {
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else {
        this.getLists();
      }
    }
  },
  mounted() {
    this.initiateComponents()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :class="cssclass" :title="title" :items="items" />
    <usersListTemplate v-if="listsData && listsData.data && !user_id" :listsData="listsData" v-on:load-more="loadMore"></usersListTemplate>
    <userTemplate v-if="listData && listData.data && user_id" :listData="listData"></userTemplate>
  </Layout>
</template>