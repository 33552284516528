<script>
import axios from 'axios';
import CheckboxEdit from '../../../plugins/CheckboxEdit';
import {_} from 'vue-underscore';

/**
 * New Location Modal component
 */
export default {
  components: { CheckboxEdit },
  props: ['tenantid', 'tenantlocations'],
  data() {
    return {
      refresh: true,
      showModal: false,
      check_or_uncheck_all: 0,
      exportUrl: "/data/superadmin/export",
      checkedlocations: [],
      locations: []
    };
  },
  methods: {
    checkOrUncheckAll(val){
      this.check_or_uncheck_all = val;
      if(val) this.checkedlocations = _.pluck(this.tenantlocations, 'id');
      else this.checkedlocations = [];
      this.downloadLinkCreator();
      let checkboxes = this.$refs.shForm.getElementsByClassName('form-check-input');
      for (let i = 1; i < checkboxes.length; i++) {   // checkboxes[0] is the "all" checkbox so skip it
        checkboxes[i].checked = this.check_or_uncheck_all;
      }

    },
    downloadLinkCreator(){
      if(this.checkedlocations.length) {

        let searchParams = new URLSearchParams({tenant: this.tenantid, locations: this.checkedlocations});

        this.exportUrl += '?' + searchParams.toString();
      }
    },
    checkedLocation(val, id){
      if(val) {
        this.checkedlocations.push(id);
      } else {
        this.checkedlocations = _.without(this.checkedlocations, id);
      }
      this.downloadLinkCreator();
    },
    isChecked(id){
      return _.contains(this.checkedlocations, id) ? 1 : 0;
    },
    getFields(){
      if(this.tenantlocations.length)
        this.locations = this.tenantlocations;
      return;
    }
  },
	watch: {
		checkedlocations: function(val){
      if(val.length == this.tenantlocations.length) {
        this.check_or_uncheck_all = 1;
      } else {
        this.check_or_uncheck_all = 0;
      }
		}
	},
  mounted() {
  }
};
</script>

<template>
	<div>
        <a href="#" v-b-modal.tenant-export-modal @click.prevent="getFields" class="btn btn-primary btn-xl btn-block">Export</a>
        <b-modal size="lg" id="tenant-export-modal" hide-footer v-model="showModal">
          <template #modal-header="{ close }">
            <button type="button" class="close" @click="close()">
              <span class="visually-hidden">Close Dialog</span>
              <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
            </button>
          </template>
          <h2 class="text-uppercase card-title">Select locations to export</h2>
            <b-form @submit.prevent="downloadLinkCreator" ref="shForm">
            <div class="d-flex align-items-center">
              <CheckboxEdit id="toggleAllLocationsExport" field="check_or_uncheck_all" :value="check_or_uncheck_all" v-on:checkbox-clicked="checkOrUncheckAll" inputLabel="Select All"></CheckboxEdit>
              
              <div v-if="checkedlocations.length" class="ms-auto">
                <a :href="exportUrl" target="_BLANK" class="btn btn-success btn-xl">Export</a>
              </div>
            </div>

              <hr class="mt-2">
              <ul class="list-unstyled list-update-locations mt-2">
                <li v-for="location in tenantlocations">
                  <CheckboxEdit :field="location.id" :id="location.id" :inputLabel="location.name" :value="isChecked(location.id)" v-on:checkbox-clicked="checkedLocation" ></CheckboxEdit>
                </li>
              </ul>
            </b-form>
        </b-modal>
	</div>
</template>