<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default{
    components: { DatePicker },
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			label: '', // v-bind data model for input text
			empty: '00:00', // empty place holder .. replace with your own localization for default
            format: 'HH:mm',
			dvalue: null,
		}
	},
	props: ['text','placeholder', 'field', 'type', 'dateformat', 'defaultvalue'], // parent should provide :text or :placeholder
	methods: {
		initText: function(){
			this.format = ( this.dateformat == '' || this.dateformat === undefined ) ? 'HH:mm' : this.dateformat;
            this.empty = ( this.type == 'time' ) ? '00:00' : '00/00/00 00:00';
			this.label = ( this.text == '' || this.text == undefined ) ? this.vlabel : this.text;
			this.dvalue = ( this.defaultvalue != '' && this.defaultvalue !== undefined ) ? this.defaultvalue : this.dvalue;
		},
		// when the div label got clicked and trigger the text box
		onLabelClick: function(){
			this.edit = true;
			this.label = this.text;
		},
		// trigger when textbox got lost focus
		updateTextBlur: function(){
			// update the edit mode to false .. display div label text
			this.edit = false;
			// emit text updated callback
			this.$emit('text-updated-blur',this.label, this.field);
		},
		updateTextEnter: function(){
			this.edit = false;
			this.$emit('text-updated-enter',this.label, this.field);
		}
	},
	computed: {
		vlabel: function(){
			// after text has been updated
			// return text value or place holder value depends on value of the text
			if(this.text==undefined||this.text==''){
				return this.vplaceholder
			}else{
				return this.label
			}
		}
	},
	mounted: function(){
		// initiate the label view
		this.initText();
	},
	watch: {
		text: function(value){
			if(value==''||value==undefined){
				this.label = this.vplaceholder
			}
		}
	}
}
</script>
<template>
	<div>
        <date-picker :input-attr="{id: $attrs['id']+'_datepicker'}" :type="type" v-model="label" ref="labeledit" :placeholder="dvalue" v-on:change="updateTextBlur" @keyup.enter="updateTextEnter"
        :minute-step="30"
        :format="format"
        value-type="format"
        ></date-picker>
	</div>
</template>