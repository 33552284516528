<template>
    <div class="form-check form-switch form-switch-lg">
        <input class="form-check-input" :id="id" type="checkbox" v-model="val" :checked="value === 1 ? 'checked' : 'false'" value="1" unchecked-value="0" @change="updateCheckbox"/>
    </div>
</template>
<script>
export default{
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			val: '', // v-bind data model for input text
			empty: 'Check', // empty place holder .. replace with your own localization for default
		}
	},
	props: ['id','value','placeholder', 'field'], // parent should provide :text or :placeholder
	methods: {
		initValue: function(){
			this.val = (this.value=='' || this.value==undefined || this.value==0) ? 0 : 1;
		},
		updateCheckbox: function(){
            let tempVal = this.val ? 1 : 0;
			this.$emit('checkbox-clicked', tempVal, this.field);
		}
	},
	computed: {
		vplaceholder: function(){
			return (this.val == 1) ? 'Yes' : 'No';
		}
	},
	mounted: function(){
		this.initValue();
	}
}
</script>