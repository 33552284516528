export const clientMenuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 6,
        label: "menuitems.dashboard.list.locations",
        icon: "icon-location",
        link: "/client/locations",
    },
    {
        id: 8,
        label: "menuitems.dashboard.list.customfieldscards",
        icon: "bx-align-justify",
        link: "/client/cards",
    },
    {
        id: 11,
        label: "menuitems.dashboard.list.settings",
        icon: "icon-settings",
        link: "/client/settings",
    }
];

export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboard.list.locations",
        icon: "icon-location",
        link: "/dashboard/locations",
    },
    {
        id: 3,
        label: "menuitems.dashboard.list.customfields",
        icon: "icon-fields",
        subItems: [
            {
                id: 70,
                label: "menuitems.dashboard.list.customfields",
                icon: "icon-fields",
                link: "/dashboard/customfields",
            },
            {
                id: 71,
                label: "menuitems.dashboard.list.customfieldspresets",
                icon: "icon-fields",
                link: "/dashboard/customfields-presets"
            }
        ]
    },
    {
        id: 4,
        label: "menuitems.dashboard.list.customfieldscards",
        icon: "icon-card",
        link: "/dashboard/customfields-cards",
    },
    {
        id: 5,
        label: "menuitems.dashboard.list.users",
        icon: "icon-user",
        link: "/dashboard/users",
    },
    {
        id: 6,
        label: "menuitems.dashboard.list.tenants",
        icon: "icon-user",
        link: "/dashboard/customers",
    },
    {
        id: 7,
        label: "menuitems.dashboard.list.settings",
        icon: "icon-settings",
        link: "/dashboard/settings",
    },
    {
        id: 8,
        label: "menuitems.dashboard.list.data",
        icon: "icon-data",
        link: "/",
    }
];
