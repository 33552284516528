<script>
import Header from "./header";

/**
 * Register component
 */
export default {
  components: { Header },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isRegisterError: false,
      registerSuccess: false,
      autofilled: false,
      activeInputClass: 'form-group label-placeholder is-focused',
      defaultInputClass: 'form-group label-placeholder'
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    regError: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  methods: {
    checkAnimation(e) {
      //hack to detect autofill and adjust field state
      //https://rojas.io/how-to-detect-chrome-autofill-with-vue-js/
      if(e.animationName == "onAutoFillStart") {
        e.target.parentElement.classList.add('is-focused');
      }
      else if(e.animationName == "onAutoFillCancel") {
        e.target.parentElement.classList.remove('is-focused');
      }
    },
    onFocused(e) {
      e.target.parentElement.classList.add('is-focused');
    },
    onBlurred(e) {
      if(e.target.value === "") {
        e.target.parentElement.classList.remove('is-focused');
      }
    }
  },
  mounted() {
    this.isRegisterError = !!this.regError;
    document.body.classList.add('bg-dark');
  },
};
</script>

<template>
<div class="container-fluid">
  <Header />
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-none">
          <div class="card-body bg-none pt-0">

            <b-alert
              v-model="registerSuccess"
              class="mt-3"
              variant="success"
              dismissible
            >Registration successfull.</b-alert>

            <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{regError}}</b-alert>

            <b-form class="p-2" :action="submitUrl" method="POST">
              <slot />
              <div id="email-group" :class="[name ? activeInputClass : defaultInputClass]">
                <label label-for="name">Name</label>
                <b-form-input
                  class="bg-none"
                  id="name"
                  v-model="name"
                  name="name"
                  type="text"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>

              <div id="fullname-group" :class="[email ? activeInputClass : defaultInputClass]">
                <label label-for="email">Email</label>
                <b-form-input
                  class="bg-none"
                  id="email"
                  name="email"
                  v-model="email"
                  type="email"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>

              <div id="password-group" :class="[password ? activeInputClass : defaultInputClass]">
                <label label-for="password">Password</label>
                <b-form-input
                  class="bg-none"
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>
              <div :class="[password_confirmation ? activeInputClass : defaultInputClass]">
                <label label-for="password-confirm">Confirm Password</label>
                <b-form-input
                  class="bg-none"
                  id="password-confirm"
                  v-model="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>

              <div class="mt-4 d-grid">
                <b-button type="submit" variant="success" class="btn-block">Register</b-button>
              </div>
              <div class="mt-4 text-center text-white">
                <p class="mb-0">
                  By registering you agree to the Reachist
                  <a
                    href="javascript: void(0);"
                    class="text-primary"
                  >Terms of Use</a>
                </p>
              </div>
            </b-form>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center text-white">
        <p>
          Already have an account ?
          <a href="/login">Login</a>
        </p>
        <p class="small">
          © {{ new Date().getFullYear() }} Reachist. Crafted with love by Blueshoon
        </p>
      </div>
    </div>
    <!-- end col -->
  </div>
</div>
</template>

<style lang="scss" module></style>
