<script>
import Header from "./header";

/**
 * Login component
 */
export default {
  components: { Header },
  data() {
    return {
      email: "",
      password: "",
      tryingToLogIn: false,
      isAuthError: false,
      activeInputClass: 'form-group label-placeholder is-focused',
      defaultInputClass: 'form-group label-placeholder'
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    authError: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  methods: {
    login(){
      console.log('login');
    },
    checkAnimation(e) {
      //hack to detect autofill and adjust field state
      //https://rojas.io/how-to-detect-chrome-autofill-with-vue-js/
      if(e.animationName == "onAutoFillStart") {
        e.target.parentElement.classList.add('is-focused');
      }
      else if(e.animationName == "onAutoFillCancel") {
        e.target.parentElement.classList.remove('is-focused');
      }
    },
    onFocused(e) {
      e.target.parentElement.classList.add('is-focused');
    },
    onBlurred(e) {
      if(e.target.value === "") {
        e.target.parentElement.classList.remove('is-focused');
      }
    }
  },
  mounted() {
    this.isAuthError = !!this.authError;
    document.body.classList.add('bg-dark');
    //customers will be different for different users and for superadmins so get a fresh list on login
    localStorage.removeItem('customers');
    localStorage.removeItem('selectedCustomer');
  },
};
</script>

<template>
<div class="container-fluid">
  <Header />
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card bg-none">
          <h1 class="text-white text-center h2">Welcome Back!</h1>
          <div class="card-body bg-none pt-0">

            <b-alert
              v-model="isAuthError"
              variant="danger"
              class="mt-3"
              dismissible
              >{{ authError }}</b-alert
            >

            <b-form class="p-2" :action="submitUrl" method="POST">
              <slot />
              <div id="input-group-1" :class="[email ? activeInputClass : defaultInputClass]">
                <label for="input-1">Email</label>
                <b-form-input
                  class="bg-none"
                  id="input-1"
                  v-model="email"
                  name="email"
                  type="email"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>

              <div id="input-group-2" :class="[password ? activeInputClass : defaultInputClass]">
                <label for="input-2">Password</label>
                <b-form-input
                  class="bg-none"
                  id="input-2"
                  v-model="password"
                  name="password"
                  type="password"
                  v-on:focus="onFocused($event)"
                  v-on:blur="onBlurred($event)"
                  v-on:animationstart="checkAnimation"
                ></b-form-input>
              </div>
              <p class="text-end mt-1">
                <a href="/password/reset">
                  Forgot your password?
                </a>
              </p>
              <div class="custom-control checkbox custom-checkbox b-custom-control-lg text-white">
                <input type="checkbox" name="checkbox-1" class="checkbox-input custom-control-input" id="customControlInline" value="accepted" checked>
                <label class="custom-control-label" for="customControlInline">Remember me</label>
              </div>

              <div class="mt-3 d-grid">
                <b-button type="submit" variant="success" class="btn-block">Log In</b-button>
              </div>
              <div class="mt-4 text-center">
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center text-white">
          <p>
            Don't have an account ?
            <a href="/register" class="fw-medium text-primary">Signup now</a>
          </p>
          <p class="small">
            © {{ new Date().getFullYear() }} Reachist. Crafted with
            love by Blueshoon
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</div>
</template>