import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		user: {},
		userset: false,
		searchSettings: [],
		customers: JSON.parse(localStorage.getItem('customers')) ?? [],
		adminSelectedCustomer: localStorage.getItem('adminSelectedCustomer') ?? 0,
		selectedCustomer: localStorage.getItem('selectedCustomer') ?? 0,
	},
	mutations: {
		setUser (state, user) {
			state.user = user;
			state.userset = true
		},
		setSearchSettings (state, settings) {
			// console.log('setting setSearchSettings', settings);
			state.searchSettings = settings;
		},
		setCustomers (state, customers) {
			state.customers = customers;
			localStorage.setItem('customers', JSON.stringify(customers));
		},
		resetAdminSelectedCustomer (state) {
			state.adminSelectedCustomer = 0;
			localStorage.setItem('adminSelectedCustomer', 0);

			state.selectedCustomer = 0;
			localStorage.setItem('selectedCustomer', 0);

			location.href = "/"
		},
		setSelectedCustomer (state, index) {
			state.selectedCustomer = state.customers[index].id;
			localStorage.setItem('selectedCustomer', state.customers[index].id);
		},
		setAdminSelectedCustomer (state, index) {
			state.adminSelectedCustomer = state.customers[index].id;
			localStorage.setItem('adminSelectedCustomer', state.customers[index].id);

			location.href = "/#/client/locations/" + state.customers[index].id
		}
	},
  getters: {
    getUser: state => {
      return state.user;
    },
    getAdminSelectedCustomer: state => {
      return localStorage.getItem('adminSelectedCustomer');
    },
    getSelectedCustomer: state => {
      return localStorage.getItem('selectedCustomer');
    }
  }
});

export default store;