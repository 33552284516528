<script>
import axios from "axios";
import {_} from 'vue-underscore';
import CheckboxEdit from '../../../plugins/CheckboxEdit';

/**
 * New CustomField Modal component
 */
export default {
  components: { CheckboxEdit },
  props: ['field', 'tenants', 'cards'],
  data() {
    return {
      options: false,
      fieldLabel: '',
      fieldTooltip: '',
      fieldSlug: '',
      is_private: false,
      assignedTenants: [],
      assignedCard: [],
      fieldVisibility: 'public',
      showModal: false,
      modalTitle: 'Update Field',
      updateCFUrl: '/data/superadmin/update-customfield'
    };
  },
  computed: {
    allTenantsOptions: function() {
      let tmpTenants = [];

      if(this.tenants && this.tenants.length){
        this.tenants.forEach(function(element){
          tmpTenants.push({value: element.id, text: element.name});
        });
      }

      return tmpTenants;
    },
    allCardsOptions: function() {
      let tmpCards = [];

      if(this.cards && this.cards.length){
        this.cards.forEach(function(element){
          tmpCards.push({value: element.id, text: element.name});
        });
      }

      return tmpCards;
    }
  },
  watch: {
      field: function(newField){
        this.fieldLabel = newField.label;
        this.fieldTooltip = newField.tooltip;
        this.fieldSlug = newField.slug;
        this.options = newField.options;
        this.is_private = newField.visibility == 'private' ? 1 : 0;
        this.assignedTenants = (this.field.tenants && this.field.tenants.length) ? _.pluck(this.field.tenants, 'id') : [];
        this.assignedCard = (this.field.card && this.field.card.length) ? this.field.card[0].id: 0;
      },
      is_private: function(val){
        this.fieldVisibility = val ? 'private' : 'public';
      }
  },
  methods: {
    clearForm(newField) {
      this.$emit('update-success', newField);
    },
    onSubmit(){
      let formData = new FormData(this.$refs.cfForm);

      let visibility = this.is_private ? 'private' : 'public';
      formData.set('visibility', visibility);

      if(this.options === null || this.options === "NULL" || this.options === "")
        formData.set('options', "");
      else
        formData.set('options', this.options);

      if(this.assignedCard)
        formData.set('card', this.assignedCard);

      if(this.assignedTenants && this.assignedTenants.length)
        formData.set('assigned-tenants', this.assignedTenants);

      axios.post(this.updateCFUrl, formData).then(response => {

        if(response.data && response.data.status && response.data.status == 'success') {
          this.clearForm(response.data.data);
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {

        this.adding = false;

        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    onReset() {
      this.label = "";
      this.tooltip = "";
    },
    visibilityChanged(val) {
      this.is_private = val;
    },
    valueUpdated(value) {
      this.fieldSlug = value.replace(/\s+/g, '_').toLowerCase();
    },
    tenantExists(tenant_id){
      return this.tenants.filter(function(obj){
        return obj.id == tenant_id;
      });
    }
  }
};
</script>

<template>
	<div class="">
		<b-modal id="modal-new-cf" hide-footer v-model="showModal" ref="modalEl">
      <template #modal-header="{ close }">
        <button type="button" class="close" @click="close()">
          <span class="visually-hidden">Close Dialog</span>
          <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
        </button>
      </template>
      <h2 class="text-uppercase card-title">{{modalTitle}}</h2>
      <b-form @submit.prevent="onSubmit" @reset="onReset" ref="cfForm">
          <b-form-group label="Label:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="fieldLabel" name="label"></b-form-input>
            <input type="hidden" name="id" :value="field.id"/>
            <input type="hidden" name="type" :value="field.type"/>
          </b-form-group>
          <b-form-group label="Tooltip:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="fieldTooltip" name="tooltip"></b-form-input>
          </b-form-group>
          <b-form-group label="Slug:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="fieldSlug" name="slug"></b-form-input>
          </b-form-group>
          <b-form-group label-cols-sm="5" content-cols-sm="7">
            <CheckboxEdit :value="is_private" v-on:checkbox-clicked="visibilityChanged" id="cf-visibility-input" inputLabel="Private"></CheckboxEdit>
          </b-form-group>

          <b-form-group label="Options:" label-for="cf-slug-input" label-cols-sm="5" content-cols-sm="7" v-if="field.type == 'radio' || options">
            <textarea placeholder="value:Label separated by |" class="" v-model="options"></textarea>
          </b-form-group>

          <b-form-group label="Assigned Tenants:" class="col-md-12 mt-2">
            <b-form-select multiple id="assign-to-tenants" class="form-select" :select-size="tenants.length" v-model="assignedTenants" :options="allTenantsOptions" required></b-form-select>
          </b-form-group>
          <b-form-group label="Assigned to card:" class="col-md-12 mt-2">
            <b-form-select id="assign-to-tenants" class="form-select" :select-size="cards.length" v-model="assignedCard" :options="allCardsOptions" required></b-form-select>
          </b-form-group>
          <div class="mt-5"><b-button type="submit" variant="success">Update</b-button></div>
      </b-form>
		</b-modal>
	</div>
</template>