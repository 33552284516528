<script>
import axios from 'axios';
import Swal from "sweetalert2";

/**
 * New Location Modal component
 */
export default {
  props: ['holiday', 'parentdata', 'islocation'],
  data() {
    return {
      removeHolidayUrl: "/data/superadmin/remove-holiday"
    };
  },
  methods: {
    confirmDelete() {
      Swal.fire({
        title: "Are you sure you want to delete " + this.holiday.holiday_name + "?",
        buttonsStyling: false,
        confirmButtonText: "Delete Holiday",
        customClass: {
          confirmButton: 'btn btn-danger',
          title: ''
        },
        focusConfirm: false,
        showCancelButton: false,
        showCloseButton: true,
        closeButtonHtml: '<svg width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"/></svg>'
      }).then(result => {
        if (result.value) {
          this.remove()
        }
      });
    },
    remove(){
      if(!this.holiday || !this.parentdata) { return; }

      let formData = new FormData();
      formData.append('tenant', this.parentdata.id);
      formData.append('id', this.holiday.id);
      if(this.islocation)
        formData.append('locations[]', this.islocation);

      axios.post(this.removeHolidayUrl, formData).then(response => {
        if(response.data && response.data.success && response.data.holiday) {
          this.$emit('removed-holiday', response.data.holiday);
        }
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    }
  }
};
</script>

<template>
	<div class="d-inline">
    <b-button variant="link" @click.prevent="confirmDelete()" class="text-primary px-0">
      Delete
    </b-button>
	</div>
</template>