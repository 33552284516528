<script>
import axios from "axios";

/**
 * New Setting Modal component
 */
export default {
  data() {
    return {
      showModal: false,
      datatypes: [
				{value: 'string', text: 'String'},
				{value: 'number', text: 'Number'},
				{value: 'boolean', text: 'Boolean'},
			],
      booleanValues: [
        {value: true, text: 'True'},
        {value: false, text: 'False'},
      ],
      settingLabel: '',
      settingDescription: null,
      settingTooltip: null,
      settingSlug: '',
      settingDatatype: 'string',
      settingDefault: null,
      currentDatatype: 'string',
      modalTitle: 'Add New Setting',
      createSettingUrl: '/data/superadmin/create-setting',
    };
  },
	watch: {
    settingLabel: function(newLabel, oldLabel){
      this.settingSlug = this.slugify(newLabel);
    },
		settingSlug: function(newSlug, oldSlug){
      this.settingSlug = this.slugify(newSlug);
		},
	},
  methods: {
    changeDatatype(){
      this.currentDatatype = this.settingDatatype;
      switch(this.settingDatatype) {
        case 'string':
          this.settingDefault = '';
        break;
        case 'number':
          this.settingDefault = 0;
        break;
        case 'boolean':
          this.settingDefault = false;
        break;
      }
    },
    clearForm(newSetting) {
      this.settingLabel = '';
      this.settingDescription = null;
      this.settingTooltip = null;
      this.settingSlug = '';
      this.settingDatatype = 'string';
      this.settingDefault = null;
      this.modalTitle = 'Add New Setting';
      this.showModal = false;

      this.$emit('form-success', newSetting);
    },
    onSubmit(){
      let formData = new FormData(this.$refs.settingForm);

      this.adding = true;

      axios.post(this.createSettingUrl, formData).then(response => {

        if(response.data && response.data.status && response.data.status == 'success') {
          this.clearForm(response.data.data);
        }

        this.adding = false;

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {

        this.adding = false;

        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
  },
};
</script>

<template>
	<div class="">
		<b-modal id="modal-new-setting" hide-footer b-modal="showModal" ref="modalEl">
    	<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
      <h2 class="text-uppercase card-title">{{ modalTitle }}</h2>

      <b-form @submit.prevent="onSubmit" ref="settingForm">
          <b-form-group label="Label" label-for="setting-label-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="settingLabel" name="label"></b-form-input>
            <input type="hidden" name="id" value="0"/>
          </b-form-group>
          <b-form-group label="Description" label-for="setting-description-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="settingDescription" name="description"></b-form-input>
          </b-form-group>
          <b-form-group label="Tooltip" label-for="setting-tooltip-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="settingTooltip" name="tooltip"></b-form-input>
          </b-form-group>
          <b-form-group label="Slug" label-for="setting-slug-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-input v-model="settingSlug" name="slug"></b-form-input>
          </b-form-group>
          <b-form-group label="Datatype" label-for="setting-datatype-input" label-cols-sm="5" content-cols-sm="7">
            <b-form-select class="form-select" v-model="settingDatatype" :options="datatypes" name="datatype" @change="changeDatatype"></b-form-select>
          </b-form-group>
          <b-form-group label="Default" label-for="setting-default-input" label-cols-sm="5" content-cols-sm="7" class="mb-5">
            <div v-if="currentDatatype === 'string'">
              <b-form-input v-model="settingDefault" name="default"></b-form-input>
            </div>
            <div v-else-if="currentDatatype === 'number'">
              <b-form-input type="number" step="any" v-model="settingDefault" name="default"></b-form-input>
            </div>
            <div v-else-if="currentDatatype === 'boolean'">
              <b-form-select v-model="settingDefault" :options="booleanValues" name="default"></b-form-select>
            </div>
          </b-form-group>
          <div class="text-center">
            <b-button type="submit" variant="success">Submit</b-button>
          </div>
      </b-form>
		</b-modal>
	</div>
</template>