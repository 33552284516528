<script>
import NewTenantModal from './new-tenant-modal';

export default {
  components: {
    'newTenantModal': NewTenantModal
  },
  props: ['listsData'],
  methods: {
    loadMore(){
      this.$emit('load-more');
    },
    deleteList(list){
      console.log(list);
    },
    loadNewTenant(newTenant){
      console.log(newTenant);
      this.listsData.data.push(newTenant);
    },
    viewTenant(id, e){
      if(e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'A') {
        let tenantURL = '/#/dashboard/customers/' + id;
        window.location = tenantURL;
      }
    }
  }
};
</script>

<template>
  <div>
    <newTenantModal v-on:reload-tenants="loadNewTenant" />

    <div class="row" v-if="listsData && listsData.data">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 100px">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Manage</th>
                </tr>
              </thead>
              <tbody v-if="listsData.data && listsData.data.length > 0">
                <tr v-for="list in listsData.data" @click="viewTenant(list.id, $event)">
                  <td>
                    {{list.id}}
                  </td>
                  <td>
                        {{list.name}}
                  </td>
                  <td>
                    <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <span class="visually-hidden">Manage {{list.name}}</span>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                        </template>
                        <li><button type="button" class="btn btn-label btn-link"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</button></li>
                        <li><button type="button" class="btn btn-label btn-link"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                        <li><a :href="'//' + list.domain_full" target="_blank" class="btn btn-label btn-link"><span class="label-icon label-primary" aria-hidden="true"><svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C6.35 2 5 3.35 5 5s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3Zm0 1c1.11 0 2 .89 2 2 0 1.11-.89 2-2 2-1.11 0-2-.89-2-2 0-1.11.89-2 2-2ZM.062 4.732c-.09.166-.048.385.032.53C1.632 8.06 4.584 10 8 10c3.43 0 6.404-1.952 7.938-4.77a.601.601 0 0 0 0-.5C14.403 1.913 11.428 0 8 0A8.99 8.99 0 0 0 .062 4.732ZM8 1c2.96 0 5.51 1.6 6.906 3.982C13.51 7.364 10.956 9 8 9 5.043 9 2.49 7.364 1.094 4.982 2.49 2.602 5.04 1 8 1Z" fill="currentColor"/></svg></span>View Site</a></li>
                      </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="listsData.next_page_url">
      <div class="col-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-success" @click.prevent="loadMore">
            <i class="bx bx-loader bx-spin font-size-18 align-middle mr-2" v-if="listsData.loading"></i>Load more
          </a>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>