<script>
export default {
  props: {
    component: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false,
      default: () => ""
    },
    secondaryid: {
      type: String,
      required: false,
      default: () => ""
    },
    user: {
      type: String,
      required: false,
      default: () => {}
    }
  },
  data(){
    return {
      userObject: {}
    };
  },
  mounted(){
    this.userObject = JSON.parse(this.user);

    if(this.userObject) {
      this.$store.commit('setUser', this.userObject);
    }
  },
  computed: {
    component_name() {
      return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
    }
  }
};
</script>
<template>
  <div>
  <component :is="component_name" :id="id" :secondaryid="secondaryid" :user="userObject" v-if="userObject"/>
  </div>
</template>