<script>
import axios from 'axios';

/**
 * New Location Modal component
 */
export default {
  props: ['parentdata'],
  data() {
    return {
      createLocationUrl: "/data/superadmin/create-tenant",
      name: "",
      subdomain: "",
	  adding: false,
	  showModal: false
    };
  },
  methods: {
	createNewLocation(){
		let formData = new FormData(document.getElementById("create-new-location"));

		this.adding = true;

		axios.post(this.createLocationUrl, formData).then(response => {
			if(response.data && response.data.success) {
				this.clearForm();
				this.showModal = false;
				if(response.data.tenant) {
					let customers = this.$store.state.customers;
					let customer = {
						id: response.data.tenant.id,
						name: response.data.tenant.name
					}
					customers.push(customer);
					this.$store.commit('setCustomers', customers);

					this.$emit('reload-tenants', response.data.tenant);
				}
			}

			this.adding = false;

			/* handleResponse is a global function inside /js/mixins/global.mixins.js
			*/
			this.handleResponse(response);
		}).catch(error => {

			this.adding = false;

			if(error.response){
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
			}
		});
	},
	clearForm() {
      this.name = "";
      this.subdomain = "";
	},
	onFocused(e) {
		e.target.parentElement.classList.add('is-focused');
    },
	onBlurred(e) {
		if(e.target.value === "") {
			e.target.parentElement.classList.remove('is-focused');
		}
    }
  },
  mounted() {
	  //mounted
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.modal-new-user variant="outline-success" class="mb-3 d-flex align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add Customer</b-button>

		<b-modal id="modal-new-user" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
      		<h2 class="text-uppercase card-title">Add New Customer</h2>
			<form action="/data/superadmin/create-tenant" id="create-new-location" method="POST" @submit.prevent="createNewLocation">
				<span class="invalid-feedback" role="alert">
				<strong>message</strong>
				</span>

				<div class="form-group label-placeholder">
					<label for="tenant_name">Customer Name</label>
					<input type="text" id="tenant_name" name="tenant_name" class="form-control" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" v-model="name" required>
					<input type="hidden" name="new_tenant" value="1"/>
				</div>

				<div class="form-group label-placeholder">
					<label for="tenant_subdomain">Subdomain</label>
					<input type="text" id="tenant_subdomain" name="tenant_subdomain" class="form-control" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" v-model="subdomain" required>
				</div>

				<div class="form-group new-tenant-name text-center mt-5">
					<b-button variant="success" type="submit">
                        <span class="d-none d-sm-block">
                            <span v-html="adding ? 'Adding' : 'Add Customer'">Add</span>
                            <i v-if="adding" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </span>
					</b-button>
				</div>
			</form>
		</b-modal>
	</div>
</template>