import store from "./../store"

export function authCheckGuard(to, from, next) {
  // console.log("authCheckGuard")
  // console.log(store.getters.getUser);
  if(store.getters.getUser) {
    next();
  } else {
    next('/setup');
  }
}

export function guestCheckGuard(to, from, next) {
  // console.log("guestCheckGuard")
  // console.log(store.getters.getUser);
  if(store.getters.getUser) {
    next('/');
  } else {
    next();
  }
}

export function adminOrClientGuard(to, from, next) {
  if(store.getters.getUser && store.getters.getUser.is_admin) {
    next("/dashboard/locations");
  } else {
    next("/client/locations");
  }
}