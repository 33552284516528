<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import axios from "axios";
import draggable from 'vuedraggable';

/**
 * Custom Fields component
 */
export default {
  components: { Layout, PageHeader, draggable },
  props: ['is_tenant'],
  data() {
    return {
      dataurl: '/data/superadmin/customfields-cards',
      createurl: '/data/superadmin/create-customfields-cards',
      updateurl: '/data/superadmin/update-customfields-cards',
      addtocardurl: '/data/superadmin/add-customfields-to-cards',
      resetdefaultcardsurl: '/data/superadmin/reset-default-cards',
      cards: [],
      areYouSure: false,
      checkAreYouSure: false,
      cardSize: "",
      newCardName: "",
      cardSizeEdit: "",
      cardNameEdit: "",
      showModal: false,
      cardSizes: [
				{value: '', text: 'Select Size'},
        {value: '1/3', text: '1/3'},
        {value: '1/2', text: '1/2'},
        {value: '2/3', text: '2/3'},
        {value: 'full', text: 'full'}
      ],
      cardToEdit: [],
      checkedCards: [],
      listData: [],
      listsData: [],

      selectedCards: "",

      title: "Location Cards",

      items: [
        {
          text: "Cards",
          href: "/",
        }
      ],
      resetDefaultConfirmation: false,
    };
  },
  computed: {
    cardSettingsData() {
      JSON.parse(localStorage.getItem('cards-settings'));
    },
  },
	watch: {
		selectedCards: function(val){
      if(val) {
        let data = this.customFieldFilter(this.cards, val);

        if(data[0] && data[0].cards) {
          this.checkedCards = data[0].cards;
          this.newCardName = data[0].name;
        }
      }
		}
	},
  mounted() {
    if(this.is_tenant){
      this.dataurl = '/data/tenants/customfields-cards/' + this.is_tenant
      this.createurl = '/data/tenants/create-customfields-cards/' + this.is_tenant
      this.updateurl = '/data/tenants/update-customfields-cards/' + this.is_tenant
      this.addtocardurl = '/data/tenants/add-customfields-to-cards/' + this.is_tenant
      this.resetdefaultcardsurl = '/data/tenants/reset-default-cards/' + this.is_tenant
    }
    // Set the initial number of items
    this.getLists();
  },
  methods: {
    getClass(card){
      let col = 'col-lg-6';

      switch(card.size) {
        case '1/3':
          col = 'col-lg-4';
          break;
        case '1/2':
          col = 'col-lg-6';
          break;
        case '2/3':
          col = 'col-lg-8';
          break;
        case 'full':
          col = 'col-lg-12';
          break;
      }
      col += ' cf-card';
      return col;
    },
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data.fields) {
            this.listsData = response.data.fields;
            this.cards = response.data.cards;
        }
      }).catch(error => {
				/* handleErrors is a global function inside /js/mixins/global.mixins.js
				*/
				this.handleErrors(error.response);
      });
    },
    create() {
      let formData = new FormData();

      formData.set('name', this.newCardName);
      formData.set('size', this.cardSize);

      axios.post(this.createurl, formData).then(response => {

        if(response.data && response.data.status && response.data.status == 'success') {
          this.cards = response.data.data;
          this.cardSize = '';
          this.newCardName = '';
          this.$refs.modalAdd.hide();
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    addToMainPool(event){
      this.addFieldToCard(event.item.id, 0);
    },
    addedToCard(event){
      this.addFieldToCard(event.item.id, event.to.id);
    },
    addFieldToCard(fieldId, cardId){
      let formData = new FormData();

      formData.set('card', cardId);
      formData.set('field', fieldId);

      axios.post(this.addtocardurl, formData).then(response => {

        if(response.data && response.data.status && response.data.status == 'success') {
          this.cards = response.data.data;
          this.listsData = response.data.fields;
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    sorted(event){
      let order = [];
      event.to.querySelectorAll('p').forEach(function(element, key) {
        order.push({
          'field_id': element.id,
          'order': key
        });
      });

      if(order.length && event.to.id){
        let formData = new FormData();

        formData.set('card_id', event.to.id);
        formData.set('order', JSON.stringify(order));
        formData.set('type', 'order-fields');

        axios.post(this.updateurl, formData).then(response => {
          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          this.handleResponse(response);
        }).catch(error => {
          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      }
    },
    cardSorted(event) {
      let order = [];
      event.to.querySelectorAll('.cf-card').forEach(function(element, key) {
        order.push({
          'card_id': element.id,
          'order': key
        });
      });

      if(order.length){
        let formData = new FormData();

        formData.set('order', JSON.stringify(order));
        formData.set('type', 'order-cards');

        axios.post(this.updateurl, formData).then(response => {
          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          this.handleResponse(response);
        }).catch(error => {
          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      }
    },
    cardEdit(card){
      this.cardToEdit = card;
      this.cardSizeEdit = card.size;
      this.cardNameEdit = card.name;
      this.$refs.modalEl.show();
    },
    cardDelete(card){
      this.cardToEdit = card;
      this.$refs.modalDelete.show();
    },
    deleteCard(yesorno = false){
      if(yesorno) {
        let formData = new FormData();

        formData.set('card', this.cardToEdit.id);
        formData.set('type', 'delete');

        axios.post(this.updateurl, formData).then(response => {
          if(response.data && response.data.status && response.data.status == 'success') {
            this.cards = response.data.data;
            this.listsData = response.data.fields;
            this.$refs.modalDelete.hide();
          }

          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          this.handleResponse(response);
        }).catch(error => {
          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else {
        this.checkAreYouSure = true;
      }
    },
    updateCard(){
      if(this.cardSizeEdit == this.cardToEdit.size && this.cardNameEdit == this.cardToEdit.name) {
        this.warningAlert("No changes made.");
        return;
      }

      if(this.cardSizeEdit && this.cardNameEdit){
        let formData = new FormData();

        formData.set('card', this.cardToEdit.id);
        formData.set('size', this.cardSizeEdit);
        formData.set('name', this.cardNameEdit);
        formData.set('type', 'update');

        axios.post(this.updateurl, formData).then(response => {
          if(response.data && response.data.status && response.data.status == 'success') {
            this.cards = response.data.data;
            this.$refs.modalEl.hide();
          }

          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          this.handleResponse(response);
        }).catch(error => {
          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      }
    },
    onFocused(e) {
		  e.target.parentElement.classList.add('is-focused');
    },
	  onBlurred(e) {
      if(e.target.value === "") {
        e.target.parentElement.classList.remove('is-focused');
      }
    },
    resetDefault(){
      if(!this.resetDefaultConfirmation){
        this.resetDefaultConfirmation = true;
        return;
      }

      console.log("resetDefault")
      let formData = new FormData();
      axios.post(this.resetdefaultcardsurl, formData).then(response => {

        this.resetDefaultConfirmation = false
        if(response.data && response.data.status && response.data.status == 'success') {
          this.cards = response.data.data;
          this.listsData = response.data.fields;
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
  },
};
</script>

<template>
<div>
  <Layout v-if="!this.is_tenant">
    <PageHeader :title="title" :items="items" class="text-center" />
    <div class="row customfield-cards-wrapper">

      <div class="col-12">
        <h2 class="card-title text-center text-uppercase border-0 m-0">Unassigned Fields <span class="text-muted font-size-12">({{listsData.length}})</span></h2>
        <p class="text-center small mb-2">(Drag and drop fields into your cards below)</p>
        <draggable class="list-field-btns d-flex flex-wrap" draggable=".list-field" group="people" @add="addToMainPool">
          <p v-for="customField in listsData" :key="customField.id" :id="customField.id" class="list-field">
              <button type="button" class="btn btn-400 btn-drag btn-md">
                  {{customField.label}}
              </button>
          </p>
        </draggable>

        <div class="text-center my-4">
          <b-button v-b-modal.modal-create-card variant="outline-success d-flex" class="mx-auto align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add Card</b-button>
        </div>

                <draggable class="row" v-if="cards" :sort="true" draggable=".cf-card" handle=".btn-move" @sort="cardSorted">
                  <div v-for="card in cards" :id="card.id" :class="getClass(card)">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-button-wrapper">
                          <b-dropdown
                              class="card-drop"
                              variant="white"
                              right
                              toggle-class="p-0"
                              menu-class="dropdown-menu-end"
                            >
                              <template v-slot:button-content>
                                <span class="visually-hidden">Manage {{card.name}}</span>
                                <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                              </template>
                              <li><b-button @click.prevent="cardEdit(card)" variant="link" class="btn-label"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</b-button></li>
                              <li><button @click.prevent="cardDelete(card)" type="button" class="btn btn-label btn-link"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                            </b-dropdown>

                          <button type="button" class="btn btn-link ms-1 btn-move"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.402 8.829.752 7.177l1.664-1.665c.238-.232-.212-.683-.443-.445L.088 6.955a.326.326 0 0 0 0 .443l1.87 1.875c.305.307.738-.148.444-.443v-.001Zm9.571 0 1.65-1.652-1.663-1.665c-.238-.234.212-.684.443-.446l1.885 1.887a.325.325 0 0 1 0 .444l-1.87 1.875c-.305.306-.737-.149-.443-.444l-.002.001Zm-6.427 3.144 1.652 1.65 1.665-1.664c.233-.237.684.213.446.444L7.42 14.288a.325.325 0 0 1-.443 0l-1.875-1.87c-.307-.305.148-.738.443-.444v-.001ZM7.188 1.875a.312.312 0 0 0-.313.312v4.688H2.188a.312.312 0 1 0 0 .625h4.687v4.687a.312.312 0 1 0 .625 0V7.5h4.688a.312.312 0 1 0 0-.625H7.5V2.187a.312.312 0 0 0-.312-.312Zm-1.64.523L7.188.754l1.65 1.658c.23.235.678-.213.442-.444L7.406.092a.323.323 0 0 0-.44 0L5.108 1.956c-.304.303.147.735.44.442Z" fill="#3A4C55"/></svg><span class="visually-hidden">Move Card</span></button>
                        </div>
                        <h2 class="card-title text-uppercase">{{card.name}}</h2>
                        <draggable class="list-field-btns d-flex flex-wrap" draggable=".list-field" group="people" :sort="true" :id="card.id" @add="addedToCard" @sort="sorted">
                          <p v-for="customField in card.fields" :key="customField.id" :id="customField.id" class="list-field">
                            <button type="button" class="btn btn-700 btn-drag btn-md">
                              {{customField.label}}
                            </button>
                          </p>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </draggable>
          </div>

    </div>
  </Layout>
  <div v-else>
    <div class="row customfield-cards-wrapper">

      <div class="col-12">
        <h2 class="card-title text-center text-uppercase border-0 m-0">Unassigned Fields <span class="text-muted font-size-12">({{listsData.length}})</span></h2>
        <p class="text-center small mb-2">(Drag and drop fields into your cards below)</p>
        <draggable class="list-field-btns d-flex flex-wrap" draggable=".list-field" group="people" @add="addToMainPool">
          <p v-for="customField in listsData" :key="customField.id" :id="customField.id" class="list-field">
              <button type="button" class="btn btn-400 btn-drag btn-md">
                  {{customField.label}}
              </button>
          </p>
        </draggable>

        <div class="text-center my-4">
          <b-button v-if="!resetDefaultConfirmation" v-b-modal.modal-create-card variant="outline-success d-flex" class="mx-auto align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add Card</b-button>
          <b-button  variant="outline-success d-flex" class="mx-auto mt-1 align-items-end" @click="resetDefault()" v-if="!resetDefaultConfirmation">
            Reset Default Cards
          </b-button>
          <b-button  variant="outline-success d-flex" class="mx-auto mt-1 align-items-end" @click="resetDefault()" v-else>Are you sure?</b-button>
          <b-button  variant="outline-success d-flex" class="mx-auto mt-1 align-items-end" v-if="resetDefaultConfirmation" @click="resetDefaultConfirmation = !resetDefaultConfirmation">Nevermind</b-button>
        </div>

        <draggable class="row" v-if="cards" :sort="true" draggable=".cf-card" handle=".btn-move" @sort="cardSorted">
          <div v-for="card in cards" :id="card.id" :class="getClass(card)">
            <div class="card">
              <div class="card-body">
                <div class="card-button-wrapper">
                  <b-dropdown
                      class="card-drop"
                      variant="white"
                      right
                      toggle-class="p-0"
                      menu-class="dropdown-menu-end"
                    >
                      <template v-slot:button-content>
                        <span class="visually-hidden">Manage {{card.name}}</span>
                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                      </template>
                      <li><b-button @click.prevent="cardEdit(card)" variant="link" class="btn-label"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</b-button></li>
                      <li><button @click.prevent="cardDelete(card)" type="button" class="btn btn-label btn-link"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                    </b-dropdown>

                  <button type="button" class="btn btn-link ms-1 btn-move"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.402 8.829.752 7.177l1.664-1.665c.238-.232-.212-.683-.443-.445L.088 6.955a.326.326 0 0 0 0 .443l1.87 1.875c.305.307.738-.148.444-.443v-.001Zm9.571 0 1.65-1.652-1.663-1.665c-.238-.234.212-.684.443-.446l1.885 1.887a.325.325 0 0 1 0 .444l-1.87 1.875c-.305.306-.737-.149-.443-.444l-.002.001Zm-6.427 3.144 1.652 1.65 1.665-1.664c.233-.237.684.213.446.444L7.42 14.288a.325.325 0 0 1-.443 0l-1.875-1.87c-.307-.305.148-.738.443-.444v-.001ZM7.188 1.875a.312.312 0 0 0-.313.312v4.688H2.188a.312.312 0 1 0 0 .625h4.687v4.687a.312.312 0 1 0 .625 0V7.5h4.688a.312.312 0 1 0 0-.625H7.5V2.187a.312.312 0 0 0-.312-.312Zm-1.64.523L7.188.754l1.65 1.658c.23.235.678-.213.442-.444L7.406.092a.323.323 0 0 0-.44 0L5.108 1.956c-.304.303.147.735.44.442Z" fill="#3A4C55"/></svg><span class="visually-hidden">Move Card</span></button>
                </div>
                <h2 class="card-title text-uppercase">{{card.name}}</h2>
                <draggable class="list-field-btns d-flex flex-wrap" draggable=".list-field" group="people" :sort="true" :id="card.id" @add="addedToCard" @sort="sorted">
                  <p v-for="customField in card.fields" :key="customField.id" :id="customField.id" class="list-field">
                    <button type="button" class="btn btn-700 btn-drag btn-md">
                      {{customField.label}}
                    </button>
                  </p>
                </draggable>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </div>
  </div>
  <b-modal id="modal-update-card" hide-footer v-model="showModal" ref="modalEl">
    <template #modal-header="{ close }">
      <button type="button" class="close" @click="close()">
        <span class="visually-hidden">Close Dialog</span>
        <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
      </button>
    </template>
    <h2 class="text-uppercase card-title">Update Card</h2>
    <b-form @submit.prevent="updateCard" ref="cfForm" v-if="cardToEdit">
      <div class="form-group label-placeholder is-focused">
          <label for="customfieldcard-name">Card Name</label>
          <b-form-input
            id="customfieldcard-name"
            v-on:focus="onFocused($event)"
            v-on:blur="onBlurred($event)"
            v-model="cardNameEdit"
            type="text"
          ></b-form-input>
      </div>
      <div class="form-group pt-2">
        <label for="customfield-size">Card Size</label>
        <b-form-select id="customfieldcard-size" :plain=true v-model="cardSizeEdit" :options="cardSizes" required></b-form-select>
      </div>
      <div class="form-group pt-2 d-flex justify-content-between">
          <b-button variant="success" role="button" enabled="enabled" @click.prevent="updateCard">
            Update Card
          </b-button>
      </div>
    </b-form>
  </b-modal>

  <b-modal id="modal-delete-card" hide-footer ref="modalDelete">
    <template #modal-header="{ close }">
      <button type="button" class="close" @click="close()">
        <span class="visually-hidden">Close Dialog</span>
        <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
      </button>
    </template>
    <h2 class="text-uppercase card-title">Delete Card</h2>
    <b-form @submit.prevent="updateCard" ref="cfForm" v-if="cardToEdit">
      <div class="pt-2">
        <p>Are you sure you want to delete this card?</p>
        <div class="form-group d-flex">
          <b-button href="#" variant="success" @click.prevent="deleteCard(true)">Yes</b-button>
          <b-button href="#" variant="danger" class="ms-2" @click="$bvModal.hide('modal-delete-card')">No</b-button>
        </div>
      </div>
    </b-form>
  </b-modal>

  <b-modal id="modal-create-card" hide-footer ref="modalAdd">
    <template #modal-header="{ close }">
      <button type="button" class="close" @click="close()">
        <span class="visually-hidden">Close Dialog</span>
        <svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
      </button>
    </template>
    <h2 class="text-uppercase card-title">Create Card</h2>
    <div class="form-group label-placeholder">
      <label for="customfieldcard-name-create">Card Name</label>
      <b-form-input
        id="customfieldcard-name-create"
        v-model="newCardName"
        v-on:focus="onFocused($event)"
        v-on:blur="onBlurred($event)"
        type="text"
      ></b-form-input>
    </div>
    <div class="form-group pt-2" v-show="newCardName">
      <label for="customfield-size">Card Size</label>
      <b-form-select id="customfieldcard-size" :plain=true v-model="cardSize" :options="cardSizes" required></b-form-select>
    </div>
    <div class="form-group pt-2" v-show="newCardName && cardSize">
      <b-button variant="success" role="button" enabled="enabled" @click.prevent="create">
        Create
      </b-button>
    </div>

  </b-modal>
</div>
</template>