<script>
/**
 * Page-header component
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <h1 class="text-uppercase mb-3 font-size-18">{{ title }}</h1>
  <!-- end page title -->
</template>
