<script>
export default {
  props: ['listsData', 'id'],
};
</script>

<template>
  <div>
    <div class="row">
      <div v-if="listsData && listsData.length">
        <div v-for="listData in listsData" :key="listData.id" class="col-xl-4 col-sm-6">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="avatar-md me-4">
                  <span class="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img :src="listData.image ? `${listData.image}` : 'http://reachist.lndo.site/images/logo-icon-light.png'" alt height="30" />
                  </span>
                </div>

                <div class="media-body overflow-hidden">
                  <h5 class="text-truncate font-size-15" :title="listData.about">
                    <router-link :to="'/client/settings/' + listData.id" class="text-dark">{{listData.name}}</router-link>
                  </h5>
                  <p class="text-muted mb-4" :title="listData.domain_full">{{listData.domain}}</p>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 border-top">
              <ul class="list-inline mb-0">
                <li class="list-inline-item me-3">
                  <span
                    class="badge"
                    :class="{ 'bg-primary': `${listData.status}` === 'Ready',
                    'bg-warning': `${listData.status}` === 'Pending',
                    'bg-danger': `${listData.status}` === 'Denied' }"
                  >{{listData.status}}</span>
                </li>
                <li v-b-tooltip.hover.top class="list-inline-item me-3" title="About">
                  <i class="bx bx-globe me-1"></i>
                    <a :href="'//' + listData.domain_full" class="d-inline-block" target="_BLANK">
                      {{listData.domain_full}}
                    </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>