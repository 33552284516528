<script>

import axios from "axios";

export default{
	data: function(){
		return {
            file: ''
		}
	},
	props: ['details', 'label', 'field'],
	methods: {
		initInput: function(){
            // console.log('initializing file upload input', this.details, this.label, this.field);
		},
		imageSelected: function(){
            this.file = this.$refs.fileUploaderInput.files[0];
			this.$emit('text-updated-blur', this.file, this.field);
		},
		updateTextBlur: function(){
			this.edit = false;
			this.$emit('text-updated-blur', this.label, this.field);
		},
		updateTextEnter: function(){
			this.edit = false;
			this.$emit('text-updated-enter', this.label, this.field);
		}
	},
	computed: {
		fileDetails: function(){
			return this.details;
		}
	},
	mounted: function(){
		this.initInput();
	}
}
</script>
<template>
	<div>
        <div v-if="details && details.public">
            <img :src="details.public" class="img-thumbnail"/>
        </div>
        <div>
            <input type="file" :name="this.field" @change="imageSelected" ref="fileUploaderInput"/>
        </div>
	</div>
</template>