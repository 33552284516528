<script>
import draggable from "vuedraggable";
import LabelEdit from '../../../plugins/LabelEdit';
import CheckboxEdit from '../../../plugins/CheckboxEdit';
import SwitchEdit from '../../../plugins/SwitchEdit';
import TextareaEdit from '../../../plugins/TextareaEdit';
import FileUploadEdit from '../../../plugins/FileUploadEdit';
import DatepickerEdit from '../../../plugins/DatepickerEdit';
import addHolidayModal from '../../components/modals/add-holiday-modal';
import bulkUpdateModal from '../../components/modals/bulk-update-modal';
import removeHolidayModal from '../../components/remove-holiday';
import CustomField from '../../../plugins/CustomField';
import { barChart } from "../../original/projects/data-overview";
import NewModal from './new-modal';
import axios from "axios";
import {_} from 'vue-underscore';

/**
 * Contacts-Profile component
 */
export default {
  components: { 'label-edit': LabelEdit, NewModal, CustomField, CheckboxEdit, SwitchEdit, FileUploadEdit, TextareaEdit, DatepickerEdit, addHolidayModal, removeHolidayModal, bulkUpdateModal, draggable },
  props: ['listData'],
  data() {
    return {
      barChart: barChart,
      updateurl: '/data/tenants/locations/update',
      updateCardsUrl: '/data/tenants/locations/update-cards',
      uploadUrl: '/data/tenants/locations/upload',
      updateBulkurl: '/data/tenants/locations/bulk-update',
      localData: false,
      bulklocations: [],
      localDataFieldsToUpdate: {},
      customFieldsToUpdate: {},
      fileUploadFieldsToUpdate: [],
      hoursToUpdate: {},
      name: '',
      cards: [],
      tenant: false,
      refresher: true,
      locations: []
    };
  },
  methods: {
    beginUploads(){
      if(!this.fileUploadFieldsToUpdate.length) return;

      let uploadField = this.fileUploadFieldsToUpdate.shift();

      let formData = new FormData();
      formData.append('file', uploadField.data);
      formData.append('customfields', uploadField.field);

      formData.append('location_id', this.localData.id);
      formData.append('tenant_id', this.tenant.id);

      if(this.bulklocations.length)
        formData.append('bulk_locations', this.bulklocations);

      axios.post( this.uploadUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
        if(response.data && response.data.success && response.data.data){
          if(response.data.data.length)
            this.localData = response.data.data;

          this.bulklocations = [];
          this.successAlert('Successfully uploaded: ' + this.customFieldLabel(uploadField.field));
          this.refresh();
        }
        this.beginUploads();
      }).catch(error => {
        /* handleErrors is a global function inside /js/mixins/global.mixins.js
        */
        this.handleErrors(error.response);
      });
    },
    saveChanges(){
      this.beginUploads();

      if(!Object.keys(this.localDataFieldsToUpdate).length
        && !Object.keys(this.customFieldsToUpdate).length
        && !Object.keys(this.hoursToUpdate).length) return;

      let formData = new FormData();
      
      if(Object.keys(this.localDataFieldsToUpdate).length)
        formData.append('localfields', JSON.stringify(this.localDataFieldsToUpdate));

      if(Object.keys(this.customFieldsToUpdate).length)
        formData.append('customfields', JSON.stringify(this.customFieldsToUpdate));

      if(Object.keys(this.hoursToUpdate).length)
        formData.append('hours', JSON.stringify(this.hoursToUpdate));

      formData.append('location_id', this.localData.id);
      formData.append('tenant_id', this.tenant.id);

      if(this.bulklocations.length)
        formData.append('bulk_locations', this.bulklocations);

      axios.post(this.updateBulkurl, formData).then(response => {
        if(response.data.success && response.data.data){
          this.localData = response.data.data;

          this.localDataFieldsToUpdate = {};
          this.customFieldsToUpdate = {};
          this.hoursToUpdate = {};
          this.bulklocations = [];
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    prepareLocaldataUpdate(newdata, field){
      if(this.localData[field] == newdata) return;
      this.localDataFieldsToUpdate[field] = this.localData[field] = newdata === '' ? null : newdata;
      this.refresh();
    },
    prepareCustomFieldUpdate(newdata, customFieldId){
      if(this.localData.fields == null) this.localData.fields = {};

      if(this.localData.fields[customFieldId] == newdata || (this.localData.fields[customFieldId] == undefined && newdata === '')) return;

      this.customFieldsToUpdate[customFieldId] = this.localData.fields[customFieldId] = newdata === '' ? null : newdata;
      this.refresh();
    },
    prepareFileUploads(newdata, customFieldId){
      if(this.localData.fields == null) this.localData.fields = {};

      let lodalFieldsToUpdate = this.fileUploadFieldsToUpdate;
      let alreadyexists = false;

      lodalFieldsToUpdate.forEach(function(val, index){
        if(val.field == customFieldId) {
          lodalFieldsToUpdate[index].data = newdata;
          alreadyexists = true;
        }
      });

      if(!alreadyexists)
        lodalFieldsToUpdate.push( {field: customFieldId, data: newdata} );

      this.fileUploadFieldsToUpdate = lodalFieldsToUpdate;

      this.refresh();
    },
    reloadTenantHoliday(holiday){
      this.localData.holidays.push(holiday);
    },
    removeTenantHoliday(holiday){
      this.localData.holidays = _.without(this.localData.holidays, _.findWhere(this.localData.holidays, {
        id: holiday.id
      }));
    },
    updateHours(newdata, customFieldId){
      this.hoursToUpdate[customFieldId] = newdata;
      this.refresh();
    },
    refresh() {
      this.refresher = false;
      this.refresher = true;
    },
    customFieldLabel(customFieldSlug){
      if(this.tenant.custom_fields) {
        return this.customFieldFilter(this.tenant.custom_fields, customFieldSlug)[0].label;
      }return '';
    },
    isCustomFieldToBeEdited(customFieldSlug){
      return (
        this.customFieldsToUpdate.hasOwnProperty(customFieldSlug) || 
        this.hoursToUpdate.hasOwnProperty(customFieldSlug) || 
        this.localDataFieldsToUpdate.hasOwnProperty(customFieldSlug)
      ) ? 'in-edit-more' : '';
    },
    customFieldExistsInCard(card){
      if(card.fields && card.fields.length) {
        for (let i = 0; i < card.fields.length; i++) {
          let field = card.fields[i];
          if(this.customFieldExists(field.slug)){
            return true;
          }
        }
      } return false;
    },
    customFieldExists(customFieldSlug, returnValue = false){
      if(this.tenant.custom_fields) {
        if(returnValue)
          return this.customFieldFilter(this.tenant.custom_fields, customFieldSlug)[0];
        return this.customFieldFilter(this.tenant.custom_fields, customFieldSlug).length;
      }return false;
    },
    customFieldFilter(customfields, rSlug){
      return customfields.filter(function(obj){
        return obj.slug == rSlug;
      });
    },
    loadTenantLocations(locationsEmitted){
      this.locations = locationsEmitted;
    },
    getCustomFieldData(slug){
      if(this.localData.fields && this.localData.fields[slug])
        return this.localData.fields[slug];
      return '';
    },
    watchforchanges(){
      return ( Object.keys(this.localDataFieldsToUpdate).length || Object.keys(this.customFieldsToUpdate).length || Object.keys(this.hoursToUpdate).length || this.fileUploadFieldsToUpdate.length);
    },
    bulkUpdate(ids){
      if(ids.length)
        this.bulklocations = ids;
      this.saveChanges();
    },
    cardClass(card){
      switch(card.size) {
        case '1/3':
          return 'cf-card col-md-4';
          break;
        case '1/2':
          return 'cf-card col-md-6';
          break;
        case '2/3':
          return 'cf-card col-md-8';
          break;
        case 'full':
          return 'cf-card col-md-12';
          break;
        default:
          return 'cf-card col-md-3';
      }
    },
    cardSorted(event) {
      let order = [];
      event.to.querySelectorAll('.cf-card').forEach(function(element, key) {
        order.push(element.id);
      });

      if(order.length){

        let formData = new FormData();

        formData.set('order', JSON.stringify(order));
        formData.append('tenant_id', this.tenant.id);

        axios.post(this.updateCardsUrl, formData).then(response => {
          /* handleResponse is a global function inside /js/mixins/global.mixins.js
          */
          this.handleResponse(response);
        }).catch(error => {
          if(error.response){
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      }
    },
  },
  mounted(){
      this.localData = this.listData.location;
      this.tenant = this.listData.tenant;
      this.cards = this.listData.cards;
  }
};
</script>

<template>
  <div class="location-details">
    <div class="mb-3 d-flex align-items-center">
      <SwitchEdit field="is_active" id="is_active" value="localData.is_active" v-on:checkbox-clicked="prepareLocaldataUpdate" ></SwitchEdit> <label class="ps-2 mb-0" for="is_active_switch"><span class="font-weight-semibold">{{ localData.is_active ? 'Active' : 'Inactive' }}:</span> {{localData.name}}</label>
    </div>

    <div class="row client-location-wrapper" v-if="localData && tenant && refresher">
      <div class="col-lg-6">

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Details</h2>

            <p :class="isCustomFieldToBeEdited('name') + ' form-group'">
              <label-edit field="name" v-bind:text="localData.name" inputLabel="Display Name" id="display_name" placeholder="Enter Restaurant Name" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('address') + ' form-group'">
              <label-edit field="address" v-bind:text="localData.address" inputLabel="Address" id="location_address" placeholder="Enter Address" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('city') + ' form-group'">
              <label-edit field="city" v-bind:text="localData.city" inputLabel="City" id="location_city" placeholder="Enter City" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('state') + ' form-group'">
              <label-edit field="state" v-bind:text="localData.state" inputLabel="State" id="location_state" placeholder="Enter state" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('zipcode') + ' form-group'">
              <label-edit field="zipcode" v-bind:text="localData.zipcode" inputLabel="Zipcode" id="location_zip" placeholder="Enter zip" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('country') + ' form-group'">
              <label-edit field="country" v-bind:text="localData.country" inputLabel="Country" id="location_country" placeholder="Enter country" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <hr>

            <p :class="isCustomFieldToBeEdited('cross_streets') + ' form-group'" v-if="customFieldExists('cross_streets')">
              <label-edit field="cross_streets" v-bind:text="getCustomFieldData('Cross Streets')" inputLabel="Cross Streets" id="cross_streets" v-on:text-updated-blur="prepareCustomFieldUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('landmark') + ' form-group'" v-if="customFieldExists('landmark')">
              <label-edit field="landmark" v-bind:text="getCustomFieldData('landmark')" inputLabel="Landmark" id="location_landmark" v-on:text-updated-blur="prepareCustomFieldUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('latitude') + ' form-group'">
              <label-edit field="latitude" v-bind:text="localData.latitude" inputLabel="Latitude" id="location_latitude" placeholder="Enter Latitude" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('longitude') + ' form-group'">
              <label-edit field="longitude" v-bind:text="localData.longitude" inputLabel="Longitude" id="location_longitude" placeholder="Enter Longitude" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <hr>

            <p :class="isCustomFieldToBeEdited('phone') + ' form-group'">
              <label-edit field="phone" v-bind:text="localData.phone" inputLabel="Phone" id="location_phone" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
              <b-form-text id="input-live-help">Enter a format of (xxx) xxx-xxxx</b-form-text>
            </p>

            <p :class="isCustomFieldToBeEdited('corporate_id') + ' form-group'">
              <label-edit field="corporate_id" type="number" v-bind:text="localData.corporate_id" inputLabel="Corporate ID" id="corporate_id" placeholder="Enter Corporate ID" v-on:text-updated-blur="prepareLocaldataUpdate"></label-edit>
            </p>

            <hr>

            <p class="vlabeledit d-flex">
              <span class="label">Created At</span>
              <span>{{localData.created_at | todate}}</span>
            </p>

            <p class="vlabeledit d-flex">
              <span class="label">Updated At</span>
              <span>{{localData.updated_at | todate}}</span>
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Hours</h2>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Monday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('monday_start')" label="Monday Opening Time" label-class="visually-hidden" label-for="monday_start_datepicker">
                <DatepickerEdit id="monday_start" field="monday_start" type="time" v-bind:text="(localData.hours && localData.hours.monday_start) ? localData.hours.monday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('monday_end')" label="Monday Closing Time" label-class="visually-hidden" label-for="monday_end_datepicker">
                <DatepickerEdit id="monday_end" field="monday_end" type="time" v-bind:text="(localData.hours && localData.hours.monday_end) ? localData.hours.monday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Tuesday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('tuesday_start')" label="Tuesday Opening Time" label-class="visually-hidden" label-for="tuesday_start_datepicker">
                <DatepickerEdit id="tuesday_start" field="tuesday_start" type="time" v-bind:text="(localData.hours && localData.hours.tuesday_start) ? localData.hours.tuesday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('tuesday_end')" label="Tuesday Closing Time" label-class="visually-hidden" label-for="tuesday_end_datepicker">
                <DatepickerEdit id="tuesday_end" field="tuesday_end" type="time" v-bind:text="(localData.hours && localData.hours.tuesday_end) ? localData.hours.tuesday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Wednesday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('wednesday_start')" label="Wednesday Opening Time" label-class="visually-hidden" label-for="wednesday_start_datepicker">
                <DatepickerEdit id="wednesday_start" field="wednesday_start" type="time" v-bind:text="(localData.hours && localData.hours.wednesday_start) ? localData.hours.wednesday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('wednesday_end')" label="Wednesday Closing Time" label-class="visually-hidden" label-for="wednesday_end_datepicker">
                <DatepickerEdit id="wednesday_end" field="wednesday_end" type="time" v-bind:text="(localData.hours && localData.hours.wednesday_end) ? localData.hours.wednesday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Thursday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('thursday_start')" label="Thursday Opening Time" label-class="visually-hidden" label-for="thursday_start_datepicker">
                <DatepickerEdit id="thursday_start" field="thursday_start" type="time" v-bind:text="(localData.hours && localData.hours.thursday_start) ? localData.hours.thursday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('thursday_end')" label="Thursday Closing Time" label-class="visually-hidden" label-for="thursday_end_datepicker">
                <DatepickerEdit id="thursday_end" field="thursday_end" type="time" v-bind:text="(localData.hours && localData.hours.thursday_end) ? localData.hours.thursday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Friday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('friday_start')" label="Friday Opening Time" label-class="visually-hidden" label-for="friday_start_datepicker">
                <DatepickerEdit id="friday_start" field="friday_start" type="time" v-bind:text="(localData.hours && localData.hours.friday_start) ? localData.hours.friday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('friday_end')" label="Friday Closing Time" label-class="visually-hidden" label-for="friday_end_datepicker">
                <DatepickerEdit id="friday_end" field="friday_end" type="time" v-bind:text="(localData.hours && localData.hours.friday_end) ? localData.hours.friday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Saturday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('saturday_start')" label="Saturday Opening Time" label-class="visually-hidden" label-for="saturday_start_datepicker">
                <DatepickerEdit id="saturday_start" field="saturday_start" type="time" v-bind:text="(localData.hours && localData.hours.saturday_start) ? localData.hours.saturday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('saturday_end')" label="Saturday Closing Time" label-class="visually-hidden" label-for="ssaturday_end_datepicker">
                <DatepickerEdit id="saturday_end" field="saturday_end" type="time" v-bind:text="(localData.hours && localData.hours.saturday_end) ? localData.hours.saturday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
            <fieldset class="fieldset fieldset-hours d-flex align-items-center">
              <legend>Sunday</legend>
              <b-form-group :class="isCustomFieldToBeEdited('sunday_start')" label="Sunday Opening Time" label-class="visually-hidden" label-for="sunday_start_datepicker">
                <DatepickerEdit id="sunday_start" field="sunday_start" type="time" v-bind:text="(localData.hours && localData.hours.sunday_start) ? localData.hours.sunday_start : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
              <b-form-group :class="isCustomFieldToBeEdited('sunday_end')" label="Sunday Closing Time" label-class="visually-hidden" label-for="sunday_end_datepicker">
                <DatepickerEdit id="sunday_end" field="sunday_end" type="time" v-bind:text="(localData.hours && localData.hours.sunday_end) ? localData.hours.sunday_end : '0'" v-on:text-updated-blur="updateHours" defaultvalue="00:00"></DatepickerEdit>
              </b-form-group>
            </fieldset>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-6">

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Social Media</h2>

            <p :class="isCustomFieldToBeEdited('facebook_url') + ' form-group'">
              <label-edit field="facebook_url" v-bind:text="localData.facebook_url" inputLabel="Facebook" id="facebook_url" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('instagram_url') + ' form-group'">
              <label-edit field="instagram_url" v-bind:text="localData.instagram_url" inputLabel="Instagram" id="instagram_url" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('yelp_url') + ' form-group'">
              <label-edit field="yelp_url" v-bind:text="localData.yelp_url" inputLabel="Yelp" id="yelp_url" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>

            <p :class="isCustomFieldToBeEdited('twitter_handle') + ' form-group'">
              <label-edit field="twitter_handle" v-bind:text="localData.twitter_handle" inputLabel="Twitter Handle" id="twitter_handle" v-on:text-updated-blur="prepareLocaldataUpdate" ></label-edit>
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Holidays</h2>
            <div class="table-responsive mb-0" v-if="localData.holidays">
              <table class="table table-nowrap table-hover mb-0 align-middle">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Open</th>
                    <th scope="col">Close</th>
                    <th scope="col"><addHolidayModal :parentdata="tenant" :locationdata="localData" :islocation="true" v-on:reload-tenant-holiday="reloadTenantHoliday"/></th>
                  </tr>
                </thead>
                <tbody v-if="localData.holidays">
                  <tr v-for="holiday in localData.holidays">
                    <td>
                        {{holiday.holiday_name}}
                    </td>
                    <td>
                        {{holiday.start_date}}
                        <span class="badge bg-primary" v-if="holiday.is_annual">annual</span>
                    </td>
                    <td>
                        <span v-html="holiday.is_closed ? 'closed' : 'open'"></span>
                    </td>
                    <td>
                        <span v-if="holiday.open_time">
                        {{holiday.open_time}}
                        </span>
                    </td>
                    <td>
                        <span v-if="holiday.close_time">
                        {{holiday.close_time}}
                        </span>
                    </td>
                    <td>
                      <removeHolidayModal :parentdata="tenant" :holiday="holiday" v-on:removed-holiday="removeTenantHoliday" :islocation="true"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Features</h2>

            <p :class="isCustomFieldToBeEdited('is_online_ordering')">
              <CheckboxEdit field="is_online_ordering" :value="localData.is_online_ordering" id="online_ordering" inputLabel="Online ordering" v-on:checkbox-clicked="prepareLocaldataUpdate" ></CheckboxEdit>
            </p>

            <p :class="isCustomFieldToBeEdited('is_fundraising')">
              <CheckboxEdit field="is_fundraising" :value="localData.is_fundraising" id="fundraising" inputLabel="Fundraising" v-on:checkbox-clicked="prepareLocaldataUpdate" ></CheckboxEdit>
            </p>

            <p :class="isCustomFieldToBeEdited('is_earn_rewards')">
              <CheckboxEdit field="is_earn_rewards" :value="localData.is_earn_rewards" id="earn_rewards" inputLabel="Earn rewards" v-on:checkbox-clicked="prepareLocaldataUpdate" ></CheckboxEdit>
            </p>

            <p :class="isCustomFieldToBeEdited('is_open_now')">
              <CheckboxEdit field="is_open_now" :value="localData.is_open_now" id="open_now" inputLabel="Open now" v-on:checkbox-clicked="prepareLocaldataUpdate" ></CheckboxEdit>
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Map Zoom Level</h2>

            <p :class="isCustomFieldToBeEdited('zoom')">
              <label-edit field="zoom" type="number" v-bind:text="localData.zoom" inputLabel="Zoom" id="map_zoom" placeholder="Enter Zoom" v-on:text-updated-blur="prepareLocaldataUpdate"></label-edit>
              <span class="d-block mt-2">*Current default zoom level is [x].</span>
            </p>
          </div>
        </div>
      </div>
      <!-- end col -->

      <draggable v-if="cards" :sort="true" draggable=".cf-card" handle=".handle" @sort="cardSorted" class="row">
        <div v-if="cards && customFieldExistsInCard(card)" v-for="card in cards" :id="card.id" :class="cardClass(card)">
          <div class="card">
            <div class="card-body">
              <div class="card-button-wrapper">
                <i class="fa fa-align-justify font-size-16 handle"></i>
              </div>
              <h4 class="card-title mb-4">{{card.name}}</h4>
              <div class="text-muted mt-4">
                <p v-if="card.fields && customFieldExists(field.slug)" v-for="field in card.fields" :class="isCustomFieldToBeEdited(field.slug)">
                  <i class="text-primary me-1">{{field.label}}:</i>
                  <TextareaEdit v-if="field.type == 'textarea'" :field="field.slug" v-bind:text="getCustomFieldData(field.slug)" v-on:text-updated-blur="prepareCustomFieldUpdate" ></TextareaEdit>
                  <FileUploadEdit v-else-if="field.type == 'file'" :details="getCustomFieldData(field.slug)" :field="field.slug" :label="customFieldLabel(field.slug)"   v-on:text-updated-blur="prepareFileUploads" ></FileUploadEdit>
                  <label-edit v-else-if="field.type == 'text' || field.type == 'email'" :field="field.slug" v-bind:text="getCustomFieldData(field.slug)" v-on:text-updated-blur="prepareCustomFieldUpdate" ></label-edit>
                  <CustomField v-else :field="customFieldExists(field.slug, true)" v-bind:text="getCustomFieldData(field.slug)"  v-on:text-updated-blur="prepareCustomFieldUpdate" ></CustomField>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </draggable>

      <div class="location-saver alert bg-white" v-show="watchforchanges()" v-if="refresher">
      	<button type="button" class="close">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>

        <bulkUpdateModal :tenantid="tenant.id" v-on:send-locations="bulkUpdate" :client="true"/>

        <button type="button" @click.prevent="saveChanges" class="btn btn-success btn-xl">Save Changes</button>
      </div>
      <!-- end col -->
    </div>
  </div>
</template>