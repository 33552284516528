<script>
import axios from 'axios';

/**
 * New User Modal component
 */
export default {
  data() {
    return {
      email: "",
      name: "",
      tenants: [],
      send_password: 1,
      createUserUrl: "/data/superadmin/create-user",
      tenant_subdomain: '',
      tenant_name: '',
      new_tenant: 0,
      tenant_id: null,
	  showModal: false,
      adding: false
    };
  },
  methods: {
    createNewUser(){
      let formData = new FormData(document.getElementById("create-new-user"));
      this.adding = true;

      axios.post(this.createUserUrl, formData).then(response => {
        if(response.data && response.data.status && response.data.status == 'success') {
          this.clearForm();

		  this.showModal = false;

          if(this.new_tenant)
            this.getTenantsData();
        }

        this.adding = false;

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {

        this.adding = false;

        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    clearForm() {
      this.email = "";
      this.name = "";
      this.send_password = 1;
      this.tenant_subdomain = '';
      this.tenant_name = '';
      this.new_tenant = 0;
    },
    getTenantsData(){
      axios.get("/data/superadmin/tenants?all").then(response=> {
        if(response.data)
          this.tenants = response.data;
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
	onFocused(e) {
		e.target.parentElement.classList.add('is-focused');
    },
	onBlurred(e) {
		if(e.target.value === "") {
			e.target.parentElement.classList.remove('is-focused');
		}
    }
  },
  mounted() {
	  this.getTenantsData();
  }
};
</script>

<template>
	<div class="">
		<b-button v-b-modal.modal-new-user variant="outline-success d-flex" class="mb-3 align-items-end"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="me-1" aria-hidden="true" viewBox="0 0 14.1 14.1" style="enable-background:new 0 0 14.1 14.1" xml:space="preserve"><path class="st0" d="M7.1 7.1v7.1h-1V7.1H0v-1h6.1V0h1v6.1h7.1v1H7.1z" fill="currentColor"/></svg>Add User</b-button>
		<b-modal id="modal-new-user" hide-footer v-model="showModal">
			<template #modal-header="{ close }">
				<button type="button" class="close" @click="close()">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>
			</template>
      		<h2 class="text-uppercase card-title">Add New User</h2>
			<form action="/data/superadmin/create-user" id="create-new-user" method="POST" @submit.prevent="createNewUser">
				<span class="invalid-feedback" role="alert">
					<strong>message</strong>
				</span>
				<div class="form-group label-placeholder">
					<label for="email">Email</label>
					<input type="email" name="email" id="email" class="form-control" v-model="email" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" required>
				</div>
				<div class="form-group label-placeholder">
					<label for="name">Name</label>
					<input type="text" name="name" id="name" class="form-control" v-model="name" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" required>
				</div>
				<div class="form-group pt-2">
					<div class="custom-control checkbox custom-checkbox b-custom-control-lg">
						<input type="checkbox" name="send_password" class="checkbox-input custom-control-input" id="send-password-link" v-model="send_password" value="1" checked>
						<label class="custom-control-label" for="send-password-link">Send password reset link.</label>
					</div>
				</div>
				<div class="form-group create-new-user-password pt-2" v-if="!send_password">
					<div class="form-group label-placeholder">
						<label for="password">Password</label>
						<input type="password" id="password" name="password" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)" class="form-control">
					</div>
				</div>
				<div class="form-group pt-2" v-if="!new_tenant && tenants.length > 0">
					<label for="tenantSelect">Select Customer</label>
					<select class="form-select" id="tenantSelect" name="tenant_id" v-model="tenant_id">
						<option :value="tenant.id" v-for="tenant in tenants">{{tenant.name}}</option>
					</select>
				</div>
				<div class="form-group pt-2">
					<div class="custom-control checkbox custom-checkbox b-custom-control-lg">
						<input type="checkbox" name="new_tenant" class="checkbox-input custom-control-input" id="new_tenant_link" v-model="new_tenant" value="1" checked>
						<label class="custom-control-label" for="new_tenant_link">New customer?</label>
					</div>
				</div>
				<div class="new-tenant-name" v-if="new_tenant">
					<div class="form-group label-placeholder">
						<label for="tenant_name">Customer Name</label>
						<input type="text" name="tenant_name" id="tenant_name" class="form-control" v-model="tenant_name" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)"/>
					</div>
					<div class="form-group label-placeholder">
						<label for="tenant_subdomain">Customer Subdomain</label>
						<input type="text" name="tenant_subdomain" class="form-control" v-model="tenant_subdomain" v-on:focus="onFocused($event)" v-on:blur="onBlurred($event)"/>
					</div>
				</div>
				<div class="form-group new-tenant-name text-center mt-5">
					<button type="submit" class="btn btn-success" :disabled="adding">
					<span class="d-none d-sm-block">
						<span v-html="adding ? 'Adding User' : 'Add User'">Add</span>
						<i v-if="adding" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
					</span>
					</button>
				</div>
		</form>
		</b-modal>
	</div>
</template>