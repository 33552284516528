<script>
import Layout from "../../../layouts/main";
import PageHeader from "../../../components/page-header";
import LocationsListTemplate from './locationsListTemplate';

import axios from "axios";

/**
 * Projects-list component
 */
export default {
  components: { 
    Layout, 
    PageHeader,
    LocationsListTemplate,
  },
  computed: {
    customer_id() {
      return this.$route.params.customer_id == undefined ? false : this.$route.params.customer_id;
    },
    secondaryid() {
      return this.$route.params.secondaryid == undefined ? false : this.$route.params.secondaryid;
    },
  },
  watch: { 
    customer_id: function() {
      this.initiateComponents()
    },
    secondaryid: function(newVal) {
      this.initiateComponents()
    }
  },
  data() {
    return {
      getdataurl: '/data/tenants/cards',
      dataurl: '/data/tenants/cards',
      listData: [],
      listsData: [],
      title: "Choose a Headquarter",
      items: [
        {
          text: "Locations",
          href: "/dashboard/locations",
        },
        {
          text: "Locations List",
          active: true,
        },
      ],
    };
  },
  methods: {
    loadMore() {
      this.dataurl = this.listsData.next_page_url;

      this.listsData.loading = true;

      this.getLists(true);
    },
    getLists(append = false) {
      axios.get(this.dataurl).then(response => { 
        if(response.data) {
          if(append) {
            this.listsData.locations.data.push.apply(this.listsData.locations.data, response.data.locations.data);

            this.listsData.locations.next_page_url = response.data.next_page_url;

            this.listsData.loading = false;
          } else {
            this.listsData = response.data;
          }
        }
      }).catch(error => {
        if(error.response) {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    initiateComponents() {
      if(this.customer_id && this.secondaryid) { // single location details
        this.$store.commit('setSearchSettings', {'tenant': this.customer_id});

        this.title = 'Location Details';
        this.items[1] = {text: 'My Location Cards', active: false, href: '/client/locations/' + this.customer_id };
        this.items[2] = {text: 'Location Details', active: true};

        this.dataurl = this.getdataurl + '/' + this.customer_id;

        if(this.secondaryid)
          this.dataurl = this.dataurl + '/' + this.secondaryid;

        axios.get(this.dataurl).then(response => { 
          if(response.data) {
            this.listData = response.data;
          }
        }).catch(error => { 
          if(error.response) {
            /* handleErrors is a global function inside /js/mixins/global.mixins.js
            */
            this.handleErrors(error.response);
          }
        });
      } else if(this.customer_id) {
        this.$store.commit('setSearchSettings', {'tenant': this.customer_id});

        this.title = 'My Locations';
        this.items[1].text = 'My Locations';

        this.dataurl = this.getdataurl + '/' + this.customer_id;
        this.getLists();
      } else {
        this.dataurl = this.getdataurl
        this.getLists();
      }
    }
  },
  mounted() {
    this.initiateComponents()
  },
  unmounted(){
    if(this.customer_id && this.secondaryid) {
      this.$store.commit('setSearchSettings', {});
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <LocationsListTemplate v-if="listsData && (listsData.locations || listsData.tenants)" :listsData="listsData" :id="customer_id" v-on:load-more="loadMore"></LocationsListTemplate>
  </Layout>
</template>