<template>
	<div class="vlabeledit d-flex">
        <span v-if="!edit" class="label">{{ inputLabel }}
		</span>
		<label v-if="edit" :for="id">
			{{ inputLabel }}
		</label>
		<b-button :id="tooltipID" v-if="tooltip" variant="tooltip">
			<svg aria-hidden="true" focusable="false" role="presentation" width="5" height="5" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.459 3a.232.232 0 0 1-.132-.04.28.28 0 0 1-.093-.104.309.309 0 0 1-.035-.145.302.302 0 0 1 .128-.25.24.24 0 0 1 .132-.04c.048 0 .092.014.131.04.04.025.072.06.095.104a.302.302 0 0 1 .035.146.309.309 0 0 1-.035.145.275.275 0 0 1-.095.104.227.227 0 0 1-.131.04Zm-.18-.895v-.766c.021.004.047.01.075.014a.74.74 0 0 0 .09.005.618.618 0 0 0 .273-.056.416.416 0 0 0 .18-.168.516.516 0 0 0 .064-.267c0-.099-.02-.184-.059-.255a.403.403 0 0 0-.166-.165.528.528 0 0 0-.253-.058.47.47 0 0 0-.336.123.508.508 0 0 0-.15.338L.666.778A.937.937 0 0 1 .807.36.772.772 0 0 1 1.1.093.824.824 0 0 1 1.487 0a.815.815 0 0 1 .737.433c.073.13.11.276.11.436 0 .146-.03.281-.09.405a.838.838 0 0 1-.248.303.726.726 0 0 1-.352.146v.382h-.366Z" fill="currentColor"/></svg>
			<span class="sr-only">More Info</span>
		</b-button>

		<div class="vlabeledit-label" @click="onLabelClick" v-if="!edit">{{vlabel}}</div>
		<input :type="inputtype" v-if="edit" v-mask="mask" v-model="label" v-on:blur="updateTextBlur" ref="labeledit" :placeholder="vplaceholder" class="vlabeledit-input" :id="id" @keyup.enter="updateTextEnter"/>
		<b-tooltip v-if="tooltip" :target="tooltipID" placement="righttop" :title="tooltip" triggers="hover focus">
			{{tooltip}}
		</b-tooltip>
	</div>
</template>
<script>
export default{
	name: 'LabelEdit',
	data: function(){
		return {
			edit: false, // define whether it is in edit mode or not
			label: '', // v-bind data model for input text
			empty: 'Enter details', // empty place holder .. replace with your own localization for default
		}
	},
	props: ['text','placeholder', 'inputLabel', 'id', 'field', 'type', 'mask', 'tooltip'], // parent should provide :text or :placeholder
	methods: {
		initText: function(){
			if(this.text==''||this.text==undefined){
				this.label = this.vlabel
			}else{
				this.label = this.text
			}
		},
		// when the div label got clicked and trigger the text box
		onLabelClick: function(){
			this.edit = true;
			this.label = this.text;
		},
		// trigger when textbox got lost focus
		updateTextBlur: function(){
			// update the edit mode to false .. display div label text
			this.edit = false;
			// emit text updated callback
			this.$emit('text-updated-blur',this.label, this.field)
		},
		updateTextEnter: function(){
			this.edit = false;
			this.$emit('text-updated-enter',this.label, this.field)
		}
	},
	computed: {
		inputtype: function(){
			return this.type ? this.type : 'text';
		},
		vplaceholder: function(){
			// check if the placeholder is undefined or empty
			if(this.placeholder==undefined || this.placeholder==''){
				// if it is empty or undefined, pre-populate with built-in place holder text
			 	return this.empty
			}else{
				return this.placeholder
			}
		},
		vlabel: function(){
			// after text has been updated
			// return text value or place holder value depends on value of the text
			if(this.text==undefined||this.text==''){
				return this.vplaceholder
			}else{
				return this.label
			}
		},
		tooltipID: function() {
			return "tooltip_" + this.id;
		}
	},
	mounted: function(){
		// initiate the label view
		this.initText();
	},
	updated: function(){
		var ed = this.$refs.labeledit;
		if(ed!=null){
			ed.focus();
		}
	},
	watch: {
		text: function(value){
			if(value==''||value==undefined){
				this.label = this.vplaceholder
			} else {
				this.label = value
			}
		}
	}
}
</script>