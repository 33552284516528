<script>
import Header from "./header";

/**
 * Forgot Password component
 */
export default {
    components: { Header },
    data() {
        return {
            email: "",
            tryingToReset: false,
            isResetError: false,
            activeInputClass: 'form-group label-placeholder is-focused',
            defaultInputClass: 'form-group label-placeholder'
        };
    },
    methods: {
        checkAnimation(e) {
            //hack to detect autofill and adjust field state
            //https://rojas.io/how-to-detect-chrome-autofill-with-vue-js/
            if(e.animationName == "onAutoFillStart") {
                e.target.parentElement.classList.add('is-focused');
            }
            else if(e.animationName == "onAutoFillCancel") {
                e.target.parentElement.classList.remove('is-focused');
            }
        },
        onFocused(e) {
            e.target.parentElement.classList.add('is-focused');
        },
        onBlurred(e) {
            if(e.target.value === "") {
                e.target.parentElement.classList.remove('is-focused');
            }
        }
    },
    mounted() {
        this.isResetError = !!this.error;
        this.tryingToReset = !!this.status;
        document.body.classList.add('bg-dark');
    },
    props: {
        submitUrl: {
            type: String,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: () => null
        },
        status: {
            type: String,
            required: false,
            default: () => null
        }
    }
};
</script>

<template>
<div class="container-fluid">
  <Header />
  <div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card bg-none">
                <h1 class="text-white text-center h2 mb-3">Forgot your password?</h1>
                <div class="card-body bg-none pt-0">
                    <p class="text-center text-white text-md">Please enter the email address associated with your Reachist account to reset your password.</p>
                    <b-alert v-model="isResetError" class="mb-4" variant="danger">{{error}}</b-alert>
                    <b-alert v-model="tryingToReset" class="mb-4" variant="success">{{status}}</b-alert>
                    <b-form class="p-2" :action="submitUrl" method="POST">
                        <slot />
                        <div id="input-group-1" :class="[email ? activeInputClass : defaultInputClass]">
                            <label for="useremail">Email Address</label>
                            <b-form-input
                                type="email" 
                                name="email" 
                                v-model="email" 
                                class="bg-none" 
                                id="useremail" 
                                v-on:focus="onFocused($event)"
                                v-on:blur="onBlurred($event)"
                                v-on:animationstart="checkAnimation"
                            ></b-form-input>
                        </div>
                        <div class="mt-3">
                            <b-button type="submit" variant="success" class="btn-block">Reset Password</b-button>
                        </div>
                    </b-form>
                </div>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
  </div>
</div>
</template>
