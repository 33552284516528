<script>
import LabelEdit from '../../../plugins/LabelEdit';
import SwitchEdit from '../../../plugins/SwitchEdit';
import TooltipSpan from '../../../plugins/TooltipSpan';
import NewModal from './new-modal';
import addUserModal from './add-user-modal';
import massImportModal from '../../components/modals/mass-import-modal';
import exportModal from '../../components/modals/export-modal';
import bulkUpdateModal from '../../components/modals/bulk-update-modal';
import axios from "axios";
import {_} from 'vue-underscore';

/**
 * Contacts-Profile component
 */
export default {
  components: { 'label-edit': LabelEdit, SwitchEdit, TooltipSpan, massImportModal, exportModal, NewModal, addUserModal, bulkUpdateModal },
  props: ['listData'],
  data() {
    return {
      removeTenantUserUrl: "/data/superadmin/assign-tenant-user",
      getPresetUrl: '/data/superadmin/customfields-presets',
      updateurl: '/data/superadmin/tenants/update',
      settingsurl: '/data/superadmin/settings',
      settingsupdateurl: '/data/superadmin/update-tenant-setting',
      localData: [],
      settingsData: {},
      name: '',
      selectedPreset: '',
      customfields: [],
      customFieldsToUpdate: {},
      presets: [],
      locations: [],
      refresher: true,
      filter: '',
      timeout: null
    };
  },
  methods: {
    chooseFromPreset(){
      if(!this.presets.length) {
        axios.get(this.getPresetUrl).then(response => {

          if(response.data.presets) {
              this.presets = response.data.presets;
          }

          if(response.data.presets.length == 0) {
            this.warningAlert('There are no presets available. Please create them <a href="/dashboard/customfields-presets">here</a>');
          }

        }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        });
      }
    },
    tenantUpdate(newdata, field){
      if(this.localData[field] == newdata) return; // no change

      let data = {id: this.localData.id};

      data[field] = newdata;

      axios.post(this.updateurl, data).then(response => {
        if(response.data.data)
          this.localData = response.data.data;
          
        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    tenantSettingsUpdate(newdata, field){
      if(this.settingsData[field].value == newdata) return;
      let updatedValue;
      if(this.settingsData[field].datatype === 'boolean') {
        updatedValue = newdata === 1 ? true : false;
      } else {
        updatedValue = newdata === '' ? null : newdata;
      }

      let data = {
        tenant: this.localData.id,
        settings: {
          [field]: updatedValue,
        }
      };

      axios.post(this.settingsupdateurl, data).then(response => {
        if(response.data.data)
          this.buildSettings(response.data.data);
          
        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    saveChanges(){
      let formData = new FormData();

      formData.append('relation', 'CustomFields');
      formData.append('id', this.localData.id);
      let customFieldsToAdd = [];
      let customFieldsToRemove = [];
      if(Object.keys(this.customFieldsToUpdate).length) {
        for (let key in this.customFieldsToUpdate) {
          if (this.customFieldsToUpdate.hasOwnProperty(key) && this.customFieldsToUpdate[key] === true) {
            customFieldsToAdd.push(key);
          } else if (this.customFieldsToUpdate.hasOwnProperty(key) && this.customFieldsToUpdate[key] === false) {
            customFieldsToRemove.push(key);
          }
        }
        formData.append('customFieldsToAdd', JSON.stringify(customFieldsToAdd));
        formData.append('customFieldsToRemove', JSON.stringify(customFieldsToRemove));
      }

      axios.post(this.updateurl, formData).then(response => {
        if(response.data.success && response.data.data){
          this.customFieldsToUpdate = {};
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    removeUserFromTenant(user){
      if(!this.localData || !user)
        return;

      let formData = new FormData();

      formData.append('tenant', this.localData.id);
      formData.append('user', user.id);
      formData.append('type', 'remove');

      axios.post(this.removeTenantUserUrl, formData).then(response => {
        if(response.data && response.data.success) {
          if(response.data.user) {
            this.localData.users = response.data.user;
          }
        }

        /* handleResponse is a global function inside /js/mixins/global.mixins.js
        */
        this.handleResponse(response);
      }).catch(error => {
        if(error.response){
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
        }
      });
    },
    checkIfCFieldExists(customFieldId){
      if(this.localData.custom_fields) {
        return this.customFieldFilter(this.localData.custom_fields, customFieldId).length;
      } return false;
    },
    customFieldFilter(customfields, rId){
      return customfields.filter(function(obj){
        return obj.id == rId;
      });
    },
    prepareCustomFieldUpdate(newdata, customFieldId){
      this.customFieldsToUpdate[customFieldId] = newdata.target.checked;
      if (newdata.target.checked) {
        this.localData.custom_fields.push(this.customfields.find(f => f.id === customFieldId));
      } else {
        const fieldToRemove = this.customfields.find(f => f.id === customFieldId);
        const indexToDelete = this.localData.custom_fields.indexOf(fieldToRemove);
        if (indexToDelete !== -1) this.localData.custom_fields.splice(indexToDelete, 1);
      }
      this.refresh();
    },
    loadTenantLocations(locationsEmitted){
      this.locations = locationsEmitted;
    },
    loadTenantUsers(usersEmitted){
      this.localData.users.push(usersEmitted);
    },
    refresh() {
      this.refresher = false;
      this.refresher = true;
    },
    updateTenantPresets(presets){
      this.customFieldsToUpdate = {}
      this.localData.custom_fields.forEach((field) => {
        this.customFieldsToUpdate[field.id] = false;
      });
      this.localData.custom_fields = [];
      let customField = null;
      presets.forEach((slug) => {
        customField = this.customfields.find(f => f.slug === slug);
        this.localData.custom_fields.push(customField);
        this.customFieldsToUpdate[customField.id] = true;
      });
    },
    watchforchanges(){
      return (Object.keys(this.customFieldsToUpdate).length );
    },
    buildSettings(tenantSettings) {
      var slugs = [];
      for (var key in tenantSettings) {
        if (tenantSettings.hasOwnProperty(key)) {
          slugs.push(key);
        }
      }
      // get data from settings table to combine with tenantSettings
      axios.get(this.settingsurl, {params: {slugs: slugs}}).then(response => { 
        if(response.data) {
          let data = response.data;
          let settingsData = {};
          data.forEach((element, index) => {
            if(tenantSettings && tenantSettings[data[index].slug] !== undefined){
              data[index].value = tenantSettings[data[index].slug];
            }
            settingsData[data[index].slug] = data[index];
          });
          this.settingsData = settingsData;
        }
      }).catch(error => {
          /* handleErrors is a global function inside /js/mixins/global.mixins.js
          */
          this.handleErrors(error.response);
      });
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, 'ig');
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
    },
    filterLocations(){
      clearTimeout(this.timeout);
      
      var self = this;
      this.timeout = setTimeout(function () {
          // enter this block of code after 1 second
          // handle stuff, call search API etc.
          console.log('searching:', self.filter);
          self.filter = self.filter;
      }, 500);
    },
    viewUser(id, e) {
      if(e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'A') {
        let userURL = '/dashboard/users/' + id;
        window.location = userURL;
      }
    },
    selectPreset(presetId) {
      this.selectedPreset = presetId;
    }
  },
  computed: {
    filteredRows() {
      return this.locations.filter(row => {
        const name = row.name.toString().toLowerCase();
        const address = row.address.toLowerCase();
        const city = row.city.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return address.includes(searchTerm) || city.includes(searchTerm) || name.includes(searchTerm);
      });
    },
  },
	watch: {
		selectedPreset: function(val){
      if(val) {
        let data = this.customFieldFilter(this.presets, val);

        if(data[0] && data[0].presets) {
          this.updateTenantPresets(data[0].presets);
        }
      }
		}
	},
  mounted(){
      this.localData = this.listData.data.tenant;
      this.customfields = this.listData.data.customfields.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));
      this.holidays = this.listData.data.holidays;
      this.locations = this.listData.data.locations;
      this.name = this.localData.name;
      this.buildSettings(this.localData.settings);
      this.chooseFromPreset();
  }
};
</script>

<template>
    <div class="row location-details" v-if="listData && listData.data">
      <div class="col-xl-4">

        <div class="card" v-if="localData.name">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Customer Information</h2>
            <p class="form-group">
              <label-edit field="about" inputLabel="About" id="info_about" v-bind:text="localData.about" v-on:text-updated-blur="tenantUpdate"></label-edit>
            </p>
            <p class="form-group">
              <label-edit
                field="name"
                id="info_about"
                inputLabel="Customer Name"
                v-bind:text="localData.name" 
                v-on:text-updated-blur="tenantUpdate"
              ></label-edit>
            </p>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-body text-center">
            <massImportModal :tenantid="localData.id" v-on:reload-tenant-locations="loadTenantLocations" class="mb-3"/>
            <exportModal :tenantid="localData.id" :tenantlocations="locations"/>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Users</h2>
            <addUserModal :parentdata="localData" v-on:reload-tenant-users="loadTenantUsers"/>
            <div class="table-responsive mb-0" v-if="localData.users">
              <table class="table table-nowrap table-hover mb-0 align-middle">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Roles</th>
                    <th scope="col" class="text-end">Manage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in localData.users" @click="viewUser(user.id, $event)">
                    <td>
                      {{user.name}}
                    </td>
                    <td>
                        <div class="avatar-group" v-if="user.roles && user.roles.length > 0">
                        <div class="avatar-group-item" v-for="role in user.roles">
                            <a href="javascript: void(0);" class="d-inline-block">
                            <img
                                :src="'/images/roles/' + `${role.id}` + '.png'"
                                class="rounded-circle avatar-xs"
                                alt
                            />
                            </a>
                        </div>
                        </div>
                    </td>
                    <td class="text-end">
                      <b-dropdown
                        class="card-drop"
                        variant="white"
                        right
                        toggle-class="p-0"
                        menu-class="dropdown-menu-end"
                      >
                        <template v-slot:button-content>
                          <span class="visually-hidden">Manage user {{user.name}}</span>
                          <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="3" cy="3" r="3" fill="#155678"/><circle cx="15" cy="3" r="3" fill="#155678"/><circle cx="27" cy="3" r="3" fill="#155678"/></svg>
                        </template>
                        <li><a href="#" class="btn btn-label btn-link"><span class="label-icon label-success" aria-hidden="true"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.063.268c-.431 0-.853.167-1.178.492L5.313 6.314l-.124.123-.035.176-.386 1.969-.176.826.826-.176 1.969-.386.176-.035.123-.123 5.554-5.573A1.67 1.67 0 0 0 12.062.268Zm0 1.09c.131 0 .261.068.386.193.25.25.25.523 0 .773L7 7.774l-.967.193L6.227 7l5.449-5.45c.125-.124.255-.193.386-.193ZM.25 2.5v11.25H11.5V6.332l-1.125 1.125v5.168h-9v-9h5.168L7.668 2.5H.25Z" fill="currentColor"/></svg></span>Edit</a></li>
                        <li><button type="button" class="btn btn-label btn-link" @click.prevent="removeUserFromTenant(user)"><span class="label-icon label-danger" aria-hidden="true"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="currentColor"/></svg></span>Delete</button></li>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title text-uppercase">
              Custom Fields
              <b-dropdown variant="white" class="dropdown-shift-up float-end">
                <template v-slot:button-content>
                  Choose From Preset
                  <svg class="caret" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m0 0 4 4 4-4H0Z" fill="#000"/></svg>
                </template>
                <div v-for="preset, index in presets" :key="index">
                  <b-dropdown-item href="javascript: void(0);" @click="selectPreset(preset.id)">
                    {{ preset.name }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </h2>
            
            <div class="table-responsive mb-0 row ms-0">
              <div v-for="customField in customfields" class="custom-control checkbox custom-checkbox b-custom-control-lg mb-3 mt-0 col-sm-12 col-md-6 col-lg-4">
                <input class="form-check-input checkbox-input custom-control-input" type="checkbox" :id="customField.slug" :checked="checkIfCFieldExists(customField.id)" @change="prepareCustomFieldUpdate($event, customField.id)" />
                <label :for="customField.slug" class="form-check-label custom-control-label">{{customField.label}} ({{customField.visibility}})<tooltip-span :id="customField.slug" :tooltip="customField.tooltip"></tooltip-span></label>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="localData.name">
          <div class="card-body">
            <h2 class="card-title text-uppercase">Settings</h2>
            <div class="form-group" v-for="(setting, key) in settingsData" :key="key">
                TEST
                <div v-if="setting.datatype === 'string'">
                  <label-edit :field="setting.slug" :id="setting.slug" :inputLabel="setting.label" v-bind:text="setting.value" @text-updated-blur="tenantSettingsUpdate" :tooltip="setting.tooltip"></label-edit>
                </div>
                <div v-else-if="setting.datatype === 'number'">
                  <label-edit :field="setting.slug" :id="setting.slug" :inputLabel="setting.label" type="number" v-bind:text="setting.value" @text-updated-blur="tenantSettingsUpdate" :tooltip="setting.tooltip"></label-edit>
                </div>
                <div v-else-if="setting.datatype === 'boolean'" class="d-flex justify-content-between settings-switch">
                  <div class="pt-1">{{ setting.label }}</div>
                  <div><SwitchEdit :field="setting.slug" :id="setting.slug" :value="(setting.value === true ? 1 : 0)" @checkbox-clicked="tenantSettingsUpdate" :tooltip="setting.tooltip"></SwitchEdit></div>
                </div>
                <p v-if="setting.description">
                  <small class="text-muted px-2">{{ setting.description }}</small>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div class="location-saver alert bg-white" v-show="watchforchanges()" v-if="refresher">
      	<button type="button" class="close">
					<span class="visually-hidden">Close Dialog</span>
					<svg aria-hidden="true" width="16" height="16" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.47 5 .236.765l.53-.53L5 4.47 9.235.235l.53.53L5.529 5l4.236 4.235-.53.53L5 5.529.765 9.765l-.53-.53L4.471 5Z" fill="#3a4c55"></path></svg>
				</button>

        <button type="button" @click.prevent="saveChanges" class="btn btn-success btn-xl mx-auto">Save Changes</button>
      </div>
    </div>
</template>